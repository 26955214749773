import React, { useState, useEffect } from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import { CSV_KEYWORDS } from "../csvParserKeyword/utils";
import mealCategoryStore from "../../stores/dataStore/mealCategoryStore";
const KeywordList = observer(({ titles, setCheckedMeals, isNew }) => {
  const categoryString = CSV_KEYWORDS.categoryString;
  const nameString = CSV_KEYWORDS.nameString;
const {csvKeywordData}=mealCategoryStore;
  useEffect(() => {
    let allKeywords = [...csvKeywordData]
    console.log(allKeywords)
  }, [csvKeywordData]);
  // },[csvKeywordData]);
  return (
    csvKeywordData?.length > 0 ?
      <div className="meals_list_container">
        {csvKeywordData?.map((item, i) => {
          return (

  <div>
     <div className=" bold" >
          {i + 1}. {item[nameString]}
        </div>
        <div className=" bold" >
          {i + 1}. {item[categoryString]}
        </div>
  </div>
          )
        }
        )}
      </div>
      : null
  );
});
export default KeywordList;
