export const CSV_TITLES_NEW = {
  numberString: "ItemNumber",
  nameString: "Item Name",
  prepString: "Prepration Time (mins)",
  categoryString: "Category Name",
  rateString: "Base Rate (inclusive of tax)",
  descString: "Item Description",
  nutritionString: "Nutritional Description",
  amountString: "Amount",
  alcoholString: "Alcohol",
  ageString: "Age",
  quantityString: "Quantity Available",
  commissionFeeString: "Comission Fee",
  partnerIdString: "Partner Id",
  commissionPercentageString: "Commission Percentage",
  showOnHomePageString: "Show on homepage",
  imageString: "Item Image",
  brandString: "brand",
  summaryString: "summary",
  addonString: "Meal is addon",
  addonsString: "addons",
  parentString: "Meal parents and addon categories",
  parentAltString: "Parent Meals",
  addonCategoryString: "Addon Category",
  addonNameString: "Addon Name",
  addonPriceString: "Addon Price",
};
