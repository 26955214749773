import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { Clock, LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Input from "../../components/inputs";
import IconInput from "../../components/inputs/iconInput";
import Select from "../../components/select";
import Loader from "react-loader-spinner";
import { Tags } from "../../components/appTag";
import { CheckBox } from "../../components/checkBoxes";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import axios from "axios";
import moment from "moment";
import Modal from "../../components/modal";
import { makeCode } from '../../helpers/makeCode'
import cleanPayload from "../../helpers/cleanPayload";
import Prompt from "../../components/modal/prompt";

const BrandInfo = ({ alert }) => {
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [loading, setLoading] = useState(false);

  const [altLoading, setAltLoading] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [brandSummary, setBrandSummary] = useState("");
  const [brandName, setBrandName] = useState("");

  const [isNew, setIsNew] = useState();
  const [brandDescription, setBrandDescription] = useState("");
  const [shortText, setShortText] = useState("");
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentBrands, setCurrentBrands] = useState([]);
  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [imageDisplay, setImageDisplay] = useState(null);
  const [brandImage, setBrandImage] = useState(null);

  const [logoDisplay, setLogoDisplay] = useState(null);
  const [brandLogo, setBrandLogo] = useState(null);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [deleting, setDeleting] = useState(false);

  const [form, setForm] = useState({
    name: "",
    posist_data: {
      customer_key: makeCode(15)
    },
    cokitchen_id: "",
    new: false,
    posist_customer_key: makeCode(15),


  });
  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);
  const [adding, setAdding] = useState(false);

  const [keywords, setKeywords] = useState([]);
  const [keywordsSuggestions, setKeywordsSuggestions] = useState([]);
  const [showKeywordDrop, setShowKeywordDrop] = useState(false);

  const [descriptiveMetadatas, setDescriptiveMetadatas] = useState([]);
  const [DescriptiveMetadatasSuggestions, setDescriptiveMetadatasSuggestions] =
    useState([]);
  const [showDescriptiveMetadatasDrop, setShowDescriptiveMetadatasDrop] =
    useState(false);

  const [businessMetadatas, setBusinessMetadatas] = useState([]);
  const [businessMetadatasSuggestions, setBusinessMetadatasSuggestions] =
    useState([]);
  const [showBusinessMetadatasDrop, setShowBusinessMetadatasDrop] =
    useState(false);
  const [openingsHrs, setOpeningHrs] = useState([
    { name: "monday", opening_time: "", closing_time: "" },
    { name: "tuesday", opening_time: "", closing_time: "" },
    { name: "wednesday", opening_time: "", closing_time: "" },
    { name: "thursday", opening_time: "", closing_time: "" },
    { name: "friday", opening_time: "", closing_time: "" },
    { name: "saturday", opening_time: "", closing_time: "" },
    { name: "sunday", opening_time: "", closing_time: "" },
  ]);
  const [openingsHrsCopy, setOpeningHrsCopy] = useState([
    { name: "monday", opening_time: "", closing_time: "" },
    { name: "tuesday", opening_time: "", closing_time: "" },
    { name: "wednesday", opening_time: "", closing_time: "" },
    { name: "thursday", opening_time: "", closing_time: "" },
    { name: "friday", opening_time: "", closing_time: "" },
    { name: "saturday", opening_time: "", closing_time: "" },
    { name: "sunday", opening_time: "", closing_time: "" },
  ]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;
    if (!BrandStore.dataCached) {
      await BrandStore.getBrands(alert);
    }

    if (!CommonStore.dataCached) {
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCkLoading(false);
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    
    let allBrands = CommonStore?.cokitchens.reduce((acc, curr) => {
      return [...acc, ...curr?.brands]
    }, [])
    setCurrentCokitchen({
      brands: allBrands,
      name: "All users (all locations)",
    });

    setBrands(allBrands);
    setAllTags();
  }, []);
  // Load Current working hours based on current brand
  useEffect(async () => {
    loadCurrentWorkingHrs();
  }, [brand]);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let curr = currentCokitchen?.brands
      ?.filter(item => item?.item_type === 'SHOP')
      ?.map(item => { return ({ ...item, label: item.name, value: item.id }) })
      || [];

    setCurrentBrands(curr);
  }, [currentCokitchen]);

  // Set brand data based on current brand
  useEffect(() => {
    setAllTags();
    let i, j, k, l;

    let keyOptions = [];
    let tagOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let keyCopy = brand.brand_keywords || [];
    let tagsCopy = brand.brand_tags || [];
    let descCopy = brand.brand_descriptive_metadatas || [];
    let bussCopy = brand.brand_business_metadatas || [];
    let descriptionCopy = brand.description || "";
    let summaryCopy = brand.summary || "";
    let shortTextCopy = brand.short_text || "";
    let logoCopy = brand.logo || "";
    let imageCopy = (brand.images && brand.images[0]) || "";
    let isNew = brand.new || false
    let brandName = brand.name

    setBrandDescription(descriptionCopy);
    setBrandName(brandName)
    setBrandSummary(summaryCopy);
    setIsNew(isNew)
    setShortText(shortTextCopy);
    setLogoDisplay(logoCopy);
    setImageDisplay(imageCopy);
    for (i = 0; i < tagsCopy.length; i++) {
      tagOptions.push({
        ...tagsCopy[i],
        label: tagsCopy[i].name,
        value: tagsCopy[i].id,
      });
    }
    setTags(tagOptions);

    for (j = 0; j < keyCopy.length; j++) {
      keyOptions.push({
        ...keyCopy[j],
        label: keyCopy[j].name,
        value: keyCopy[j].id,
      });
    }
    setKeywords(keyOptions);

    for (k = 0; k < descCopy.length; k++) {
      descOptions.push({
        ...descCopy[k],
        label: descCopy[k].name,
        value: descCopy[k].id,
      });
    }
    setDescriptiveMetadatas(descOptions);

    for (l = 0; l < bussCopy.length; l++) {
      bussOptions.push({
        ...bussCopy[l],
        label: bussCopy[l].name,
        value: bussCopy[l].id,
      });
    }
    setBusinessMetadatas(bussOptions);
  }, [brand]);

  //  Load Current working hours based on current brand

  const loadCurrentWorkingHrs = async () => {
    setAltLoading(true);
    const { getWorkingHrs, workingHours, updateWorkingHrs } = BrandStore;
    let m, n;
    let workingHrCopy = openingsHrs;
    let id = brand && brand.id;

    let res = await getWorkingHrs(alert, id);
    let matched = false;

    for (m = 0; m < res?.length; m++) {
      for (n = 0; n < workingHrCopy?.length; n++) {
        if (
          res &&
          res[m] &&
          res[m].name &&
          workingHrCopy &&
          workingHrCopy[n] &&
          workingHrCopy[n].name &&
          workingHrCopy[n].name.toLocaleLowerCase() &&
          res[m].name.toLocaleLowerCase() ===
          workingHrCopy[n].name.toLocaleLowerCase()
        ) {
          workingHrCopy[n] = res[m];
          matched = true;
        }
      }
    }

    if (!matched) {
      workingHrCopy = [
        { name: "monday", opening_time: "", closing_time: "" },
        { name: "tuesday", opening_time: "", closing_time: "" },
        { name: "wednesday", opening_time: "", closing_time: "" },
        { name: "thursday", opening_time: "", closing_time: "" },
        { name: "friday", opening_time: "", closing_time: "" },
        { name: "saturday", opening_time: "", closing_time: "" },
        { name: "sunday", opening_time: "", closing_time: "" },
      ];
    }

    setAltLoading(false);
    setOpeningHrs(workingHrCopy);
    updateWorkingHrs(workingHrCopy);
  };

  // Load tags from store
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) { }
  };

  // Sort tags
  const setAllTags = () => {
    let { tags } = TagsStore;
    const tagsCopy = JSON.parse(JSON.stringify(tags));
    setSuggestions(tagsCopy?.brand_tag);
    let curr = tagsCopy?.brand_keyword
      ?.filter(item => item?.item_type === 'SHOP')
    setKeywordsSuggestions(curr);
    let currDescriptive = tagsCopy?.brand_descriptive_metadata
      ?.filter(item => item?.item_type === 'SHOP')
    setDescriptiveMetadatasSuggestions(currDescriptive);
    let currBuss = tagsCopy?.brand_business_metadata
      ?.filter(item => item?.item_type === 'SHOP')
    setBusinessMetadatasSuggestions(currBuss);
  };
  // Sort working hours format for the api request body
  const sortWorkingHours = () => {
    let items = [...openingsHrs],
      itemsCopy = [];

    let i;

    for (i = 0; i < openingsHrs.length; i++) {
      if (items[i].opening_time && items[i].closing_time) {
        delete items[i].checked;
        delete items[i].updated_at;
        delete items[i].created_at;
        delete items[i].id;

        items[i].brand_id = brand?.id;
        items[i].active = true;
        items[i].opening_time =
          moment(items[i].opening_time).format("HH:MM A") == "Invalid date"
            ? items[i].opening_time
            : moment(items[i].opening_time).format("HH:MM A");
        items[i].closing_time =
          moment(items[i].closing_time).format("HH:MM A") == "Invalid date"
            ? items[i].closing_time
            : moment(items[i].closing_time).format("HH:MM A");
        itemsCopy.push(items[i]);
      } else {
        delete items[i].checked;
        delete items[i].updated_at;
        delete items[i].created_at;
        delete items[i].id;
        delete items[i].opening_time;
        delete items[i].closing_time;

        items[i].brand_id = brand?.id;
        items[i].active = false;
        itemsCopy.push(items[i]);
      }
    }

    return itemsCopy;
  };

  // Update brand info
  const brandUpdate = async (e) => {
    e.preventDefault();
    const { updateBrands, getBrands, postWorkingHrs } = BrandStore;
    const { getCokitchens } = CommonStore;
    if (!brand) {
      alert.show("Please select a brand to update", {
        type: "info",
      });
    } else if (!imageDisplay) {
      alert.show("Please upload an image for this brand", {
        type: "info",
      });
    } else if (!logoDisplay) {
      alert.show("Please upload a logo for this brand", {
        type: "info",
      });
    } else {
      setSubmitting(true);
      let url;
      if (brandImage) {
        const formData = new FormData();
        const CLOUDINARY_CLOUDNAME = process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
        const CLOUDINARY_UPLOAD_PRESET =
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
        formData.append("file", brandImage);
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        const urlRes = await axios.post(
          `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
          formData
        );
        if (urlRes && urlRes.data) {
          url = urlRes && urlRes.data && urlRes.data.url;
        }
      }
      let logoUrl;
      if (brandLogo) {
        const formData = new FormData();
        const CLOUDINARY_CLOUDNAME = process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
        const CLOUDINARY_UPLOAD_PRESET =
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
        formData.append("file", brandLogo);
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        const urlRes = await axios.post(
          `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
          formData
        );
        if (urlRes && urlRes.data) {
          logoUrl = urlRes && urlRes.data && urlRes.data.url;
          setLogoDisplay(logoUrl);
        }
      }

      let keyCopy = [],
        descCopy = [],
        bussCopy = [];

      for (let i = 0, len = keywords.length; i < len; i++) {
        keyCopy.push({ name: keywords[i].name, id: keywords[i].id });
      }

      for (let i = 0, len = descriptiveMetadatas.length; i < len; i++) {
        descCopy.push({
          name: descriptiveMetadatas[i].name,
          id: descriptiveMetadatas[i].id,
        });
      }
      for (let i = 0, len = businessMetadatas.length; i < len; i++) {
        bussCopy.push({
          name: businessMetadatas[i].name,
          id: businessMetadatas[i].id,
        });
      }

      let payload = {
        name:brandName,
        brand_id: brand.id,
        summary: brandSummary,
        description: brandDescription,
        short_text: shortText,
        logo: logoUrl || logoDisplay,
        images: (url && [url]) || [imageDisplay],
        brand_keywords: keyCopy ? keyCopy : "",
        brand_descriptive_metadatas: descCopy ? descCopy : "",
        brand_business_metadatas: bussCopy ? bussCopy : "",

        new: isNew

      };

      payload = cleanPayload(payload);
      await updateBrands(alert, payload);

      await postWorkingHrs(alert, {
        days: sortWorkingHours(),
      });

      setSubmitting(false);
      alert.show("Fetching updates...", {
        type: "info",
      });
      await getBrands(alert);
      let ckRes = await getCokitchens(alert, false);
      if (ckRes && ckRes.data && ckRes.data.brands) {
        setBrands(ckRes.data.brands);
        setCurrentBrands(ckRes.data.brands);
      }
    }
  };

  const onDelete = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allSuggestions = [].concat(suggestionState, tag);
    const allTags = [...tagState];
    allTags.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const onAddition = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allTags = [].concat(tagState, tag);
    const allSuggestions = [...suggestionState];
    allSuggestions.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const setOpeningHrsFields = (index, field, val) => {
    let fields = [...openingsHrs];
    fields[index][field] = val;
    fields[index].active = true;

    setOpeningHrs(fields);
  };

  const setOpeningHrsFieldsCB = (index) => {
    let fields = [...openingsHrs];

    fields[index] = {
      ...fields[index],
      opening_time: "",
      closing_time: "",
      active: false,
    };
    setOpeningHrs(fields);
    setOpeningHrsCopy(fields);
  };

  const onImageChange = async (e) => {
    setBrandImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  const onLogoChange = async (e) => {
    setBrandLogo(e);
    e && setLogoDisplay(URL.createObjectURL(e));
  };




  // Submit add form
  const formSubmit = async () => {
    const { getBrands, createBrands } = BrandStore;
    let body = form;
    if (!body?.cokitchen_id) {
      alert.show("Cokitchen is required!", {
        type: "info",
      });
    }

    else {

      try {
        setAdding(true);

        let res;
        body.item_type = 'SHOP';
        delete body.new;
        res = await createBrands(alert, body);

        if (res) {
          setAdding(false);
          setShowCreateModal(false);

          alert.show("Fetching updates...", {
            type: "info",
          });

          await getBrands(alert, false);
          await CommonStore.getCokitchens(alert);
          setCokitchens(CommonStore.cokitchens);
          setBrands(CommonStore.brands);

        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
          errMsg ||
          "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();

      }
    }
  };
  const deleteBrand = async () => {
    const { deleteBrands } = BrandStore;
    if (!brand) {
      alert.show("Please select a brand to delete", {
        type: "info",
      });
    }
    else {
      setDeleting(true);
      await deleteBrands(brand?.id, alert);
      setDeleting(false);
      setShowPrompt(false);
    }

  }
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab brandInfo="active_nav_item" />

            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">Brand info</h6>

                <Button
                  withIcon
                  icon={<PlusIcon width="13px" height="13px" fill="#2699FB" />}
                  text="New Brand"
                  onClick={() => {
                    setForm({
                      name: "",
                      posist_data: {
                        customer_key: makeCode(15),
                      },
                      cokitchen_id: "",
                      posist_customer_key: makeCode(15),
                    });

                    setShowCreateModal(!showCreateModal);
                  }}
                  loading={loading}
                />
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}
                {!ckLoading ? (
                  <NavItem
                    navText="All users (all locations)"
                    onClick={() =>
                      setCurrentCokitchen({
                        brands: brands,
                        name: "All users (all locations)",
                      })
                    }
                    activeItem={
                      currentCokitchen.name === "All users (all locations)"
                    }
                  />
                ) : null}
                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen.name === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section className="cards_container px_50 seond_width_desktop_2 mb_30">
              <form
                onSubmit={(e) => brandUpdate(e)}
                className="flex_column_center"
              >
                <Select
                  placeholder="Select brand"
                  defaultValue={brand}
                  handleChange={(e) => setBrand(e)}
                  containerClass="width_full "
                  options={currentBrands}
                />
                <Input
                  placeholder="Brand Name"
                  value={brandName}
                  onChange={(e) => setBrandName(e)}
                  required
                  containerClass="width_full mb_35"
                />
                <Input
                  placeholder="Brand summary"
                  value={brandSummary}
                  onChange={(e) => setBrandSummary(e)}
                  required
                  containerClass="width_full mb_35"
                />
                <Input
                  placeholder="Short Text"
                  value={shortText}
                  onChange={(e) => setShortText(e)}
                  containerClass="width_full mb_35"
                  maxLength="15"
                />
                <div className="flex_row_left width_full mb_35">
                  <div className="flex_column_left mr_25">
                    <ImageFile
                      onChange={(e) => {
                        onImageChange(e.target.files[0]);
                      }}
                      src={imageDisplay}
                      text="Upload Image"
                    />
                    <label className="gray_label mt_10">
                      Main brand image:
                    </label>
                  </div>
                  <div className="flex_column_left">
                    <ImageFile
                      onChange={(e) => {
                        onLogoChange(e.target.files[0]);
                      }}
                      src={logoDisplay}
                      text="Upload logo"
                    />
                    <label className="mt_10 gray_label">Brand logo:</label>
                  </div>
                </div>

                <textarea
                  className="text_area text_area_short mb_35"
                  placeholder="Full brand description "
                  value={brandDescription}
                  onChange={(e) => {
                    setBrandDescription(e.target.value);
                  }}
                  required
                />

                <div className="flex_row_left mb_25 width_full">
                  <CheckBox
                    id="is_new"
                    label={`Is new?`}
                    checked={isNew}
                    onClick={() => {
                      setIsNew(!isNew);
                    }}
                  />
                </div>

                <div className="flex_column width_full mb_35">
                  <label className="bold_18_px width_full mb_25">
                    Opening hours
                  </label>

                  {altLoading ? (
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  ) : (
                    openingsHrs.map((item, i) => {
                      return (
                        <div className="flex_row_between mb_15">
                          <label className="mr_15 bold_21px day_label">
                            {item.name}:
                          </label>
                          <IconInput
                            withLabel={i === 0 ? true : false}
                            label="Opening time:"
                            value={item?.active ? item?.opening_time : ""}
                            onChange={(e) =>
                              setOpeningHrsFields(i, "opening_time", e)
                            }
                            icon={Clock}
                            type="time"
                            containerClass="mr_10"
                          />
                          <IconInput
                            withLabel={i === 0 ? true : false}
                            label="Closing time:"
                            value={item?.active ? item?.closing_time : ""}
                            onChange={(e) =>
                              setOpeningHrsFields(i, "closing_time", e)
                            }
                            icon={Clock}
                            containerClass="ml_10"
                            type="time"
                          />
                          <span className="ml_15_t_5">
                            <CheckBox
                              checked={item?.active}
                              onClick={() => {
                                setOpeningHrsFieldsCB(i, "checked");
                              }}
                              className="m_xl_0"
                            />
                          </span>
                        </div>
                      );
                    })
                  )}
                </div>
                <div className="flex_row_center">
                  <Button
                    type="submit"
                    text={submitting ? "Updating..." : "Update brand info"}
                    loading={submitting}
                  />

                  <Button
                    text={"Delete Brand"}
                    className="ml_15"
                    loading={false}
                    isRed
                    type="button"
                    onClick={() => setShowPrompt(true)}
                  />
                </div>
              </form>
            </section>
            <section className="m_xl_0 second_width_desktop align_left outside_tag_container">
              <h6 className="bold_18_px mb_35">Brand Tags & Metadata</h6>

              <Tags
                title="keywords"
                showTagDrop={showKeywordDrop}
                setShowTagDrop={setShowKeywordDrop}
                suggestions={keywordsSuggestions}
                onAddition={onAddition}
                tags={keywords}
                defaultTags={keywords}
                onDelete={onDelete}
                setTags={setKeywords}
                setSuggestions={setKeywordsSuggestions}
              />

              <Tags
                title="Descriptive metadatas"
                showTagDrop={showDescriptiveMetadatasDrop}
                setShowTagDrop={setShowDescriptiveMetadatasDrop}
                suggestions={DescriptiveMetadatasSuggestions}
                onAddition={onAddition}
                tags={descriptiveMetadatas}
                onDelete={onDelete}
                setTags={setDescriptiveMetadatas}
                setSuggestions={setDescriptiveMetadatasSuggestions}
              />
              <Tags
                title="Business metadatas"
                showTagDrop={showBusinessMetadatasDrop}
                setShowTagDrop={setShowBusinessMetadatasDrop}
                suggestions={businessMetadatasSuggestions}
                onAddition={onAddition}
                tags={businessMetadatas}
                onDelete={onDelete}
                setTags={setBusinessMetadatas}
                setSuggestions={setBusinessMetadatasSuggestions}
              />
            </section>
          </section>
        </section>
      </div>

      <Modal
        title="Create Brand"
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(!showCreateModal)}
        customContent={
          <div>
            <Input
              placeholder="Enter brand name"
              value={form.name}
              onChange={(e) => {
                setForm({ ...form, name: e });
              }}
              required
              containerClass="mb_15"
            />

            <Select
              placeholder="Select cokitchen"
              handleChange={(e) => {
                setForm({
                  ...form,
                  cokitchen_id: e?.id,
                  //  posist_data:e?.posist_data,
                });
              }}
              containerClass=""
              options={cokitchens}
              name="name"
              id="id"
            />
            <div className="flex_row_left mb_25 width_full">
              <CheckBox
                id="is_new"
                label={`Is new?`}
                checked={form?.new}
                onClick={() => {
                  setForm({
                    ...form,
                    new: !form?.new,
                  });
                }}
              />
            </div>
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={adding ? "Creating..." : "Create Brand"}
        loading={adding}
      />

      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          deleteBrand();
        }}
        saveText="Cancel"
        loading={deleting}
        title={`Are you sure you want to delete ${form?.name}?`}
        deleteText={deleting ? "Deleting..." : "Delete brand"}
      />
    </main>
  );
};
export default withAlert()(BrandInfo);
