import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import { FAQ_URL, FAQ_ARRANGEMENT_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class FaqStore {
  @observable faqs = [];
  @observable dataCached = false;

  getFaqs = async (alert) => {
    try {
      let response = await dataService.getAuthData(FAQ_ARRANGEMENT_URL);

      runInAction(() => {
        let res = response.data && response.data.data;

        if (!res[0]) {
          
          res.shift();
        }
        this.faqs = res;
        alert.show("FAQs loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postFaqs = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(FAQ_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("FAQ created successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateFaqs = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(FAQ_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("FAQ updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateFaqsArrangement = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(FAQ_ARRANGEMENT_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("FAQs rearranged successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteFaqs = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(`${FAQ_URL}/${id}`);

      runInAction(() => {
        if (response && response.data) {
          alert.show("FAQ deleted successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };




  

}

export default new FaqStore();
