import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
import Input from "../../components/inputs";
import commonStore from "../../stores/dataStore/commonStore";
import UserStore from "../../stores/userStore/user";
import { withAlert } from "react-alert";
import Select from "../../components/select";

const PushNotifications = ({ alert }) => {
  //  const [subject, setSubject] = useState("");
  const [body, setBody] = useState({ title: "", body: "", users: [] });

  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    const { users, getUsers, dataCached } = UserStore;
    setLoading(true);
    if (!dataCached) {
      await getUsers(alert, false);
    }
    setLoading(false);

    setUsersOptions();
  }, []);

  // Sort users options for react select
  const setUsersOptions = () => {
    let opts = UserStore.users;
    let options = [];
    let i;
    for (i = 0; i < opts.length; i++) {
      options.push({
        ...opts[i],
        name: opts[i].first_name + " " + opts[i].last_name,
      });
    }
    setUsers(options);
  };
  const submitForm = async (e) => {
    e.preventDefault();
    const { postPosts } = commonStore;
    setSubmitting(true);
    await postPosts(alert, body);
    setSubmitting(false);
  
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab notifications="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">Push notifications</h6>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}></aside>
            ) : null}
            <section className="cards_container px_50">
              <form
                onSubmit={(e) => submitForm(e)}
                className="flex_column_right third_width_desktop"
              >
                <Input
                  placeholder="Notification subject"
                  value={body.title}
                  onChange={(e) => setBody({ ...body, title: e })}
                  required
                  containerClass="width_full mb_30"
                  withLabel
                  label="Notification subject"
                />
                <div className="width_full">
                  <label className="mb_15 normal_17px">Notification body</label>
                  <textarea
                    className="text_area text_area_shorter"
                    placeholder="Notification body"
                    value={body.body}
                    onChange={(e) => setBody({ ...body, body: e.target.value })}
                    required
                  />
                </div>
                <div className="width_full">
                  <label className="mb_15 normal_17px">
                    Select customers{" "}
                    {`   (${users?.length}). Leave this field blank to send to all customers`}
                  </label>
                  <Select
                    placeholder="Select customers"
                    defaultValue={[]}
                    handleChange={(e) => {
                      let i;
                      let copy = [];
                      for (i = 0; i < e.length; i++) {
                        copy.push(e[i].id);
                      }
                      setBody({ ...body, users: copy });
                    }}
                    containerClass="width_full"
                    options={users}
                    isMulti
                    name="name"
                    id="id"
                  />
                  <Button
                    text={
                      submitting
                        ? "Sending notification..."
                        : "Send notification"
                    }
                    type="submit"
                    className="mt_35"
                  />
                </div>
              </form>
            </section>
            <section className="desktop_visible"></section>
          </section>
        </section>
      </div>
    </main>
  );
};
export default withAlert()(PushNotifications);
