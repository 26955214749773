import React, { useState, useRef, useEffect } from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import mealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import { CheckBox } from "../checkBoxes";
import IconInput from "../inputs/iconInput";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import Select from "../select";
import { FiChevronDown } from 'react-icons/fi'
import { CSV_TITLES_NEW } from "../csvParserNew/utils";
import ImageFile from "../imageFile";

const MealListAcc = observer(({
  i,
  item,
  isChecked,
  handleMealCheckboxChange,
  titles,
  brandData,
  handleMealInputChange,
  isNew
}) => {

  const [setActive, setActiveState] = useState(i === 0 ? 'activeAccordion' : 'activeAccordion')
  const [showAcc, setShowAcc] = useState(i === 0 ? true : true)
  const [setRotate, setRotateState] = useState(i === 0 ? 'accordion__icon rotate' : 'accordion__icon')
  const content = useRef(null);

  const {csvNewData}=mealCategoryStore;
  const brandString = CSV_TITLES_NEW.brandString;
  const categoryString = CSV_TITLES_NEW.categoryString;
  const rateString = CSV_TITLES_NEW.rateString;
  const parentString = CSV_TITLES_NEW.parentString;
  const nameString = CSV_TITLES_NEW.nameString;
  const alcoholString = CSV_TITLES_NEW?.alcoholString;
  const homepageString = CSV_TITLES_NEW?.showOnHomePageString;
  const ageString = CSV_TITLES_NEW?.ageString;
  const quantityString = CSV_TITLES_NEW?.quantityString;
  const imageString = CSV_TITLES_NEW?.imageString;
  const prepString = CSV_TITLES_NEW?.prepString;

  useEffect(()=>{
    console.log(item)
   },[item])

  function toggleAccordion() {
    setActiveState(setActive === '' ? 'activeAccordion' : '')
    setShowAcc(
      setActive === 'activeAccordion'
        ? false
        : true
    )
    setRotateState(
      setActive === 'activeAccordion'
        ? 'accordion__icon'
        : 'accordion__icon rotate'
    )
  }

  return (

    <div
      className="text_card mb_30 black flex_column_left cursor_pointer position_relative width_full"
      key={i + "item"}

    >
      <div className="flex_row_between width_full">
        <div className=" bold" >
          {i + 1}. {item[nameString]}
        </div>
        <div className="flex_row_between " >
          <CheckBox
            id={"is_addon" + i}
            mb=""
            checked={isChecked}
            onClick={() => { handleMealCheckboxChange(item) }}
            label={`${isChecked ? "Deselect" : "Select"} meal`}
          />


          <button
            style={{
              background: 'rgba(0, 0, 0, 0.1)',
              borderRadius: '50%',
              width: 28,
              height: 28,
              marginLeft: "10px"
            }}
            className={`flex_row_center cursor_pointer accordion ${setActive} ${setRotate}`}
            onClick={toggleAccordion}
            type="button"
          >
            <FiChevronDown size={30} />
          </button>
        </div>

      </div>
      <div
        className="grid_third_two width_full  mt_35 accordion__content"
        ref={content}
        style={{ display: `${showAcc ? "grid" : "none"}` }}
      >
        {titles
          ?.filter(itm => itm !== parentString)
          ?.map((el, ind) => {
            let isBrand = el?.toUpperCase() === brandString?.toUpperCase(),
              isMealCategory = el?.toUpperCase() === categoryString?.toUpperCase();
            brandData = isBrand && item[el]

            return (
              <div className="mb_12" key={el + ind}>
                {el?.toUpperCase() === homepageString?.toUpperCase() ? (
                  <div
                    className="flex_column_left mt_25"
                    style={{ height: "100%" }}
                  >
                    <CheckBox
                      id={"show_homepage" + i}
                      checked={item[el]}
                      onClick={() => {
                        if (!isChecked) {
                          return;
                        } else {
                          handleMealInputChange(item, el, item[el]);
                        }
                      }}
                      label={el}
                      disabled={!isChecked}
                    />
                  </div>
                ) : null}
                {el?.toUpperCase() === alcoholString?.toUpperCase() ? (
                  <div
                    className="flex_column_left mt_25"
                    style={{ height: "100%" }}
                  >
                    <CheckBox
                      id={"is_alcohol" + i}
                      checked={item[el]}
                      onClick={() => {
                        if (!isChecked) {
                          return;
                        } else {
                          handleMealInputChange(item, el, item[el]);
                        }
                      }}
                      label={el}
                      disabled={!isChecked}
                    />
                  </div>
                ) : (isBrand || isMealCategory) && !isNew ? (
                  <div className="flex_column_left">
                    <label className="mb_15 normal_17px">{el}</label>
                    <Select
                      disabled={!isChecked}
                      placeholder={
                        isBrand ? "Select brand" : "Select meal category"
                      }
                      value={item[el]}
                      handleChange={(e) => {
                        handleMealInputChange(item, el, e);
                      }}
                      containerClass=""
                      options={
                        isBrand
                          ? BrandStore.brandsAlt
                          : csvNewData[i][brandString]?.meal_categories
                      }
                      name="name"
                      id="id"
                    />
                  </div>
                ) : el?.toUpperCase() === "PLACEHOLDER" ? (
                  <div />
                ) : el?.toUpperCase() === imageString?.toUpperCase() ? (
                  <ImageFile
                    onChange={(e) => {
                      handleMealInputChange(item, el, e.target.files[0]);
                    }}
                    src={
                      typeof item[el] === "object"
                        ? URL.createObjectURL(item[el])
                        : item[el]
                    }
                  />
                ) : (
                  <IconInput
                    disabled={!isChecked}
                    required={
                      el === nameString ||
                      el === rateString ||
                      el === categoryString
                    }
                    label={el}
                    value={item[el]}
                    onChange={(e) => handleMealInputChange(item, el, e)}
                    type={
                      el === quantityString ||
                      el === prepString ||
                      el === ageString
                        ? "number"
                        : "text"
                    }
                  />
                )}
              </div>
            );
          })

        }
      </div>

    </div>

  )

});
export default MealListAcc