import React, { useState, useEffect } from 'react'
import './styles.scss'
import Header from '../../components/header'
import Tab from '../../components/tabs'
import { Button } from '../../components/buttons'
import { ArrowBack, Clock, DateIcon } from '../../assets/icons/icons'
import TextEditor from '../../components/textEditor'
import Input from '../../components/inputs'
import IconInput from '../../components/inputs/iconInput'
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromHTML
} from 'draft-js'
import { CheckBoxRound, CheckBox } from '../../components/checkBoxes'
import { useHistory } from 'react-router-dom'
import { DEALS_DISCOUNTS } from '../../router/router'

import { withAlert } from 'react-alert'
import dealStore from '../../stores/dataStore/dealStore'
import draftToHtml from 'draftjs-to-html'
import ImageFile from '../../components/imageFile'
import BrandStore from '../../stores/dataStore/brandInfoStore'
import UserStore from '../../stores/userStore/user'
import Select from '../../components/select'
import axios from 'axios'
import moment from 'moment'
import PageLoading from '../../components/loader'
import CommonStore from '../../stores/dataStore/commonStore'
import Prompt from '../../components/modal/prompt'
const AddDiscount = ({ alert, ...props }) => {
  const history = useHistory()
  const path = props.match.params.id
  const [discountCode, setDiscountCode] = useState('')
  const [discountType, setDiscountType] = useState('')
  const [discountValue, setDiscountValue] = useState('')
  const [discountTarget, setDiscountTarget] = useState('')
  const [discountTargetBrandName, setDiscountTargetBrandName] = useState([])
  const [discountTargetCokitchen, setDiscountTargetCokitchen] = useState(null)
  const [discountMinReq, setDiscountMinReq] = useState('')
  const [minReqValue, setMinReqValue] = useState('')
  const [minReqAmount, setMinReqAmount] = useState('')
  const [discountCustEligibility, setDiscountCustEligibility] = useState('')
  const [discountUsageLimit, setDiscountUsageLimit] = useState(
    'no_of_use_limit'
  )
  const [discountUsageLimitValue, setDiscountUsageLimitValue] = useState('')
  const [discountStartDate, setDiscountStartDate] = useState('')
  const [discountStartTime, setDiscountStartTime] = useState('')
  const [discountEndDate, setDiscountEndDate] = useState('')
  const [discountEndTime, setDiscountEndTime] = useState('')
  const [endDateSet, setEndDateSet] = useState(false)

  const [discountPostTitle, setDiscountPostTitle] = useState('')
  const [discountPostHeading, setDiscountPostHeading] = useState('')
  const [sendPushNot, setSendPushNot] = useState(false)
  const [addToHomeHeader, setAddToHomeHeader] = useState(false)
  const [textEditorState, setTextEditorState] = useState('')
  const [image, setImage] = useState(null)

  const [imageDisplay, setImageDisplay] = useState(null)
  //  localStorage.getItem("textedit")
  const [dealTypes, setDealTypes] = useState([])
  const [dealEligibilityTypes, setDealEligibilityTypes] = useState([])
  const [dealValueTypes, setDealValueTypes] = useState([])
  const [dealRequirementTypes, setDealRequirementTypes] = useState([])
  const [brands, setBrands] = useState([])

  const [users, setUsers] = useState([])
  const [selectedUsers, setSelectedUsers] = useState([])
  // const [discountImage, setDiscountImage] = useState("");
  // const [discountPostBody, setDiscountPostBody] = useState("");
  const [submitting, setSubmitting] = useState(false)
  const [deleting, setDeleting] = useState(false)
  const [cokitchens, setCokitchens] = useState([])
  const [loading, setLoading] = useState(false)
  const [ckLoading, setCkLoading] = useState(false)
  const [dealVisible, setDealVisible] = useState(true)
  const [dealCap, setDealCap] = useState('')
  const [deals, setDeals] = useState([])
  const [currentDeal, setCurrentDeal] = useState([])
  const [userDefaults, setUserDefaults] = useState([])
  const [brandDefaults, setBrandDefaults] = useState([])
  const [cokitchenDefault, setCokitchenDefault] = useState('')
  const [showPrompt, setShowPrompt] = useState(false)
  useEffect(() => {
    window.scrollTo({
      top: 0
    })
  }, [])
  const submitForm = e => {
    e.preventDefault()
  }
  // load deals data from store
  useEffect(async () => {
    path === 'discount_post' && setLoading(true)
    if (
      !dealStore.dataCached &&
      !UserStore.dataCached &&
      !BrandStore.dataCached
    ) {
      setLoading(true)
      setCkLoading(true)
      await Promise.all([
        dealStore.getDeals(alert),
        dealStore.getDealTypes(alert),
        UserStore.getUsers(alert, false),
        BrandStore.getBrands(alert),
        CommonStore.getCokitchens(alert, false)
      ])
    } else if (!dealStore.dataCached && BrandStore.dataCached) {
      await Promise.all([
        dealStore.getDeals(alert),
        dealStore.getDealTypes(alert),
        CommonStore.getCokitchens(alert, false)
      ])
    } else if (
      dealStore.dataCached &&
      !UserStore.dataCached &&
      !BrandStore.dataCached
    ) {
      await Promise.all([
        UserStore.getUsers(alert, false),
        BrandStore.getBrands(alert),
        CommonStore.getCokitchens(alert, false)
      ])
    } else if (
      dealStore.dataCached &&
      BrandStore.dataCached &&
      !UserStore.dataCached
    ) {
      await Promise.all([
        UserStore.getUsers(alert, false),
        CommonStore.getCokitchens(alert, false)
      ])
    } else if (
      dealStore.dataCached &&
      !BrandStore.dataCached &&
      UserStore.dataCached
    ) {
      await Promise.all([
        BrandStore.getBrands(alert),
        CommonStore.getCokitchens(alert, false)
      ])
    }

    setCokitchens(CommonStore.cokitchens)
    setAllDeals()
    setDealDataTypes()
    setBrandOptions()
    setUsersOptions()
    findCurrentDeal()
    path !== 'discount_post' && (await setAllDefaults())
    path !== 'discount_post' && setCurrentData()
    setLoading(false)
  }, [])

  // collect all deals in an array
  const setAllDeals = () => {
    let allDealsCopy = []
    let allDeals = dealStore.deals
    let i, j, k
    for (i = 0; i < allDeals.length; i++) {
      if (allDeals && allDeals[i] && allDeals[i].deals && allDeals[i].deals)
        for (j = 0; j < allDeals[i].deals.length; j++) {
          allDealsCopy.push(allDeals[i].deals[j])
        }
    }

    setDeals(allDealsCopy)
    return allDealsCopy
  }

  // FInd currently selected deal
  const findCurrentDeal = () => {
    let active = setAllDeals().find(item => item.id === path)
    setCurrentDeal(active)
    return active
  }

  // Set current deal data
  const setCurrentData = async () => {
    let item = findCurrentDeal()

    setDiscountCode(item?.discount_code)
    setDiscountType(item?.deal_value_type_id)
    setDiscountValue(
      item?.deal_value_type?.name === 'FIXED_AMOUNT'
        ? item.fixed_amount
        : item?.deal_value_type?.name === 'PERCENTAGE'
        ? item.rate
        : '0'
    )
    setDiscountTarget(item?.deal_type_id)
    setDiscountUsageLimitValue(item?.max_usage)
    setDiscountUsageLimit(item?.max_usage ? 'no_of_use_limit' : 'one_use_limit')
    setMinReqAmount(item?.min_amount)
    setMinReqValue(item?.min_items)

    setDiscountMinReq(item?.deal_requirement_type_id)
    setDiscountCustEligibility(item?.deal_eligibility_type_id)
    setDiscountPostTitle(item?.title)

    setDealVisible(item?.active)
    setDealCap(item?.cap)

    setDiscountPostHeading(item?.heading)
    setSendPushNot(item?.notification)
    setImageDisplay(item?.images && item.images[0])
    if (item?.body) {
      setTextEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(JSON.parse(item.body))
          )
        )
      )
    }
    setDiscountStartDate(new Date(item?.to_start_date))
    setDiscountStartTime(
      moment(item?.to_start_date + ' ' + item.to_start_time).format('LT')
    )
    setDiscountEndDate(new Date(item?.to_expire_date))
    setDiscountEndTime(
      moment(item?.to_expire_date + ' ' + item.to_expire_time).format('LT')
    )
  }

  // Set all Defaults
  const setAllDefaults = async () => {
    let item = findCurrentDeal()

    let ckDefault = setValueTypes(dealStore.deals, item?.cokitchen_id)

    setCokitchenDefault({
      id: ckDefault?.id,
      name: ckDefault?.name
    })
    let brandsDefaults = setSelectDefaults(item?.brands, false)
    setBrandDefaults(brandsDefaults)

    let usersDefaults = setSelectDefaultsAlt(
      item?.specific_customers,
      setUsersOptions()
    )
    setUserDefaults(usersDefaults)
    return brandsDefaults
  }
  // Sort brand options for react select
  const setBrandOptions = () => {
    let opts = BrandStore.brands
    let options = []
    let i
    for (i = 0; i < opts.length; i++) {
      options.push({ ...opts[i], label: opts[i].name, value: opts[i].id })
    }
    setBrands(options?.filter((item)=> item.item_type==="SHOP"))
  }

  // Sort users options for react select
  const setUsersOptions = () => {
    let opts = UserStore.users
    let options = []
    let i
    for (i = 0; i < opts.length; i++) {
      options.push({
        ...opts[i],
        label: opts[i].first_name + ' ' + opts[i].last_name,
        value: opts[i].id
      })
    }
    setUsers(options)
    return options
  }

  //Set selected brands
  const setSelectedBrands = () => {
    let options = []
    let i
    if (discountTargetBrandName.length > 0) {
      for (i = 0; i < discountTargetBrandName.length; i++) {
        options.push({
          name:
            discountTargetBrandName[i].name || discountTargetBrandName[i].label,
          id: discountTargetBrandName[i].id || discountTargetBrandName[i].value
        })
      }
    } else {
      for (i = 0; i < brandDefaults.length; i++) {
        options.push({
          name: brandDefaults[i].label,
          id: brandDefaults[i].value
        })
      }
    }
    return options
  }

  //Set selected users
  const setAllSelectedUsers = () => {
    let options = []
    let i
    if (selectedUsers.length > 0) {
      for (i = 0; i < selectedUsers.length; i++) {
        options.push(selectedUsers[i].id || selectedUsers[i].value)
      }
    } else {
      for (i = 0; i < userDefaults.length; i++) {
        options.push(userDefaults[i].id || userDefaults[i].value)
      }
    }
    return options
  }

  // Create deal
  const submitDeal = async () => {
    if (path === 'discount_post' && !image) {
      alert.show(`Please upload an image!`, {
        type: 'info'
      })
    } else if (path !== 'discount_post' && !imageDisplay) {
      alert.show(`Please upload an image!`, {
        type: 'info'
      })
    } else if (path === 'discount_post' && !discountTargetCokitchen) {
      alert.show(`Please select a cokitchen to apply deal`, {
        type: 'info'
      })
    } else {
      try {
        setSubmitting(true)
        let urlRes, url
        if (image) {
          const formData = new FormData()
          const CLOUDINARY_CLOUDNAME =
            process.env.REACT_APP_CLOUDINARY_CLOUDNAME
          const CLOUDINARY_UPLOAD_PRESET =
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET
          formData.append('file', image)
          formData.append('upload_preset', CLOUDINARY_UPLOAD_PRESET)
          urlRes = await axios.post(
            `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
            formData
          )
        }
        if (urlRes && urlRes.data) {
          url = urlRes && urlRes.data && urlRes.data.url
          setImageDisplay(url)
        }
        const payLoad = {
          deal_type_id: discountTarget,
          deal_value_type_id: discountType,
          deal_requirement_type_id: discountMinReq,
          deal_eligibility_type_id: discountCustEligibility,
          min_amount: minReqAmount || '0',
          max_usage: discountUsageLimitValue || '0',
          specific_customers:
            discountCustEligibility === dealEligibilityTypes[1].id
              ? setAllSelectedUsers()
              : [],
          min_items: minReqValue || '0',
          title: discountPostTitle,
          cap: dealCap,
          active: dealVisible,
          heading: discountPostHeading,
          body: JSON.stringify(
            draftToHtml(convertToRaw(textEditorState.getCurrentContent()))
          ),
          discount_code: discountCode,
          notification: sendPushNot,
          images: url ? [url] : [imageDisplay],
          brands: setSelectedBrands(),
          cokitchen_id: discountTargetCokitchen || cokitchenDefault?.id,
          rate:
            setValueTypes(dealValueTypes, discountType).name.toLowerCase() ===
            'percentage'
              ? discountValue
              : '0',
          fixed_amount:
            setValueTypes(dealValueTypes, discountType).name.toLowerCase() ===
            'fixed_amount'
              ? discountValue
              : '0',
          to_expire_date: moment(new Date(discountEndDate)).format(
            'YYYY-MM-DD'
          ),
          to_expire_time:
            moment(new Date(discountEndTime)).format('HH:mm:ss') ==
            'Invalid date'
              ? discountEndTime
              : moment(new Date(discountEndTime)).format('HH:mm:ss'),
          to_start_date: moment(new Date(discountStartDate)).format(
            'YYYY-MM-DD'
          ),
          to_start_time:
            moment(new Date(discountStartTime)).format('HH:mm:ss') ==
            'Invalid date'
              ? discountStartTime
              : moment(new Date(discountStartTime)).format('HH:mm:ss'),
          post: !dealVisible ? false : addToHomeHeader,
        }

        if (path !== 'discount_post') {
          delete payLoad.cokitchen_id
          delete payLoad.post
          delete payLoad.notification
        }
        !dealCap && delete payLoad.cap
        !addToHomeHeader && delete payLoad.post
        //  delete payLoad.max_usage;
        let res
        if (path === 'discount_post') {
          payLoad.item_type="SHOP";
          res = await dealStore.postDeals(alert, payLoad, history)
        } else {
          res = await dealStore.updateDeal(alert, payLoad, history, path)
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error)
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            'An error has occured!!',
          {
            type: 'error'
          }
        )
      } finally {
        dealStore.getDeals(alert)
        setSubmitting(false)
        resetForm()
      }
    }
  }
  // To delete deals
  const dealDelete = async () => {
    const { deleteDeals } = dealStore
    setDeleting(true)
    await deleteDeals(path, alert, history)
    setDeleting(false)
    setShowPrompt(false)
  }

  // Clearing  the form
  const resetForm = () => {}
  // Set deal types data from deal types endpoint
  const setDealDataTypes = () => {
    setDealTypes(dealStore.dealTypes)
    setDealEligibilityTypes(dealStore.dealEligibilityTypes)
    setDealValueTypes(dealStore.dealValueTypes)
    setDealRequirementTypes(dealStore.dealRequirementTypes)
  }
  // Text editor state chnage
  const onEditorStateChange = editorState => {
    setTextEditorState(editorState)
  }

  // Setting image url and image file states
  const onImageChange = async e => {
    setImage(e)
    e && setImageDisplay(URL.createObjectURL(e))
  }

  // Set the defaults for preselected options
  const setSelectDefaults = (items, isString) => {
    let options = []
    let i

    if (items && items.length && items.length > 0 && !isString) {
      for (i = 0; i < items.length; i++) {
        options.push({ label: items[i].name, value: items[i].id })
      }
    } else if (items && items.length && items.length > 0 && isString) {
      for (i = 0; i < items.length; i++) {
        options.push({ value: items[i] })
      }
    }

    return options
  }

  // Set the defaults for preselected brands
  const setSelectDefaultsAlt = (items, selectOptions) => {
    let options = []
    let i
    let j

    if (items && items.length && items.length > 0) {
      for (i = 0; i < items.length; i++) {
        for (j = 0; j < selectOptions.length; j++) {
          if (selectOptions[j].value === items[i]) {
            options.push({ label: selectOptions[j].label, value: items[i] })
          }
        }
      }
    }

    return options
  }

  // Set the values for deal value types

  const setValueTypes = (items, state) => {
    let active = items.find(item => item.id === state)
    return active
  }

  // Set the valid start date to today
  const validStartDate = current => {
    return current.isAfter(moment().subtract(1, 'day'))
  }
  // Settting the valid end date to start date and above
  const validEndDate = current => {
    return current.isAfter(moment(discountStartDate || '').subtract(1, 'day'))
  }

  return (
    <main className='main_container'>
      <div className='main_container_content'>
        <Header />
        <section className='main_section'>
          <div className='top_fixed'>
            <Tab deals='active_nav_item' />
            <div className='border_bottom_thick'>
              <div className='flex_row_between title_bar'>
                <div className='flex_row_center'>
                  <button
                    className='mr_30 cursor_pointer'
                    onClick={() => history.push(DEALS_DISCOUNTS)}
                  >
                    <ArrowBack width='17px' height='17px' fill='#FF0000' />
                  </button>

                  <h6 className='bold_24px'>New discount & post</h6>
                </div>
                {path !== 'discount_post' ? (
                  <Button
                    type='button'
                    className=''
                    isRed
                    text='Delete deal'
                    onClick={() => setShowPrompt(true)}
                  />
                ) : null}
              </div>
            </div>
          </div>
          {loading ? (
            <PageLoading
              loading={loading}
              style={{ backgroundColor: '#2698fb2f', top: '200px' }}
              logoStyle={{ marginTop: '15%' }}
            />
          ) : (
            <form
              className='form_content_section flex_column easein'
              onSubmit={e => {
                e.preventDefault()
                submitDeal()
              }}
            >
              <section className='forms_container flex_row_top tab_column'>
                {/* ******* Form section one ****** */}

                <section className='pr_20 width_52'>
                  <Input
                    placeholder='Discount code e.g. SAVE15'
                    value={discountCode}
                    onChange={e => setDiscountCode(e)}
                    required
                    containerClass='third_width_desktop'
                  />
                  <div className='flex_row_center_top mt_25 third_width_desktop'>
                    <div className='grid_second_two width_full'>
                      {dealValueTypes.map((item, i) => {
                        return (
                          <CheckBoxRound
                            key={item.id}
                            id={item.id}
                            label={item.name}
                            checked={discountType === item.id}
                            onClick={() => setDiscountType(item.id)}
                            className='flex_row_start'
                          />
                        )
                      })}
                    </div>
                  </div>
                  <div className='flex_row_top mb_35 mobile_column'>
                    <Input
                      placeholder={`${
                        setValueTypes(dealValueTypes, discountType) &&
                        setValueTypes(
                          dealValueTypes,
                          discountType
                        ).name.toLowerCase() === 'percentage'
                          ? '%'
                          : setValueTypes(dealValueTypes, discountType) &&
                            setValueTypes(
                              dealValueTypes,
                              discountType
                            ).name.toLowerCase() === 'fixed_amount'
                          ? '₦'
                          : ''
                      }`}
                      value={discountValue}
                      onChange={e => setDiscountValue(e)}
                      withLabel
                      label={`Discount value ${
                        setValueTypes(dealValueTypes, discountType) &&
                        setValueTypes(
                          dealValueTypes,
                          discountType
                        ).name.toLowerCase() === 'percentage'
                          ? '%'
                          : setValueTypes(dealValueTypes, discountType) &&
                            setValueTypes(
                              dealValueTypes,
                              discountType
                            ).name.toLowerCase() === 'fixed_amount'
                          ? '₦'
                          : ''
                      }`}
                      containerClass='mr_10 quater_width_desktop mt_35_tab'
                      type='number'
                      max={
                        setValueTypes(dealValueTypes, discountType) &&
                        setValueTypes(
                          dealValueTypes,
                          discountType
                        ).name.toLowerCase() === 'percentage' &&
                        '0.99'
                      }
                      step={
                        (setValueTypes(dealValueTypes, discountType) &&
                          setValueTypes(
                            dealValueTypes,
                            discountType
                          ).name.toLowerCase() === 'percentage' &&
                          '0.01') ||
                        undefined
                      }
                      min='0'
                    />
                    <div className='flex_column_left mt_35_tab'>
                      <label className='normal_17px mb_30'> Applies to:</label>

                      <div className='flex_row_between fourth_width_desktop mobile_column_two'>
                        <CheckBoxRound
                          id={dealTypes[1] && dealTypes[1].id}
                          label='All brands'
                          checked={
                            dealTypes[1] &&
                            dealTypes[1].id &&
                            discountTarget === dealTypes[1].id
                          }
                          onClick={() =>
                            setDiscountTarget(dealTypes[1] && dealTypes[1].id)
                          }
                        />
                      </div>
                      <Select
                        placeholder='Select cokitchen'
                        defaultValue={cokitchenDefault}
                        handleChange={e => setDiscountTargetCokitchen(e.id)}
                        containerClass='width_300 m_left_15'
                        options={cokitchens}
                        name='name'
                        id='id'
                      />
                      <div className='flex_row_between fourth_width_desktop mobile_column_two'>
                        <CheckBoxRound
                          id={dealTypes[0] && dealTypes[0].id}
                          label='Specific brand'
                          checked={
                            dealTypes[0] &&
                            dealTypes[0].id &&
                            discountTarget === dealTypes[0].id
                          }
                          onClick={() =>
                            setDiscountTarget(dealTypes[0] && dealTypes[0].id)
                          }
                        />

                        {dealTypes[0] &&
                        dealTypes[0].id &&
                        discountTarget === dealTypes[0].id ? (
                          <Select
                            placeholder='Select brands'
                            defaultValue={brandDefaults}
                            handleChange={e => setDiscountTargetBrandName(e)}
                            containerClass='width_300 m_left_15'
                            options={brands}
                            isMulti
                          />
                        ) : null}
                      </div>
                    </div>
                  </div>

                  <div className='flex_row_top mb_45 almost_width tab_column'>
                    <div className='flex_column_left mr_15'>
                      <label className='normal_17px mb_30'>
                        Minimum requirements:
                      </label>
                      <CheckBoxRound
                        id={
                          dealRequirementTypes[0] && dealRequirementTypes[0].id
                        }
                        label='None'
                        checked={
                          dealRequirementTypes[0] &&
                          dealRequirementTypes[0].id &&
                          discountMinReq === dealRequirementTypes[0]?.id
                        }
                        onClick={() =>
                          setDiscountMinReq(
                            dealRequirementTypes[0] &&
                              dealRequirementTypes[0].id
                          )
                        }
                      />
                      <CheckBoxRound
                        id={
                          dealRequirementTypes[1] && dealRequirementTypes[1].id
                        }
                        label='Minimum purchase amount'
                        checked={
                          dealRequirementTypes[1] &&
                          dealRequirementTypes[1].id &&
                          discountMinReq === dealRequirementTypes[1].id
                        }
                        onClick={() =>
                          setDiscountMinReq(
                            dealRequirementTypes[1] &&
                              dealRequirementTypes[1].id
                          )
                        }
                      />
                      {(dealRequirementTypes[1] &&
                        dealRequirementTypes[1].id &&
                        discountMinReq === dealRequirementTypes[1].id) ||
                      discountMinReq === 'min_purchase_amt' ? (
                        <Input
                          placeholder='Amount'
                          value={minReqAmount}
                          onChange={e => setMinReqAmount(e)}
                          containerClass='half_width_desktop ml_15_tab mb_30'
                          type='number'
                        />
                      ) : null}

                      <CheckBoxRound
                        id={
                          dealRequirementTypes[2] && dealRequirementTypes[2].id
                        }
                        label='Minimum quantity of items'
                        checked={
                          dealRequirementTypes[2] &&
                          dealRequirementTypes[2].id &&
                          discountMinReq === dealRequirementTypes[2].id
                        }
                        onClick={() =>
                          setDiscountMinReq(
                            dealRequirementTypes[2] &&
                              dealRequirementTypes[2].id
                          )
                        }
                      />
                      {(dealRequirementTypes[2] &&
                        dealRequirementTypes[2].id &&
                        discountMinReq === dealRequirementTypes[2].id) ||
                      discountMinReq === 'min_purchase_amt' ? (
                        <Input
                          placeholder='Value'
                          value={minReqValue}
                          onChange={e => setMinReqValue(e)}
                          containerClass='half_width_desktop ml_15_tab'
                          type='number'
                        />
                      ) : null}
                    </div>

                    <div className='flex_column_left mt_35_tab'>
                      <label className='normal_17px mb_30'>
                        Customer eligibility:
                      </label>
                      <CheckBoxRound
                        id={
                          dealEligibilityTypes[0] && dealEligibilityTypes[0].id
                        }
                        label='Everyone'
                        checked={
                          dealEligibilityTypes[0] &&
                          dealEligibilityTypes[0].id &&
                          discountCustEligibility === dealEligibilityTypes[0].id
                        }
                        onClick={() =>
                          setDiscountCustEligibility(
                            dealEligibilityTypes[0] &&
                              dealEligibilityTypes[0].id
                          )
                        }
                      />
                      {/* <CheckBoxRound
                      id="spec_group"
                      label="Specific group of users (new/ returning/ email subs)"
                      checked={discountCustEligibility === "spec_group"}
                      onClick={() => setDiscountCustEligibility("spec_group")}
                    /> */}
                      <CheckBoxRound
                        id={
                          dealEligibilityTypes[1] && dealEligibilityTypes[1].id
                        }
                        label={`Specific customers (search ${users?.length} customers in the DB)`}
                        checked={
                          dealEligibilityTypes[1] &&
                          dealEligibilityTypes[1].id &&
                          discountCustEligibility === dealEligibilityTypes[1].id
                        }
                        onClick={() =>
                          setDiscountCustEligibility(
                            dealEligibilityTypes[1] &&
                              dealEligibilityTypes[1].id
                          )
                        }
                      />

                      {dealEligibilityTypes[1] &&
                      dealEligibilityTypes[1].id &&
                      discountCustEligibility === dealEligibilityTypes[1].id ? (
                        <Select
                          placeholder='Select customers'
                          defaultValue={userDefaults}
                          handleChange={e => setSelectedUsers(e)}
                          containerClass='width_300 m_left_15'
                          options={users}
                          isMulti
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className='flex_column_left '>
                    <label className='normal_17px mb_30'>Usage limits:</label>

                    <div className='flex_row_between mobile_column_two'>
                      <CheckBoxRound
                        id='no_of_use_limit'
                        label='Choose usage limit'
                        checked={discountUsageLimit === 'no_of_use_limit'}
                        onClick={() => setDiscountUsageLimit('no_of_use_limit')}
                      />
                      {discountUsageLimit === 'no_of_use_limit' ? (
                        <Input
                          placeholder='Usage limit'
                          value={discountUsageLimitValue}
                          onChange={e => setDiscountUsageLimitValue(e)}
                          containerClass='half_width_desktop ml_15_tab'
                          type='number'
                        />
                      ) : null}
                    </div>
                  </div>

                  <div className='flex_column_left mt_35'>
                    <label className='normal_17px mb_30'>Extra settings:</label>
                    <div className=' flex_row_top tab_column width_full mb_30'>
                      <CheckBoxRound
                        id='sendPushNot'
                        label='Send as push notification '
                        checked={sendPushNot}
                        onClick={() => setSendPushNot(!sendPushNot)}
                        className='mr_15'
                      />

                      <CheckBoxRound
                        id='addToHomeHeader'
                        label='Add to home page header'
                        checked={addToHomeHeader}
                        onClick={() => setAddToHomeHeader(prev => !prev)}
                      />
                    </div>
                    <div className=' flex_row_top tab_column width_full mb_30'>
                      <CheckBoxRound
                        id='dealVisible'
                        label='Set deal as visible'
                        checked={dealVisible}
                        onClick={() => setDealVisible(!dealVisible)}
                        className='mr_15'
                      />

                      <Input
                        placeholder='Enter deal cap e.g. (1000) '
                        value={dealCap}
                        onChange={e => setDealCap(e)}
                        containerClass='half_width_desktop ml_15_tab'
                        type='number'
                        withLabel
                        label={'Deal capped at: '}
                      />
                    </div>
                  </div>
                </section>

                {/* ******* Form section two ****** */}

                <section className='form_section_two pr_30 mt_35_tab mb_45'>
                  <Input
                    placeholder='Deal post title'
                    value={discountPostTitle}
                    onChange={e => setDiscountPostTitle(e)}
                    required
                    containerClass='mb_15 '
                  />

                  <Input
                    placeholder='Deal post heading'
                    value={discountPostHeading}
                    onChange={e => setDiscountPostHeading(e)}
                    required
                    containerClass='mb_30'
                  />

                  <ImageFile
                    onChange={e => {
                      onImageChange(e.target.files[0])
                    }}
                    src={imageDisplay}
                    text='Upload Image'
                  />
                  <TextEditor
                    placeholder='Deal post body'
                    editorState={textEditorState}
                    onEditorStateChange={onEditorStateChange}
                  />
                </section>
                {/* ******* Form section three ****** */}

                <section className='form_section_three p_25 mt_35_tab'>
                  <div>
                    <p className='bold_18_px mb_30'>Discount summary:</p>

                    <div className='form_detail_content'></div>

                    <p className='bold_18_px mb_15'>Performance:</p>

                    <div className='form_detail_content'>
                      <p className=' gray_label mb_30'>
                        Discount is not active yet.
                      </p>
                    </div>
                  </div>
                </section>
              </section>

              <section
                className='flex_row_bottom mt_35_tab mobile_column'
                style={{ marginBottom: '50px' }}
              >
                <div className='flex_column mr_15'>
                  <label className='normal_17px mb_15'>Usage limits:</label>
                  <div className='flex_row_between tab_column'>
                    <IconInput
                      containerClass='second_width_desktop'
                      withLabel
                      isDate
                      label='Start date:'
                      dateValue={discountStartDate}
                      onDateChange={setDiscountStartDate}
                      icon={<DateIcon width='11px' height='11px' />}
                      dateFormat='DD/MM/YYYY'
                      isValidDate={validStartDate}
                    />
                    <IconInput
                      containerClass='second_width_desktop mt_15_tab'
                      withLabel
                      isDate
                      label='Start time:'
                      dateValue={discountStartTime}
                      onDateChange={setDiscountStartTime}
                      icon={<Clock width='11px' height='11px' fill='' />}
                      dateFormat={false}
                    />
                  </div>
                </div>

                <div className='flex_column_left mr_15 mt_35_mobile'>
                  <CheckBox
                    id='set_end_date'
                    label='No end date'
                    checked={endDateSet}
                    onClick={() => {
                      setEndDateSet(!endDateSet)
                      setDiscountEndDate(null)
                    }}
                  />
                  <div className='flex_row_between tab_column'>
                    <IconInput
                      containerClass='second_width_desktop'
                      withLabel
                      isDate
                      label='End date:'
                      dateValue={endDateSet ? null : discountEndDate}
                      onDateChange={setDiscountEndDate}
                      icon={<DateIcon width='11px' height='11px' />}
                      dateFormat='DD/MM/YYYY'
                      isValidDate={validEndDate}
                    />
                    <IconInput
                      containerClass='second_width_desktop mt_15_tab'
                      withLabel
                      isDate
                      label='End time:'
                      dateValue={discountEndTime}
                      onDateChange={setDiscountEndTime}
                      icon={<Clock width='11px' height='11px' fill='' />}
                      dateFormat={false}
                    />
                  </div>
                </div>
                <Button
                  type='submit'
                  className='mt_35_mobile'
                  text={
                    submitting && path === 'discount_post'
                      ? 'Creating deal...'
                      : submitting && path !== 'discount_post'
                      ? 'Updating deal...'
                      : !submitting && path !== 'discount_post'
                      ? 'Update deal'
                      : 'Create deal'
                  }
                  loading={submitting}
                />
              </section>
            </form>
          )}
        </section>
      </div>
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={e => {
          e.preventDefault()
          dealDelete()
        }}
        saveText='Cancel'
        loading={deleting}
        title='Are you sure you want to delete this deal?'
        deleteText={deleting ? 'Deleting...' : 'Delete'}
      />
    </main>
  )
}

export default withAlert()(AddDiscount)
