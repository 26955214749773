import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import { GET_APP_FEEDBACK_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class FeedbackStore {
  @observable feedbacks = [];
  @observable dataCached = false;

  getFeedbacks = async (alert) => {
    try {
      let response = await dataService.getAuthData(GET_APP_FEEDBACK_URL);

      runInAction(() => {
        this.feedbacks = response.data && response.data.data;
        alert.show("Feedbacks loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new FeedbackStore();
