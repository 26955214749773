import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import { ArrowBack, LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Select from "../../components/select";
import { Tags } from "../../components/appTag";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import { CheckBox } from "../../components/checkBoxes";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import { ITEM_INFO } from "../../router/router";
import { useHistory } from "react-router-dom";
import PageLoading from "../../components/loader";
import { uploadImageToCloud } from "../../helpers/uploadImagesToCloud";
import cleanPayload from "../../helpers/cleanPayload";

const AddMeal = ({ alert }) => {
  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [meal, setMeal] = useState("");
  const [meals, setMeals] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentBrands, setCurrentBrands] = useState([]);
  const [SelectedParentMeals, setSelectedParentMeals] = useState([]);

  const [imageDisplay, setImageDisplay] = useState(null);
  const [image, setImage] = useState(null);

  const [showSide, setShowSide] = useState(true);
  const [showTagDrop, setShowTagDrop] = useState(false);
  const [showDietaryDrop, setShowDietaryDrop] = useState(false);
  const [showAllergenDrop, setShowAllergenDrop] = useState(false);
  const [showDescriptionDrop, setShowDescriptionDrop] = useState(false);
  const [showBusinessDrop, setShowBusinessDrop] = useState(false);

  const [showCreateModal, setShowCreateModal] = useState(false);
  const [adding, setAdding] = useState(false);
  const [brandmealCategories, setBrandMealCategories] = useState([]);

  const [pageWidth, setPageWidth] = useState("");

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [dietaryTags, setDietaryTags] = useState([]);
  const [dietarySuggestions, setDietarySuggestions] = useState([]);

  const [allergenTags, setAllergenTags] = useState([]);
  const [allergenSuggestions, setAllergenSuggestions] = useState([]);

  const [descriptionTags, setDescriptionTags] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);

  const [businessTags, setBusinessTags] = useState([]);
  const [businessSuggestions, setBusinessSuggestions] = useState([]);
  const [allBrands, setAllBrands] = useState([]);
  const [form, setForm] = useState({
    name: "",
    meal_category_id: "",
    meal_parent_ids: "",
    amount: "",
    brand_id: "",
    new: false,
    home_page: false,
    minimum_age: false,
    preparation_time: "5",
    description: "",
    summary: "",
    images: [],
    meal_keywords: [],
    meal_descriptive_metadatas: [],
    meal_business_metadatas: [],
    meal_dietary_metadatas: [],
    meal_allergy_metadatas: [],
    available_no: "",
    alcohol: false,
    nutritional_description: "",
    commission_fee: "",
    commission_fee_percentage: "",
    partner_id: "",
    show_on_homepage: "",
  });
  const [categoryForm, setCategoryForm] = useState({
    name: "",
    summary: "",
    brand_id: "",

    meal_category_selection_type_id: "",
    super_meal_category_id: "",
    selection_no: "",
    meal_category_id: "",

  });
  const [brandsAddon, setBrandsAddon] = useState([]);
  const [brandsAddonMeals, setBrandsAddonMeals] = useState([]);
  const [selectedAddons, setSelectedAddons] = useState([]);
  const [newPhoto, setNewPhoto] = useState(null);
  const [newPhotoDisplay, setNewPhotoDisplay] = useState(null);
  const [mealCategoryOptions, setMealCategoryOptions] = useState([]);
  const [mealCategorySelections, setmealCategorySelections] = useState([]);
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);


  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (
      !BrandStore.dataAltCached &&
      !MealCategoryStore.dataCached &&
      !BrandStore.dataCached &&
      !CommonStore.dataCached
    ) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        MealCategoryStore.getMealCategories(alert, true),
        CommonStore.getCokitchens(alert),
        BrandStore.getBrands(alert),
        MealCategoryStore.getMealCategorySelection(alert),
      ]);
    } else {
      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrands(BrandStore.brandsAlt);
      }
      if (!BrandStore.dataCached) {
        await BrandStore.getBrands(alert);
      }

      if (!CommonStore.dataCached) {
        await CommonStore.getCokitchens(alert);
        setCokitchens(CommonStore.cokitchens);
        setCkLoading(false);
      }

      if (!MealCategoryStore.dataCached) {

        await Promise.all([MealCategoryStore.getMealCategorySelection(alert),
        MealCategoryStore.getMealCategories(alert, true)]);

      }
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen({
      brands:
        CommonStore?.cokitchens &&
        CommonStore?.cokitchens[0] &&
        CommonStore?.cokitchens[0].brands,
      name: "All users (all locations)",
    });

    setAllTags();
    setBrands(BrandStore.brandsAlt);
    setBrandsAddon(MealCategoryStore.mealCategories);
    setMealCategoryTypesOptions();
    mergeMealCategories();
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let i;
    let options = [];
    let curr = currentCokitchen.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        label: curr[i].name,
        value: curr[i].id,
        index: i,
      });
    }
    setCurrentBrands(options);
  }, [currentCokitchen]);

  // Set brand data based on current brand
  useEffect(() => {
    setAllTags();

    let mealOptions = [];
    let mealCopy = brand.meals || [];
    for (let l = 0; l < mealCopy.length; l++) {
      mealOptions.push({
        ...mealCopy[l],
        label: mealCopy[l].images[0]
          ? mealCopy[l].name + " 📸"
          : mealCopy[l].name,
        value: mealCopy[l].id,
      });
    }

    setMeals(mealOptions);
    setMeal("");
    setImageDisplay("");
    setTags([]);
    setDietaryTags([]);
    setAllergenTags([]);
    setDescriptionTags([]);
    setBusinessTags([]);
  }, [brand]);

  // Sort setMealCategoryTypesOptions options for react select
  const setMealCategoryTypesOptions = async () => {
    let opts = MealCategoryStore.mealCategoriesSelection;
    setmealCategorySelections(opts);
  };

  // Set meal data based on current meal
  useEffect(() => {
    setAllTags();
    let i, j, k, l, m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let keyCopy = meal.meal_keywords || [];
    let descCopy = meal.meal_descriptive_metadatas || [];
    let bussCopy = meal.meal_business_metadatas || [];
    let dietCopy = meal.meal_dietary_metadatas || [];
    let allergyCopy = meal.meal_allergy_metadatas || [];
    let descriptionCopy = meal.description || "";
    let imagesCopy = (meal && meal.images && meal.images[0]) || "";

    setImageDisplay(imagesCopy);
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        ...keyCopy[i],
        label: keyCopy[i].name,
        value: keyCopy[i].id,
      });
    }
    setTags(keyOptions);

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        ...dietCopy[j],
        label: dietCopy[j].name,
        value: dietCopy[j].id,
      });
    }
    setDietaryTags(dietOptions);

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        ...allergyCopy[k],
        label: allergyCopy[k].name,
        value: allergyCopy[k].id,
      });
    }

    setAllergenTags(allergyOptions);

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        ...descCopy[l],
        label: descCopy[l].name,
        value: descCopy[l].id,
      });
    }
    setDescriptionTags(descOptions);

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        ...bussCopy[m],
        label: bussCopy[m].name,
        value: bussCopy[m].id,
      });
    }
    setBusinessTags(bussOptions);
  }, [meal]);

  useEffect(async () => {
    let i;
    let options = [];
    let curr = CommonStore.cokitchens || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.brands];
    }
    setAllBrands(options);
  }, [cokitchens]);

  useEffect(async () => {
    globalReset();
    setForm({
      ...form,
      brand_id: "",
      meal_category_id: "",
    });
  }, [form?.cokitchen_id]);

  useEffect(async () => {
    globalReset();
    findCurrentBrandMealCategories();
    setForm({
      ...form,
      meal_category_id: "",
    });
  }, [form?.brand_id]);

  useEffect(async () => {
    globalReset();
  }, [form?.meal_category_id]);

  const globalReset = () => {
    setForm({
      ...form,
      name: "",
      meal_parent_ids: "",
      amount: "",
      new: false,
      home_page:false,
      preparation_time: "5",
      description: "",
      summary: "",
      images: [],
      meal_keywords: [],
      meal_descriptive_metadatas: [],
      meal_business_metadatas: [],
      meal_dietary_metadatas: [],
      meal_allergy_metadatas: [],
      minimum_age: false,
      available_no: "",
      nutritional_description: "",
      commission_fee: "",
      commission_fee_percentage: "",
      partner_id: "",
      show_on_homepage:""

    });

    setMeal("");
    setNewPhoto("");
    setNewPhotoDisplay("");
    setTags([]);
    setDietaryTags([]);
    setAllergenTags([]);
    setDescriptionTags([]);
    setBusinessTags([]);
    setSelectedAddons([]);
  };



  const findCurrentBrandMealCategories = () => {
    let active = BrandStore.brandsAlt?.find(
      (item) => item.id === form?.brand_id
    );
    let activeMeals =
      active?.meal_categories?.sort(
        (a, b) => Number(a?.position) - Number(b?.position)
      ) || [];
    setBrandMealCategories(activeMeals);
  };

  const mergeMealCategories = () => {
    let i;
    let options = [];
    let curr = MealCategoryStore.mealCategories || [];
    for (i = 0; i < curr.length; i++) {
      options = [...options, ...curr[i]?.meals];
    }
    setBrandsAddonMeals(options);
  };
  // Load tags from store
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) { }
  };
  // Sort tags
  const setAllTags = () => {
    let { tags } = TagsStore;
    const tagsCopy = JSON.parse(JSON.stringify(tags));

    setSuggestions(tagsCopy.meal_keyword?.filter(item => item?.item_type === 'SHOP'));
    setDietarySuggestions(tagsCopy.meal_dietary_metadata?.filter(item => item?.item_type === 'SHOP'));
    setAllergenSuggestions(tagsCopy.meal_allergy_metadata?.filter(item => item?.item_type === 'SHOP'));
    setDescriptionSuggestions(tagsCopy.meal_descriptive_metadata?.filter(item => item?.item_type === 'SHOP'));
    setBusinessSuggestions(tagsCopy.meal_business_metadata?.filter(item => item?.item_type === 'SHOP'));

  };

  // Format tags to be submitted
  const formatTags = () => {
    let i;
    let j;
    let k;
    let l;
    let m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let allOptions = {};
    let keyCopy = tags;
    let descCopy = descriptionTags;
    let bussCopy = businessTags;
    let dietCopy = dietaryTags;
    let allergyCopy = allergenTags;

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        name: allergyCopy[k].name,
        id: allergyCopy[k].id,
      });
    }
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        name: keyCopy[i].name,
        id: keyCopy[i].id,
      });
    }

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        name: dietCopy[j].name,
        id: dietCopy[j].id,
      });
    }

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        name: descCopy[l].name,
        id: descCopy[l].id,
      });
    }

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        name: bussCopy[m].name,
        id: bussCopy[m].id,
      });
    }

    allOptions = {
      key: keyOptions,
      desc: descOptions,
      buss: bussOptions,
      diet: dietOptions,
      allergy: allergyOptions,
    };

    return allOptions;
  };

  // Update meal
  const mealUpdate = async (e) => {
    e.preventDefault();
    const { createMeal } = BrandStore;
    const allTags = await formatTags();

      let body = {
        name: form.name,
        meal_category_id: form.meal_category_id?.id,
        amount: form.amount,
        brand_id: [form.brand_id?.id],
        new: form?.new ? true : false,
        home_page: form?.home_page,
        is_addon:false,
        preparation_time: form.preparation_time,
        description: form.description,
        summary: form.summary,
        nutritional_description: form.nutritional_description,
        available_no: form.available_no||"INFINITE",
        alcohol: form.alcohol? true : false,
        minimum_age:form.minimum_age,
        images: [],
        meal_keywords: allTags.key,
        meal_descriptive_metadatas: allTags.desc,
        meal_business_metadatas: allTags.buss,
        meal_dietary_metadatas: allTags.diet,
        meal_allergy_metadatas: allTags.allergy,
        item_type: "SHOP",
      };
      body= cleanPayload(body);
      if (!form?.cokitchen_id) {
        alert.show("Select cokitchen too add meal!", {
          type: "info",
        });
      } else if (!body?.brand_id) {
        alert.show("Brand is required", {
          type: "info",
        });
      } else if (!body?.meal_category_id) {
        alert.show("Item category is required", {
          type: "info",
        });
      } else {
        setSubmitting(true);
        let url;
        if (newPhoto) {
          url = await uploadImageToCloud(newPhoto);
          if (url) {
            setNewPhotoDisplay(url);
          }
        }

        body.images = [url || newPhotoDisplay];
        //  navigator.clipboard.writeText(JSON.stringify(textBD))

        await createMeal(alert, body, async () => {
          await CommonStore.getCokitchens(alert);
          history.push(ITEM_INFO);
        });

        setSubmitting(false);
        alert.show("Fetching updates...", {
          type: "info",
        });

        setCokitchens(CommonStore.cokitchens);
        setCurrentCokitchen({
          brands:
            CommonStore?.cokitchens &&
            CommonStore?.cokitchens[0] &&
            CommonStore?.cokitchens[0].brands,
          name: "All users (all locations)",
        });
        setCurrentBrands(
          CommonStore?.cokitchens &&
          CommonStore?.cokitchens[0] &&
          CommonStore?.cokitchens[0].brands
        );

        setAllTags();
      }
    
  };

  // Submit add form
  const categoryFormSubmit = async () => {
    const { getMealCategories, createMealCategory } = MealCategoryStore;
    let body = JSON.parse(JSON.stringify(categoryForm));

    if (!body?.brand_id) {
      alert.show("Brand is required!", {
        type: "info",
      });

    } else if (
      body?.meal_category_selection_type_id &&
      !body?.meal_category_id
    ) {
      alert.show("Item category type is required!", {
        type: "info",
      });
    }
    else {
      body = cleanPayload(body);


      try {
        setAdding(true);
        let res;
        body.brand_id = body?.brand_id?.id;
        res = await createMealCategory(alert, body);
        if (res) {
          setAdding(false);
          setShowCreateModal(false);
          await getMealCategories(alert, false);
          setBrands(MealCategoryStore.mealCategories);
          form?.brand_id?.meal_categories.push(res.data);
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
          errMsg ||
          "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setAdding(false);
        // resetForm();
      }
    }
  };
  const onDelete = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allSuggestions = [].concat(suggestionState, tag);
    const allTags = [...tagState];
    allTags.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const onAddition = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allTags = [].concat(tagState, tag);
    const allSuggestions = [...suggestionState];
    allSuggestions.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };



  const handleCheckboxChangeAlt = async (item) => {
    let newArr = [...selectedAddons, item];
    let match = selectedAddons?.find((el) => el?.id === item?.id);

    if (match) {
      newArr = newArr.filter((itm) => itm?.id !== item?.id);
    }

    setSelectedAddons(newArr);
  };
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab itemInfo="active_nav_item" />

            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <div className="flex_row_start">
                  <button
                    className="mr_30 cursor_pointer"
                    onClick={() => history.push(ITEM_INFO)}
                  >
                    <ArrowBack width="17px" height="17px" fill="#FF0000" />
                  </button>
                  <h6 className="bold_24px flex_row">
                    New Item
                    <Tippy content={`Add items to brands`}>
                      <div style={{ marginLeft: "10px" }}>
                        <AiFillQuestionCircle />
                      </div>
                    </Tippy>
                  </h6>
                </div>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          {loading ? (
            <PageLoading
              loading={loading}
              style={{ backgroundColor: "#2698fb2f", top: "200px" }}
              logoStyle={{ marginTop: "15%" }}
            />
          ) : (
            <section className="content_section mobile_column">
              {pageWidth > 628 || showSide ? (
                <aside className={`slide_in`} style={{ marginTop: "0px" }}>
                  <Select
                    placeholder="Select cokitchen"
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        cokitchen_id: e,
                      });
                    }}
                    containerClass=""
                    options={cokitchens}
                    name="name"
                    id="id"
                    label="Select cokitchen"
                  />
                  <Select
                    placeholder="Select brand"
                    value={form?.brand_id}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        brand_id: e,
                        //  posist_data:e?.posist_data,
                      });
                    }}
                    containerClass=""
                    options={brands?.filter(
                      (item) =>
                        item?.cokitchen_id === form?.cokitchen_id?.id &&
                        item?.item_type === "SHOP"
                    )}
                    name="name"
                    id="id"
                    label="Select brand"
                  />

                  <Select
                    placeholder="Select meal category"
                    value={form?.meal_category_id}
                    handleChange={(e) => {
                      setForm({
                        ...form,
                        meal_category_id: e,
                        //  posist_data:e?.posist_data,
                      });
                    }}
                    containerClass=""
                    options={form?.brand_id?.meal_categories}
                    name="name"
                    id="id"
                    label="Select meal category"
                  />
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Create Item Category"
                    onClick={() => {
                      setShowCreateModal(true);
                    }}
                    loading={loading}
                  />
                </aside>
              ) : null}
              <section className="cards_container px_50 seond_width_desktop_2 mb_30">
                <form
                  onSubmit={(e) => mealUpdate(e)}
                  className="flex_column_center"
                >
                  <Input
                    placeholder="Enter item name"
                    value={form.name}
                    onChange={(e) => {
                      setForm({ ...form, name: e });
                    }}
                    required
                    containerClass="mb_15 width_full"
                    label="Item name"
                  />

                  <Input
                    placeholder="Enter item amount"
                    value={form.amount}
                    onChange={(e) => {
                      setForm({ ...form, amount: e });
                    }}
                    required
                    containerClass="mb_15 width_full"
                    type="number"
                    label="Item amount"
                  />

                  <Input
                    placeholder="Enter item preparation time (minutes)"
                    value={form.preparation_time}
                    onChange={(e) => {
                      setForm({ ...form, preparation_time: e });
                    }}
                    required
                    label="item preparation time (minutes)"
                    containerClass="mb_15 width_full"
                  />

                  <Input
                    placeholder="Enter minimum age"
                    value={form.minimum_age}
                    onChange={(e) => {
                      setForm({ ...form, minimum_age: e });
                    }}
                    type="number"
                    containerClass="mb_15 width_full"
                    label="Minimum age"
                  />
                  <Input
                    placeholder="Quantity available"
                    value={form.available_no}
                    onChange={(e) => {
                      setForm({ ...form, available_no: e });
                    }}
                    type="number"
                    containerClass="mb_15 width_full"
                    label="Quantity available (leave blank if infinite)"
                  />
                  <div className="width_full">
                    <label className="mb_15 normal_17px">
                      Item description
                    </label>
                    <textarea
                      className="text_area text_area_short mb_35"
                      placeholder="item description "
                      value={form.description}
                      onChange={(e) => {
                        setForm({ ...form, description: e.target.value });
                      }}
                    />
                  </div>
                  <div className="width_full">
                    <label className="mb_15 normal_17px">Commission Fee </label>
                    <textarea
                      className="text_area text_area_short mb_35"
                      placeholder="Commission Fee "
                      value={form.commission_fee}
                      onChange={(e) => {
                        setForm({ ...form, commission_fee: e.target.value });
                      }}
                    />
                  </div>
                  <div className="width_full">
                    <label className="mb_15 normal_17px">
                      Commission Fee percentage
                    </label>
                    <textarea
                      className="text_area text_area_short mb_35"
                      placeholder="Commission Fee Percentage "
                      value={form.commission_fee_percentage}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          commission_fee_percentage: e.target.value,
                        });
                      }}
                    />
                  </div>
                  <div className="width_full">
                    <label className="mb_15 normal_17px">Partner Id </label>
                    <textarea
                      className="text_area text_area_short mb_35"
                      placeholder="Partner Id"
                      value={form.partner_id}
                      onChange={(e) => {
                        setForm({ ...form, partner_id: e.target.value });
                      }}
                    />
                  </div>

                  <div className="width_full">
                    <label className="mb_15 normal_17px">
                      Nutritional description
                    </label>

                    <textarea
                      className="text_area text_area_short mb_30"
                      placeholder="Nutritional Information"
                      value={form.nutritional_description}
                      onChange={(e) => {
                        setForm({
                          ...form,
                          nutritional_description: e.target.value,
                        });
                      }}
                    />
                  </div>

                  <div className="flex_row_left mb_25 width_full">
                    <ImageFile
                      onChange={(e) => {
                        setNewPhoto(e.target.files[0]);
                        e &&
                          setNewPhotoDisplay(
                            URL.createObjectURL(e.target.files[0])
                          );
                      }}
                      src={newPhotoDisplay}
                      text="Upload item Image"
                    />
                  </div>
                  <CheckBox
                    id="show_on_home_page"
                    label={`Show on Hompeage ?`}
                    checked={form?.show_on_homepage}
                    onClick={() => {
                      setForm({
                        ...form,
                        alcohol: !form?.alcohol,
                      });
                    }}
                  />
                  <div className="flex_row_left mb_25 width_full">
                    <CheckBox
                      id="alcohol"
                      label={`is alcohol?`}
                      checked={form?.alcohol}
                      onClick={() => {
                        setForm({
                          ...form,
                          alcohol: !form?.alcohol,
                        });
                      }}
                    />
                  </div>
                  <div className="flex_row_left mb_25 width_full">
                    <CheckBox
                      id="is_new"
                      label={`Is new?`}
                      checked={form?.new}
                      onClick={() => {
                        setForm({
                          ...form,
                          new: !form?.new,
                        });
                      }}
                    />
                  </div>
                  <div className="flex_row_left mb_25 width_full">
                    <CheckBox
                      id="is_hidden"
                      label={`Hidden?`}
                      checked={form?.home_page}
                      onClick={() => {
                        setForm({
                          ...form,
                          home_page: !form?.home_page,
                        });
                      }}
                    />
                  </div>

                  <Button
                    text={submitting ? "Updating..." : "Create meal"}
                    type="submit"
                    loading={submitting}
                  />
                </form>
              </section>
              <section className="m_xl_0 second_width_desktop align_left outside_tag_container">
                <h6 className="bold_18_px mb_35">Item Tags & Metadata</h6>
                <Tags
                  title="Keywords"
                  showTagDrop={showTagDrop}
                  setShowTagDrop={setShowTagDrop}
                  suggestions={suggestions}
                  onAddition={onAddition}
                  tags={tags}
                  onDelete={onDelete}
                  setTags={setTags}
                  setSuggestions={setSuggestions}
                />
                <Tags
                  title="Dietary information"
                  showTagDrop={showDietaryDrop}
                  setShowTagDrop={setShowDietaryDrop}
                  suggestions={dietarySuggestions}
                  onAddition={onAddition}
                  tags={dietaryTags}
                  onDelete={onDelete}
                  setTags={setDietaryTags}
                  setSuggestions={setDietarySuggestions}
                />
                <Tags
                  title="Allergen information"
                  showTagDrop={showAllergenDrop}
                  setShowTagDrop={setShowAllergenDrop}
                  suggestions={allergenSuggestions}
                  onAddition={onAddition}
                  tags={allergenTags}
                  onDelete={onDelete}
                  setTags={setAllergenTags}
                  setSuggestions={setAllergenSuggestions}
                />
                <Tags
                  title="Descriptive information"
                  showTagDrop={showDescriptionDrop}
                  setShowTagDrop={setShowDescriptionDrop}
                  suggestions={descriptionSuggestions}
                  onAddition={onAddition}
                  tags={descriptionTags}
                  onDelete={onDelete}
                  setTags={setDescriptionTags}
                  setSuggestions={setDescriptionSuggestions}
                />
                <Tags
                  title="Business information"
                  showTagDrop={showBusinessDrop}
                  setShowTagDrop={setShowBusinessDrop}
                  suggestions={businessSuggestions}
                  onAddition={onAddition}
                  tags={businessTags}
                  onDelete={onDelete}
                  setTags={setBusinessTags}
                  setSuggestions={setBusinessSuggestions}
                />
              </section>
            </section>
          )}
          <Modal
            title="Create Shop Item Category"
            showModal={showCreateModal}
            closeModal={() => {
              setShowCreateModal(!showCreateModal);
            }}
            customContent={
              <div>
                <Input
                  placeholder="Enter meal category name"
                  value={categoryForm.name}
                  onChange={(e) => {
                    setCategoryForm({ ...categoryForm, name: e });
                  }}
                  required
                  containerClass="mb_15"
                />

                <textarea
                  className="text_area text_area_short mb_15"
                  placeholder="Item category description "
                  value={categoryForm.summary}
                  onChange={(e) => {
                    setCategoryForm({
                      ...categoryForm,
                      summary: e.target.value,
                    });
                  }}
                />
                <Select
                  placeholder="Select brand"
                  value={categoryForm?.brand_id || form?.brand_id || ""}
                  handleChange={(e) => {
                    setCategoryForm({
                      ...categoryForm,
                      brand_id: e,
                      //  posist_data:e?.posist_data,
                    });
                    setSelectedParentMeals([]);
                  }}
                  containerClass=""
                  options={brands
                    ?.filter(
                      (item) => item?.cokitchen_id === form?.cokitchen_id?.id
                    )
                    ?.filter((item) => item.item_type === "SHOP")}
                  name="name"
                  id="id"
                  isLoading={loading}
                />

                {categoryForm?.meal_category_selection_type_id ===
                "35fbe06c-c6f8-4ca4-80d8-8a07020e5902" ? (
                  <Input
                    placeholder="Selection Number (Optional)"
                    value={categoryForm?.selection_no || ""}
                    onChange={(e) =>
                      setCategoryForm({
                        ...categoryForm,
                        selection_no: e,
                      })
                    }
                    containerClass="mb_15 "
                  />
                ) : null}
              </div>
            }
            customClass="feedback_modal"
            onSubmit={(e) => {
              e.preventDefault();
              categoryFormSubmit();
            }}
            saveText={adding ? "Creating..." : "Create Meal Category"}
            loading={adding}
          />
        </section>
      </div>
    </main>
  );
};
export default withAlert()(AddMeal);
