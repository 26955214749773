import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button, RoundButton } from "../../components/buttons";
import ImageCard from "../../components/imageCard";
import NavItem from "../../components/sideNavItem";
import { PlusIcon, LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import Select from "../../components/select";
import ImageFile from "../../components/imageFile";
import PageLoading from "../../components/loader";
import { fetchPhotos, openUploadWidget } from "../../helpers/cloudinaryService";
import { MEAL_ALLERGY_URL } from "../../helpers/urls";
import dataService from "../../services/data.service";
import MealsList from "../../components/mealsList";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import axios from "axios";
import CsvParserKeyword from "../../components/csvParserKeyword";
import { CSV_KEYWORDS } from "../../components/csvParserKeyword/utils"
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import KeywordList from "../../components/keywordList";
const MealKeywords = ({ alert }) => {
  const { csvKeywordData, setCsvKeywordData} = MealCategoryStore;
  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordIcon, setKeywordIcon] = useState(null);
  const [keywordIconDisplay, setKeywordIconDisplay] = useState(null);
  const [keywordImage, setKeywordImage] = useState(null);
  const [keywordImageDisplay, setKeywordImageDisplay] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setloading] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [showCsvNewModal, setShowCsvNewModal] = useState(false);
  const [csvMeals, setCsvMeals] = useState([]);
  const [csvAddons, setCsvAddons] = useState([]);
  const [addingNew, setAddingNew] = useState([]);
  const [checkedNewMeals, setCheckedNewMeals] = useState([]);
  const [allTagsOptions, setAllTagsOptions] = useState([]);
  const [titlesNew, setTitlesNew] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false)

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);
  useEffect(() => {
    fetchPhotos("image", setKeywordIconDisplay);
  }, []);

  useEffect(async () => {
    setloading(true);
    let { formatTags, dataCached } = TagsStore;
    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setMealTags();
    } else {
      setMealTags();
    }
    setloading(false);
  }, []);

  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };

  const setMealTags = () => {
    let { formatedTags } = TagsStore;
    let i;
    let j;
    let tagsCopy = [];
    let optionsCopy = [];
    for (i = 0; i < formatedTags.length; i++) {
      if (formatedTags[i].placeholder === "meal_keyword") {
        let curr =
          formatedTags[i].tags
            ?.filter((item) => item?.item_type === "SHOP")
            ?.map((item, i) => {
              return { ...item, label: item.name, value: item.id, index: i };
            }) || [];
        setAllTagsOptions(curr);
      }
      
    }
    
  };

  const onImageChange = async (e) => {
    setKeywordImage(e);
    setKeywordImageDisplay(URL.createObjectURL(e));
  };

  const onIconChange = async (e) => {
    setKeywordIcon(e);
    e && setKeywordIconDisplay(URL.createObjectURL(e));
  };

  const formSubmit = async () => {
    if (!keyword) {
      alert.show(`Please select a keyword!`, {
        type: "info",
      });
    }

    // else if (!keyword.icon && !keywordIcon) {
    //   alert.show(`Please upload an icon!`, {
    //     type: "info",
    //   });
    // }
    else {
      try {
        setSubmitting(true);
        let url;
        if (keywordImage) {
          const formData = new FormData();
          const CLOUDINARY_CLOUDNAME =
            process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
          const CLOUDINARY_UPLOAD_PRESET =
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
          formData.append("file", keywordImage);
          formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
          const urlRes = await axios.post(
            `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
            formData
          );
          if (urlRes && urlRes.data) {
            url = urlRes && urlRes.data && urlRes.data.url;
          }
        }
        let logoUrl;
        if (keywordIcon) {
          const formData = new FormData();
          const CLOUDINARY_CLOUDNAME =
            process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
          const CLOUDINARY_UPLOAD_PRESET =
            process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
          formData.append("file", keywordIcon);
          formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
          const urlRes = await axios.post(
            `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
            formData
          );
          if (urlRes && urlRes.data) {
            logoUrl = urlRes && urlRes.data && urlRes.data.url;
            setKeywordIconDisplay(logoUrl);
          }
        }

        const payload = {
          keyword_id: keyword.value,
          keyword_type: "meal_keyword",
          icon: logoUrl || keyword.icon,
          images:
            (url && [url]) || (keyword?.images && [keyword.images[0]]) || "",
        };
        !payload.images && delete payload.images;
        !payload.icon && delete payload.icon;

        if (!payload.images && !payload.icon) {
          alert.show(`There's nothing to update!`, {
            type: "info",
          });
        } else {
          const res = await dataService.putAuthData(MEAL_ALLERGY_URL, payload);

          if (res && res.data) {
            alert.show(`Keyword updated successfully!`, {
              type: "success",
            });
            const tags = await getTags();
            TagsStore.formatTags(tags);
            setMealTags();
          }
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setSubmitting(false);
        setShowAddForm(false);
      }
    }
  }
  const createNewItems = async () => {
    //const { createBulkMeal } = BrandStore;
    let body = JSON.parse(JSON.stringify(checkedNewMeals))
    body=body?.map(item=>{
      // let bodyAddons=item[CSV_TITLES_NEW.addonsString]?.map(ele=>{
      //   return(
      //     {
      //       meal_category_name:ele[CSV_TITLES_NEW.addonCategoryString],
      //       name:ele[CSV_TITLES_NEW.addonNameString],
      //       amount:ele[CSV_TITLES_NEW.addonPriceString]
      //     }
      //   )
      // })
     
      return({
        metadata_name:item[CSV_KEYWORDS.nameString],
        metadata_category:item[CSV_KEYWORDS.categoryString],
       // addons:bodyAddons,
        item_type: "SHOP",
      })
    })
    if(!csvMeals[0])
    {
      alert.show("Please upload menu items!", {
        type: "info",
      });
    }
    else if(!csvAddons[0])
    {
      alert.show("Please upload addon mapping!", {
        type: "info",
      });
    }

    
    else{
     // console.log("body",body,"checkedNewMeals",checkedNewMeals)
      setAddingNew(true)
      //navigator.clipboard.writeText(JSON.stringify({meals:body}))
     //await createBulkMeal(alert,{meals:body})
      setAddingNew(false)
      setShowPrompt(false)
    }
   
  }

  
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab meal_keyword="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
              <h6 className="bold_24px "> Item Keyword </h6>
              <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Upload Keywords Via CSV (New)"
                    onClick={() => {
                      setShowCsvNewModal(true);
                    }}
                    className="mr_30"
                  />
              </div>
            </div>
          </div>

          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
              <section className="flex_column_right">
                <Button text="Save changes" />
              </section>
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}></aside>
            ) : null}
            <section className="cards_container grid_fifth px_50">
              <PageLoading loading={loading} />
              {allTags.map((item, i) => {
                if (item.icon) {
                  return (
                    <ImageCard
                      key={i + "item"}
                      loading={false}
                      src={item.icon}
                      author={item.name}
                    />
                  );
                } else {
                  return;
                }
              })}
              {!loading && (
                <div className="flex_column_center">
                  <RoundButton
                    icon={
                      <PlusIcon width="10px" height="10px" fill="#2699FB" />
                    }
                    className="mb_15"
                    onClick={() => setShowAddForm(!showAddForm)}
                  />
                  <label className="text_center bold_18_px">
                    New <br /> Thumbnail
                  </label>
                </div>
              )}
            </section>
            <section className="desktop_visible"></section>
          </section>
        </section>
      </div>
      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        customContent={
          <>
            <Select
              placeholder="Select keyword"
              defaultValue={keyword}
              handleChange={(e) => {
                setKeyword(e);
                setKeywordIconDisplay("");
                setKeywordIcon("");
                setKeywordImage("");
                setKeywordImageDisplay("");
              }}
              options={allTagsOptions}
            />
            <div className="flex_row">
              <ImageFile
                onChange={(e) => {
                  onIconChange(e.target.files[0]);
                }}
                src={keyword?.icon || keywordIconDisplay}
                text="Upload Icon"
                className="mr_25"
              />
              <ImageFile
                onChange={(e) => {
                  onImageChange(e.target.files[0]);
                }}
                src={
                  (keyword?.images && keyword?.images[0]) || keywordImageDisplay
                }
                text="Upload Image"
              />
            </div>
          </>
        }
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={submitting ? "Saving..." : "Save Keyword"}
        title="Add New Item Keyword icon"
        loading={submitting}
      />
         <Modal
      title={`Upload Metadata`}
      showModal={showCsvNewModal}
      closeModal={() => setShowCsvNewModal(!showCsvNewModal)}
      customContent={
      <div>
   
      <CsvParserKeyword
      setCsvData={(e)=>setCsvKeywordData(e)}
      setTitles={(e)=>setTitlesNew(e)}
     // setCsvAddons={(e)=>setCsvAddons(e)}
      setCsvMeals={(e)=>setCsvMeals(e)}
      
      
      />
      {csvKeywordData?.length>0&&
     <h4 className="black mb_15">
     {
     csvKeywordData?.length} Meals Found 

     </h4>

      }
      <KeywordList 
      meals={csvKeywordData} 
      titles={titlesNew} 
      
      setCheckedMeals={(e)=>{setCheckedNewMeals(e); console.log(e)}}
      isNew
      />


      </div>
      }
      customClass="feedback_modal"
      onSubmit={(e) => {
      e.preventDefault();
      createNewItems()
      
      }}
      saveText={addingNew ? "Creating..." : `Create Meals`}
      loading={addingNew}
      />
    </main>
  );
};

export default withAlert()(MealKeywords);
