import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { PlusIcon, LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import PolygonItem from "../../components/polygonItem";
import AppMap from "../../components/map";
import Input from "../../components/inputs";
import CommonStore from "../../stores/dataStore/commonStore";
import LocationStore from "../../stores/dataStore/locationsStore";
import { withAlert } from "react-alert";
import Loader from "react-loader-spinner";
import { CheckBox } from "../../components/checkBoxes";
import "leaflet/dist/leaflet.css";
import "leaflet-draw/dist/leaflet.draw.css";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import db from "../../services/firebase.config";

const LocationSettings = ({ alert, visible }) => {
  const [polygons, setPolygons] = useState([]);
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentPolygon, setCurrentPolygon] = useState("");
  const [currentCkId, setCurrentCkId] = useState("");

  const [showSide, setShowSide] = useState(true);
  const [showAddForm, setShowAddForm] = useState(false);
  const [deliverySubmitting, setDeliverySubmitting] = useState(false);
  const [showUpdateForm, setShowUpdateForm] = useState(false);
  const [pageWidth, setPageWidth] = useState("");
  const [selectedPolygons, setSelectedPolygons] = useState([]);
  const [selectedPolygonsText, setSelectedPolygonsText] =
    useState("Select all");

  const [polygonUpdate, setPolygonUpdate] = useState(1);

  const [polygonName, setPolygonName] = useState("");
  const [cords, setCords] = useState("");
  const [updateCords, setUpdateCords] = useState("");
  const [deliveryFee, setDeliveryFee] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [updating, setUpdating] = useState(null);
  const [switchDelivery, setSwitchDelivery] = useState(false);
  const [loading, setLoading] = useState(false);
  const [rectPolygons, setRectPolygons] = useState([]);
  const [counter, setCounter] = useState(0);
  const [deliveryValues, setDeliveryValues] = useState({
    delivery: false,
    surge: "",
  });
  const onPolygonSelect = (i) => {
    let newArr = [...selectedPolygons, i];
    if (selectedPolygons.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedPolygons(newArr);
  };

  useEffect(() => {

    setCounter(1);
  }, [visible]);
  useEffect(() => {
    setPageWidth(window.innerWidth);

    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);

  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  useEffect(() => {
    if (selectedPolygons.length !== polygons.length) {
      setSelectedPolygonsText("Select all");
    } else if (selectedPolygons.length === polygons.length) {
      setSelectedPolygonsText("Deselect all");
    }
  }, [selectedPolygons, polygons.length]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);

    if (!CommonStore.dataCached) {
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCkLoading(false);
    }
    await getDeliveryValue();
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen(CommonStore.cokitchens[0]);
    sortRectPolygons();
    setCurrentPolygon(
      CommonStore &&
        CommonStore.cokitchens &&
        CommonStore.cokitchens[0] &&
        CommonStore.cokitchens[0].cokitchen_polygons &&
        CommonStore.cokitchens[0].cokitchen_polygons[0]
    );

    setCurrentCkId([0]);
  }, []);

  // Set polygons based on current cokitchen
  useEffect(() => {
    let curr = (currentCokitchen && currentCokitchen.cokitchen_polygons) || [];
    setPolygons(curr);
  }, [currentCokitchen, cokitchens, polygonUpdate]);

  // Set all polygons for the map
  const sortRectPolygons = () => {
    let pols =
      CommonStore.cokitchens[0] && CommonStore.cokitchens[0].cokitchen_polygons;
    let i;
    let polsCopy = [];
  };

  const getDeliveryValue = async () => {
    const response = await db
      .collection("delivery_values")
      .doc("delivery_values")
      .onSnapshot(
        (items) => {
          setDeliveryValues(items?.data());
    
        },
        (err) => {
          console.log(err);
        }
      );
  };
  // Update cokitchen data

  const updateData = async () => {
    await CommonStore.getCokitchens(alert);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen(CommonStore.cokitchens[currentCkId]);
    setCurrentPolygon(
      CommonStore.cokitchens &&
        CommonStore.cokitchens[currentCkId] &&
        CommonStore.cokitchens[currentCkId].cokitchen_polygons[currentCkId]
    );
  };

  // Create polygon
  const createPolygon = async () => {
    setSubmitting(true);
    setPolygonUpdate((prevState) => prevState + 1);
    let cordsCopy = [];
    let i;
    for (i = 0; i < cords.length; i++) {
      cordsCopy.push([cords[i].lat, cords[i].lng]);
    }
    await LocationStore.postPolygons(alert, {
      cokitchen_id: currentCokitchen.id,
      name: polygonName,
      delivery_fee: deliveryFee,
      polygon: cordsCopy,
    });

    setSubmitting(false);
    setPolygonUpdate((prevState) => prevState + 1);
    await updateData();
    resetForm();
  };

  // Update delivery factors
  const updateDeliveryFactors = async () => {
    if (parseFloat(deliveryValues?.surge) < 1) {
      alert.show("Surge cannot be less than x1", {
        type: "info",
      });
    } else {
      setDeliverySubmitting(true);
      let body = deliveryValues;
      body.surge = parseFloat(deliveryValues.surge);
      await LocationStore.postDeliveryValues(alert, body);
      setDeliverySubmitting(false);
      await getDeliveryValue();
    }
  };

  // Update polygon
  const updatePolygon = async () => {
    setUpdating(true);
    setPolygonUpdate((prevState) => prevState + 1);
    let cordsCopy = [];
    let i;
    if (updateCords) {
      for (i = 0; i < updateCords.length; i++) {
        cordsCopy.push([updateCords[i].lat, updateCords[i].lng]);
      }
    }
    await LocationStore.updatePolygons(alert, {
      cokitchen_polygon_id: currentPolygon.id,
      name: currentPolygon.name,
      delivery_fee: currentPolygon.delivery_fee,
      polygon: cordsCopy.length !== 0 ? cordsCopy : currentPolygon.polygon,
    });

    setUpdating(false);
    setPolygonUpdate((prevState) => prevState + 1);
    await updateData();
    resetForm();
  };

  // Delete polygon
  const deletePolygon = async () => {
    setSubmitting(true);

    await LocationStore.deletePolygons(currentPolygon.id, alert);
    setPolygonUpdate(!polygonUpdate);
    setSubmitting(false);
    resetForm();
    await updateData();
  };
  const resetForm = () => {
    setPolygonName("");
    setDeliveryFee("");
    setShowAddForm(false);
    setShowUpdateForm(false);
  };
  const togglePolygonsSelect = () => {
    if (selectedPolygons.length !== polygons.length) {
      let i;
      let ind = [];
      for (i = 0; i < polygons.length; i++) {
        ind.push(polygons[i]);
      }
      setSelectedPolygons(ind);
      setSelectedPolygonsText("Deselect all");
    } else {
      setSelectedPolygons([]);
      setSelectedPolygonsText("Select all");
    }
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab location_set="active_nav_item nav_item_first" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">
                  <span className="cursor_pointer underlined">
                    Cokitchen Polygons ({polygons.length})
                  </span>{" "}
                </h6>
                <div className="flex_row_center">
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="New Polygon"
                    onClick={() => setShowAddForm(!showAddForm)}
                  />
                </div>
              </div>
            </div>
          </div>

          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}

                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => {
                        setCurrentCokitchen(item);
                        setCurrentCkId(i);
                      }}
                      activeItem={
                        currentCokitchen && currentCokitchen.name === item.name
                      }
                    />
                  );
                })}
              </aside>
            ) : null}

            <section className="cards_container px_50 flex_column_left_top app_map_container">
              <h6 className="bold_24px mb_15">Map</h6>

              {currentCokitchen && currentCokitchen.lat ? (
                <AppMap
                  lat={currentCokitchen && currentCokitchen.lat}
                  lng={currentCokitchen && currentCokitchen.lng}
                  withRect
                  withMarker
                  positions={currentPolygon && currentPolygon.polygon}
                  withAllRects
                  rectPolygons={
                    currentCokitchen?.cokitchen_polygons
                  }
                />
              ) : null}
            </section>
            <aside className="desktop_visible flex_column_left fixed_aside_right">
              <div className="flex_column_left fixed_aside_inner">
                <form
                  className="flex_column_left mb_35"
                  onSubmit={(e) => {
                    e.preventDefault();
                    updateDeliveryFactors();
                  }}
                >
                  <Tippy content="Contains factors that affect delivery and delivery prices">
                    <h6 className="bold_24px mb_25 ">Delivery factors</h6>
                  </Tippy>
                  <div className="flex_row_left mb_25">
                    <h6 className="mr_10 black normal_18px">Surge x</h6>

                    <Input
                      placeholder="Surge"
                      value={deliveryValues?.surge}
                      onChange={(e) => {
                        setDeliveryValues({
                          ...deliveryValues,
                          surge: e,
                        });
                      }}
                      required
                      containerClass="width_52"
                      type="number"
                      min="1"
                      step="0.1"
                    />
                    <Tippy content="Surge multiplies the delivery price by its value. 1 is the minimum value">
                      <div style={{ marginTop: "-5px", marginLeft: "10px" }}>
                        <AiFillQuestionCircle />
                      </div>
                    </Tippy>
                  </div>
                  <div className="flex_row_center mb_25">
                    <CheckBox
                      id="set_end_date"
                      label={`Switch delivery ${
                        deliveryValues?.delivery ? "off" : "on"
                      }`}
                      checked={deliveryValues?.delivery}
                      onClick={() => {
                        setDeliveryValues({
                          ...deliveryValues,
                          delivery: !deliveryValues?.delivery,
                        });
                      }}
                    />

                    <Tippy content="Checking the box switches on delivery and switches off pickup. Unchecking the box does vice versa">
                      <div style={{ marginTop: "-5px", marginLeft: "10px" }}>
                        <AiFillQuestionCircle />
                      </div>
                    </Tippy>
                  </div>

                  <Button
                    text={`${
                      deliverySubmitting ? "Updating" : "Update"
                    } delivery factors`}
                    type="submit"
                    loading={deliverySubmitting}
                  />
                </form>

                
                <Tippy content="Contains polygons for each cokitchen. Each polygon encaps the areas between its borders.">
                  <h6 className="bold_24px mb_25 ">
                    Polygons
                    {/* <label
                    className="normal_18px cursor_pointer underlined ml_10"
                    onClick={togglePolygonsSelect}
                  >
                    {selectedPolygonsText}
                  </label> */}
                  </h6>
                </Tippy>
                {currentPolygon &&
                  currentPolygon.name &&
                  polygons.map((item, i) => {
                    return (
                      <PolygonItem
                        polygonText={item.name}
                        key={i + "name"}
                        onClick={() => {
                          setCurrentPolygon(item);
                        }}
                        activeItem={true}
                        optionClick={() => {
                          setCurrentPolygon(item);
                          setShowUpdateForm(true);
                        }}
                      />
                    );
                  })}
              </div>
            </aside>
          </section>
        </section>
      </div>

      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        customContent={
          <div>
            <AppMap
              lat={(currentCokitchen && currentCokitchen.lat) || 0}
              lng={(currentCokitchen && currentCokitchen.lng) || 0}
              setCords={setCords}
              setCord
              withMarker
            />
            <Input
              withLabel
              label="Polygon name"
              placeholder="Polygon name"
              value={polygonName}
              onChange={(e) => setPolygonName(e)}
              required
              containerClass="mt_25"
            />
            <Input
              withLabel
              label="Delivery fee"
              placeholder="Delivery fee"
              value={deliveryFee}
              onChange={(e) => setDeliveryFee(e)}
              required
              containerClass="mt_25"
            />
          </div>
        }
        customClass="map_modal"
        onSubmit={(e) => {
          e.preventDefault();
          createPolygon();
        }}
        saveText={submitting ? "Creating Polygon..." : "Create polygon"}
        title="Create a New Polygon"
      />

      <Modal
        showModal={showUpdateForm}
        closeModal={() => setShowUpdateForm(!showUpdateForm)}
        withDel
        deleteText={submitting ? "Deleting polygon..." : "Delete polygon"}
        delClick={deletePolygon}
        customContent={
          <div>
            <AppMap
              setCord
              setCords={setUpdateCords}
              lat={
                currentPolygon &&
                currentPolygon.polygon &&
                currentPolygon.polygon[0] &&
                currentPolygon.polygon[0][0]
              }
              lng={
                currentPolygon &&
                currentPolygon.polygon &&
                currentPolygon.polygon[0] &&
                currentPolygon.polygon[0][1]
              }
              withRect
              positions={currentPolygon && currentPolygon.polygon}
            />
            <Input
              withLabel
              label="Polygon name"
              placeholder="Polygon name"
              value={currentPolygon && currentPolygon.name}
              onChange={(e) =>
                setCurrentPolygon({ ...currentPolygon, name: e })
              }
              required
              containerClass="mt_25"
            />
            <Input
              withLabel
              label="Delivery fee"
              placeholder="Delivery fee"
              value={currentPolygon && currentPolygon.delivery_fee}
              onChange={(e) =>
                setCurrentPolygon({
                  ...currentPolygon,
                  delivery_fee: e,
                })
              }
              required
              containerClass="mt_25"
            />
          </div>
        }
        customClass="map_modal"
        onSubmit={(e) => {
          e.preventDefault();
          updatePolygon();
        }}
        saveText={updating ? "Updating..." : "Update polygon"}
        title={`Update ${currentPolygon && currentPolygon.name}`}
      />
    </main>
  );
};
export default withAlert()(LocationSettings);
