import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import { GET_COKITCHENS_URL, CREATE_NOTIFICATION, COKITCHENS_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class CommonStore {
  @observable cokitchens = [];

  @observable brands = [];
  @action updateCokitchen = (item) => {
    this.cokitchens = item;
  };
  @observable dataCached = false;

  getCokitchens = async (alert, shouldAlert) => {
    try {
      let response = await dataService.getInternalData(GET_COKITCHENS_URL);

      runInAction(() => {
        let res = response.data && response.data.cokitchens;
        let brandRes = response.data && response.data.brands;
        let i;
        let options = [];

        for (i = 0; i < res.length; i++) {
          options.push({ ...res[i], label: res[i].name, value: res[i].id });
        }
        this.cokitchens = options;
        this.brands = brandRes;
        shouldAlert &&
          alert.show("Cokitchens loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postPosts = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_NOTIFICATION, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          alert.show("Notification sent successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };


  postCokitchens = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(COKITCHENS_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          alert.show("Cokitchen created successfully!", {
            type: "success",
          });

         
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new CommonStore();
