import React from "react";
import "./styles.scss";

const Button = ({
  withIcon,
  icon,
  text,
  onClick,
  isBlue,
  isRed,
  className,
  withSubIcon,
  subIcon,
  loading,
  type,
}) => {
  return (
    <button
      className={`button cursor_pointer ${
        isBlue ? "blue_button" : isRed ? "red_button" : "green_button"
      } ${className}`}
      onClick={onClick}
      disabled={loading}
      type={type}
    >
      {withIcon && <span className="mr_10">{icon}</span>}
      <h6 className="bold_18_px cursor_pointer">{text}</h6>
      {withSubIcon && loading ? (
        <span className="ml_10">loading...</span>
      ) : withSubIcon && !loading ? (
        <div className="ml_10">{subIcon}</div>
      ) : null}
    </button>
  );
};

const RoundButton = ({ icon, onClick, className }) => {
  return (
    <button
      className={`round_button cursor_pointer ${className}`}
      onClick={onClick}
    >
      <span className="cursor_pointer">{icon}</span>
    </button>
  );
};
export { Button, RoundButton };
