import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
  CREATE_POSTS_URL,
  GET_POSTS_URL,
  UPDATE_POSTS_ARRANGEMENT_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
import commonStore from "./commonStore";
export class HomeStore {
  @observable posts = [];
  @observable dataCached = false;

  getPosts = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_POSTS_URL);

      runInAction(() => {
        let res = response?.data?.data;

        this.posts = res;

        alert.show("Posts loaded successfully!", {
          type: "success",
        });
        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postPosts = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_POSTS_URL, data);
      let res;
      runInAction(() => {
        res = response && response.data;

        if (res) {
          alert.show("Post created successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updatePostsArrangement = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(
        UPDATE_POSTS_ARRANGEMENT_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Posts rearranged successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  updatePost = async (alert, data, id) => {
    try {
      let response = await dataService.putAuthData(
        `${CREATE_POSTS_URL}/${id}`,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Post updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
      return response.data;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updatePostsArrangement = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(
        UPDATE_POSTS_ARRANGEMENT_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Posts rearranged successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deletePosts = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(
        `${CREATE_POSTS_URL}/${id}`
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Post deleted successfully!", {
            type: "success",
          });

          this.dataCached = true;
          commonStore.getCokitchens(alert, false);
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new HomeStore();
