import React, { useState, useEffect } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from 'react-papaparse';
import { observer } from "mobx-react-lite";
import { arraymove } from "../../helpers/moveArray";
import mealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import "./styles.scss";
import { CSV_TITLES_NEW, CSV_KEYWORDS } from "./utils";


const GREY = '#CCC';
const GREY_LIGHT = 'rgba(255, 255, 255, 0.4)';
const DEFAULT_REMOVE_HOVER_COLOR = '#A01919';
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = '#686868';

const styles = {
  zone: {
    alignItems: 'center',
    borderWidth: '2px',
    borderStyle: 'dashed',
    borderColor: `${GREY}`,
    borderRadius: 7.5,
    display: 'flex',
    flexDirection: 'column',
    height: '100%',
    justifyContent: 'center',
    padding: 20,
  },
  file: {
    background: 'linear-gradient(to bottom, #EEE, #DDD)',
    borderRadius: 7.5,
    display: 'flex',
    height: 120,
    width: 120,
    position: 'relative',
    zIndex: 10,
    flexDirection: 'column',
    justifyContent: 'center',
  },
  info: {
    alignItems: 'center',
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: '0.5em',
    justifyContent: 'center',
    display: 'flex',
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: '0.5em',
  },
  progressBar: {
    bottom: 14,
    position: 'absolute',
    width: '100%',
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: 'absolute',
    right: 6,
    top: 6,
    width: 23,
  },
};

const CsvParserKeyword = observer(({
  setCsvMeals,
  setTitles,

}) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [meals, setMeals] = useState([]);
  const [addons, setAddons] = useState([]);
  const [mealsWIthaddons, setMealsWIthAddons] = useState([]);
  const [assItems, setAssItems] = useState([]);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const numberString = CSV_KEYWORDS?.numberString;
  const nameString = CSV_KEYWORDS?.nameString;
  const categoryString = CSV_KEYWORDS?.categoryString;
  const mealString = CSV_KEYWORDS?.mealString;
  const associatedString1= CSV_KEYWORDS?.associatedString1;
  const associatedString2= CSV_KEYWORDS?.associatedString2;
  const associatedString3= CSV_KEYWORDS?.associatedString3;
  const associatedString4= CSV_KEYWORDS?.associatedString4;
  const associatedString5= CSV_KEYWORDS?.associatedString5;
  const associatedString6= CSV_KEYWORDS?.associatedString6;
  const associatedString7= CSV_KEYWORDS?.associatedString7;
  const associatedString8= CSV_KEYWORDS?.associatedString8;
  const associatedString9= CSV_KEYWORDS?.associatedString9;
  const associatedString10= CSV_KEYWORDS?.associatedString10;
  const associatedString11= CSV_KEYWORDS?.associatedString11;
  const associatedString12= CSV_KEYWORDS?.associatedString12;
  const associatedString13= CSV_KEYWORDS?.associatedString13;
  const associatedString14= CSV_KEYWORDS?.associatedString14;
  const associatedString15= CSV_KEYWORDS?.associatedString15;
  const associatedString16= CSV_KEYWORDS?.associatedString16;
  const associatedString17= CSV_KEYWORDS?.associatedString17;
  const associatedString18= CSV_KEYWORDS?.associatedString18;
  const associatedString19= CSV_KEYWORDS?.associatedString19;
  const associatedString20= CSV_KEYWORDS?.associatedString20;
  const associatedString21= CSV_KEYWORDS?.associatedString21;
  const associatedString22= CSV_KEYWORDS?.associatedString22;
  const associatedString23= CSV_KEYWORDS?.associatedString23;
  const associatedString24= CSV_KEYWORDS?.associatedString24;
  const associatedString25= CSV_KEYWORDS?.associatedString25;
  const { setCsvKeywordData } = mealCategoryStore;

  const collectCsvData = (items) => {
    return sortCsvData(items);
  }
  const sortCsvData = (items) => {
    let sortedData = [];
    let titles = [
      numberString,
      nameString,
      categoryString,
      mealString,
      associatedString1,
      associatedString2,
      associatedString3,
      associatedString4,
      associatedString5,
      associatedString6,
      associatedString7,
      associatedString8,
      associatedString9,
      associatedString10,
      associatedString11,
      associatedString12,
      associatedString13,
      associatedString14,
      associatedString15,
      associatedString16,
      associatedString17,
      associatedString18,
      associatedString19,
      associatedString20,
      associatedString21,
      associatedString22,
      associatedString23,
      associatedString24,
      associatedString25      
    ]
    items?.shift();


    sortedData = items?.reduce((acc, curr) => {
      let item = {};
      for (let index = 0; index < curr.length; index++) {
        const isNumber = titles[index]?.toUpperCase() === numberString?.toUpperCase();
        const isName = titles[index]?.toUpperCase() === nameString?.toUpperCase();
        const isCategory = titles[index]?.toUpperCase() === categoryString?.toUpperCase();
        const isMealString = titles[index]?.toUpperCase() === mealString?.toUpperCase();
        const isAssociated1= titles[index]?.toUpperCase() === associatedString1?.toUpperCase();
        const isAssociated2= titles[index]?.toUpperCase() === associatedString2?.toUpperCase();
        const isAssociated3= titles[index]?.toUpperCase() === associatedString3?.toUpperCase();
        const isAssociated4= titles[index]?.toUpperCase() === associatedString4?.toUpperCase();
        const isAssociated5= titles[index]?.toUpperCase() === associatedString5?.toUpperCase();
        const isAssociated6= titles[index]?.toUpperCase() === associatedString6?.toUpperCase();
        const isAssociated7= titles[index]?.toUpperCase() === associatedString7?.toUpperCase();
        const isAssociated8= titles[index]?.toUpperCase() === associatedString8?.toUpperCase();
        const isAssociated9= titles[index]?.toUpperCase() === associatedString9?.toUpperCase();
        const isAssociated10= titles[index]?.toUpperCase() === associatedString10?.toUpperCase();
        const isAssociated11= titles[index]?.toUpperCase() === associatedString11?.toUpperCase();
        const isAssociated12= titles[index]?.toUpperCase() === associatedString12?.toUpperCase();
        const isAssociated13= titles[index]?.toUpperCase() === associatedString13?.toUpperCase();
        const isAssociated14= titles[index]?.toUpperCase() === associatedString14?.toUpperCase();
        const isAssociated15= titles[index]?.toUpperCase() === associatedString15?.toUpperCase();
        const isAssociated16= titles[index]?.toUpperCase() === associatedString16?.toUpperCase();
        const isAssociated17= titles[index]?.toUpperCase() === associatedString17?.toUpperCase();
        const isAssociated18= titles[index]?.toUpperCase() === associatedString18?.toUpperCase();
        const isAssociated19= titles[index]?.toUpperCase() === associatedString19?.toUpperCase();
        const isAssociated20= titles[index]?.toUpperCase() === associatedString20?.toUpperCase();
        const isAssociated21= titles[index]?.toUpperCase() === associatedString21?.toUpperCase();
        const isAssociated22= titles[index]?.toUpperCase() === associatedString22?.toUpperCase();
        const isAssociated23= titles[index]?.toUpperCase() === associatedString23?.toUpperCase();
        const isAssociated24= titles[index]?.toUpperCase() === associatedString24?.toUpperCase();
        const isAssociated25= titles[index]?.toUpperCase() === associatedString25?.toUpperCase();
        const currmeals= []
        if(isAssociated1 || isAssociated2 ||isAssociated3 || isAssociated4){
          for (let i =3; i < index; i++) {
            currmeals.push(curr[i])
           //  setAssItems([currmeals])  
           delete currmeals.metadata_category 
             console.log(currmeals)
             
          }
         }
       
        else{
          if (
            isNumber || isName ||isCategory ) {
            item[titles[index]] = curr[index];
            item[titles[2]]= "Meal Keyword" ?"meal_keyword":""
            item[titles[2]]= "Meal Allergy Metadata" ?"meal_allergy":""
            item[titles[2]]= "Meal Business Metadata" ?"meal_business":""
            item[titles[2]]= "Brand Keyword" ?"brand_keyword":""
            item[titles[2]]= "Brand Business Metadata" ?"brand_business":""
            item[titles[2]]= "Meal Descriptive Metadata" ?"meal_descriptive":""
          }
        
        }
        item[mealString] =currmeals
      }
      
     // item[mealString] =assItems
      delete item[numberString]
      console.log(item)
      return [...acc, item]
    }, [])
    titles?.shift();
  
    const newTitles = [...titles, "placeholder"];
    arraymove(newTitles, );
    setTitles(newTitles);
    setMeals(sortedData);
    setCsvMeals(sortedData);
    setCsvKeywordData(sortedData)
   // sortItemImages(csvImages, sortedData);
    let mealsAddons = sortFullMealData(addons, sortedData);
    return mealsAddons;
  }
  const sortFullMealData = (sortedData = [], allMeals = []) => {
    let mealsAddons = allMeals?.map(ele => {
      let mealAddons = sortedData?.filter(
        itm => {
          let itmKeys = Object.keys(itm)?.map(elll => elll?.toLowerCase())?.filter(str => str.includes("associated"));

          return (
            Object.values(itm)?.map(ell => ell?.toLowerCase())?.includes(ele[nameString]?.toLowerCase()) && itmKeys && itmKeys[0]

          )
        }

      )
      return (
        { ...ele, addons: mealAddons }
      )
    }
    )
    setMealsWIthAddons(mealsAddons)
    return mealsAddons
  }
  return (
    <div>
      <CSVReader
        onUploadAccepted={(results) => {
          collectCsvData(results?.data);
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }) => (
          <>
            <div
              className="cursor_pointer mb_30"
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                'Drop CSV file here or click to upload'
              )}
            </div>
          </>
        )}
      </CSVReader>
    </div>
  );
});

export default CsvParserKeyword;
