import React, { useState } from "react";
import "./styles.scss";
import { BsFillOctagonFill, BsThreeDots } from "react-icons/bs";

const PolygonItem = ({ activeItem, polygonText, onClick, optionClick }) => {
  const [showOption, setShowOption] = useState(false);
  return (
    <div
      className="polygon_item flex_row_align_center cursor_pointer mb_35"
      onClick={onClick}
      onMouseEnter={() => {
        setShowOption(true);
      }}
      onMouseLeave={() => {
        setShowOption(false);
      }}
    >
      <span className="mr_25">
        <BsFillOctagonFill
          fill={activeItem ? "#2699fb" : "#ACACAC"}
          width="16px"
          height="16px"
        />
      </span>
      <label className={`${activeItem ? "bold_18_px" : "gray_label_sec"}`}>
        {polygonText}
      </label>

      <BsThreeDots
        onClick={optionClick}
        width="26px"
        height="26px"
        fill={activeItem ? "#2699fb" : "#ACACAC"}
        style={{
          margin: "-4.5px 0 0 7px",
          transform: "scale(1.5)",
          opacity: showOption ? 1 : 0,
          transition: "all 0.3s ease",
        }}
      />
    </div>
  );
};

export default PolygonItem;
