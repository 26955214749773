// Dashboard Routes
const HOME = "/home";
const EXPLORE = "/explore";
const DEALS_DISCOUNTS = "/deals-and-discounts";
const ADD_DEALS = "/add-deals/:id";
const VOUCHERS = "/vouchers";
const PUSH_NOTIFICATIONS = "/push-notifications";
const BRAND_INFO = "/brand-info";
const RATINGS = "/ratings-and-reviews";
const ITEM_INFO = "/meal-info";
const MEAL_ARRANGEMENTS = "/meal-arrangements";
const MEALS_ARRANGEMENTS = "/meals-arrangements/:brand_id/:meal_id";
const ADD_MEAL = "/add-meal";
const TAG_CREATOR = "/tag-creator";
const CALENDER = "/calender";
const LOCATION_SETTINGS = "/location-settings";
const APP_FEEDBACKS = "/app-feedbacks";
const MEAL_ALLERGY = "/meal-allergy-metadata";
const MEAL_KEYWORD = "/meal-keywords";
const MEAL_CATEGORIES = "/item-categories/:type";
const FAQ = "/faqs";
const APP_VERSIONS = "/app-versions";
const COKITCHENS = "/cokitchens";

//Auth Routes
const LOGIN = "/login";
const SIGNUP = "/signup";

export {
  //   Auth
  LOGIN,
  SIGNUP,
  //   Dashboard
  HOME,
  EXPLORE,
  DEALS_DISCOUNTS,
  ADD_DEALS,
  VOUCHERS,
  PUSH_NOTIFICATIONS,
  BRAND_INFO,
  RATINGS,
  ITEM_INFO,
  MEAL_ARRANGEMENTS,
  MEALS_ARRANGEMENTS,
  ADD_MEAL,
  TAG_CREATOR,
  CALENDER,
  LOCATION_SETTINGS,
  APP_FEEDBACKS,
  MEAL_ALLERGY,
  MEAL_KEYWORD,
  FAQ,
  APP_VERSIONS,
  COKITCHENS,
  MEAL_CATEGORIES,
};
