import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button, RoundButton } from "../../components/buttons";
import ImageCard from "../../components/imageCard";
import NavItem from "../../components/sideNavItem";
import { ArrowBack, LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import TagsStore from "../../stores/dataStore/tagsStore";
import Prompt from "../../components/modal/prompt";
import { withAlert } from "react-alert";
import Loader from "react-loader-spinner";
import CommonStore from "../../stores/dataStore/commonStore";
import ExploreStore from "../../stores/dataStore/exploreStore";
import { useHistory } from 'react-router-dom'
import BrandStore from "../../stores/dataStore/brandInfoStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import { MEAL_ARRANGEMENTS } from "../../router/router";
import { ReactSortable } from "react-sortablejs";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import TextCard from "../../components/textCard";
import moment from "moment";


const MealsArrangement = ({ alert, ...props }) => {
  const path = props.match.params.brand_id
  const meal_id = props.match.params.meal_id
  const history = useHistory()
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentCkId, setCurrentCkId] = useState("");
  const [currentKeyword, setCurrentKeyword] = useState("");

  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showModalAlt, setShowModalAlt] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [submittingAlt, setSubmittingAlt] = useState(null);
  const [allTagsOptions, setAllTagsOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [currentData, setCurrentData] = useState(null);
  const [currentMeal, setCurrentMeal] = useState(null);
  const [currentAddons, setCurrentAddons] = useState(null);
  const [meals, setMeals] = useState([]);
  const [currentBrandData, setCurrentBrandData] = useState(null);
  const [brands, setBrands] = useState([]);
  const [brandsAddon, setBrandsAddon] = useState([]);
  const [currentMealsCategories, setCurrentMealsCategories] = useState([]);
  const [currentMealsAddons, setCurrentMealsAddons] = useState([]);
  const [submittingAddon, setSubmittingAddon] = useState(false);
  const [showModalAddon, setShowModalAddon] = useState(false);


  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (!BrandStore.dataAltCached && !MealCategoryStore.dataCached) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        MealCategoryStore.getMealCategories(alert, true)])

    } else {
      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrands(BrandStore.brandsAlt);
      }
      if (!MealCategoryStore.dataCached) {
        await MealCategoryStore.getMealCategories(alert, true);
      }
    }


    setCkLoading(false);
    setLoading(false);
    setBrandsAddon(MealCategoryStore.mealCategories)
    findCurrentData()
  }, []);

  useEffect(() => {
    findCurrentCategory()
  }, [currentMeal])

// Finder
  useEffect(() => {
   let activeAddons = currentAddons?.addons?.sort((a, b) => Number(a?.position) - Number(b?.position))
    setCurrentMealsAddons(activeAddons);
  }, [currentAddons])
  // FInd currently selected deal
  const findCurrentData = () => {
    let active = BrandStore?.brandsAlt?.find(item => item.id === path)
    let activeMeals = active?.meal_categories?.find(item => item.id === meal_id)
    setCurrentBrandData(active)
    setCurrentData(activeMeals)
    let currMeals = activeMeals?.meals?.sort((a, b) => Number(a?.position) - Number(b?.position)) || []
    setMeals(currMeals)
    return activeMeals
  }

  // FInd currently selected deal's meal categories
  const findCurrentCategory = () => {
    let active = MealCategoryStore.mealCategories?.find(item => item.id === path)
    let activeMeals = active?.meals?.find(item => item.id === currentMeal?.id)
    let currMealsCategories = activeMeals?.meal_addon_categories?.sort((a, b) => Number(a?.position) - Number(b?.position)) || []
    setCurrentMealsCategories(currMealsCategories)
    return currMealsCategories
  }

  const formUpdate = async () => {
    const { arrangeMeal } = MealCategoryStore;
   
    setSubmitting(true);


    let body = {
      meals: meals?.map(item => item?.id),
      meal_category_id: currentData?.id,

    };

    await arrangeMeal(alert, body);
    setSubmitting(false);
    setShowPrompt(false);
    alert.show("Fetching updates...", {
      type: "info",
    });
  
    let dataRes =  await Promise.all([
      BrandStore.getBrandsAlt(alert),
      MealCategoryStore.getMealCategories(alert)])
    if (dataRes[1]) {
      setBrands(dataRes[0]);
      setBrandsAddon(dataRes[1])
    }
    setSubmitting(false);

  };



  const formUpdateAddon = async () => {
    const { arrangeMeal } = MealCategoryStore;

    setSubmittingAddon(true);


    let body = {
      meals: currentMealsAddons?.map(item => item?.id),
      meal_addon_category_id: currentAddons?.id,

    };

    await arrangeMeal(alert, body);
    setSubmittingAddon(false);
    setShowModalAddon(false);
    alert.show("Fetching updates...", {
      type: "info",
    });
    let dataRes =  await Promise.all([
      BrandStore.getBrandsAlt(alert),
      MealCategoryStore.getMealCategories(alert)])
    if (dataRes[1]) {
      setBrands(dataRes[0]);
      setBrandsAddon(dataRes[1])
    }
    setSubmittingAddon(false);

  };


  const formUpdateAlt = async () => {
    const { arrangeMealCategory } = MealCategoryStore;

    setSubmittingAlt(true);


    let body = {
      meal_categories: currentMealsCategories?.map(item => item?.id),
      brand_id: path,
      is_addon: true,
      meal_id: currentMeal?.id,

    };

    await arrangeMealCategory(alert, body);
    setSubmittingAlt(false);
    alert.show("Fetching updates...", {
      type: "info",
    });
    setSubmittingAlt(false);
    setShowModal(false);
    let dataRes =  await Promise.all([
      BrandStore.getBrandsAlt(alert),
      MealCategoryStore.getMealCategories(alert)])
    if (dataRes[1]) {
      setBrands(dataRes[0]);
      setBrandsAddon(dataRes[1])
    }


  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab explore="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_align_center title_bar">
                <button
                  className='mr_30 cursor_pointer'
                  onClick={() => history.push(MEAL_ARRANGEMENTS)}
                >
                  <ArrowBack width='17px' height='17px' fill='#FF0000' />
                </button>
                <h6 className="bold_24px flex_row">{currentBrandData?.name} {" > "} {currentData?.name}{" >"} meals


                  <Tippy
                    content={`Drag the cards around to rearrange 
                              and hit "save changes" 
                              below to save changes. 
                              `}
                  >
                    <div style={{ marginLeft: "10px" }}>
                      <AiFillQuestionCircle />
                    </div>
                  </Tippy>
                </h6>
              </div>
            </div>
          </div>

          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
              <section className="flex_column_right">
                <Button text="Save changes" />
              </section>
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {loading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}


              </aside>
            ) : null}
            <section className="width_full px_50">
              <ReactSortable
                list={meals}
                setList={setMeals}
                className="width_full cards_container grid_fifth"
                animation={300}
                delayOnTouchStart={true}
                delay={1.5}

              >
                {meals?.map((item, i) => {
                  return (
                    <ImageCard
                      key={i + "item"}
                      loading={false}
                      src={item?.images && item?.images[0]}
                      author={item?.name}
                      text="edit"
                      optionClick={() => {
                        setCurrentMeal(item);
                        setShowModal(true);

                      }}

                    />
                  );
                })}
              </ReactSortable>

            </section>
            <section className="desktop_visible">
              <Button text="Save changes" onClick={() => setShowPrompt(true)} />
            </section>
          </section>
        </section>
      </div>


      <Modal
        title={`Rearrange "${currentMeal?.name}" addon categories`}
        showModal={showModal}
        closeModal={() => setShowModal(false)}
        customContent={
          currentMealsCategories?.length > 0 ? <ReactSortable
            list={currentMealsCategories}
            setList={setCurrentMealsCategories}
            className="width_full"
            animation={300}
            delayOnTouchStart={true}
            delay={1.5}

          >
            {currentMealsCategories?.map((item, ind) => {
              return (
                <div 
                className="text_card mb_15 black flex_row_between cursor_grab"  
                key={ind+"item"} 
                onClick={()=>{
                  setCurrentAddons(item);
                  setShowModalAddon(true);

                }}
                >

                 <span>{item?.name}</span> 
                 <span className="cursor_pointer">Edit</span> 
                </div>
              )
            })}
          </ReactSortable> : <h5 className="gray bold">{`${currentMeal?.name} has no addon`}</h5>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formUpdateAlt();
        }}
        saveText={submittingAlt ? "Saving..." : "Save changes"}
        loading={submittingAlt || currentMealsCategories?.length < 1}

      />
{/* Current addon */}

        <Modal
        title={`Rearrange "${currentAddons?.name}" meals`}
        showModal={showModalAddon}
        closeModal={() => setShowModalAddon(false)}
        customContent={
          currentMealsAddons?.length > 0 ? 
          <ReactSortable
            list={currentMealsAddons}
            setList={setCurrentMealsAddons}
            animation={300}
            delayOnTouchStart={true}
            delay={1.5}
            className="width_full cards_container grid_third"
          >
            {currentMealsAddons?.map((item, i) => {
              return (
               

                    <div 
                    className="no-scrollbar text_card mb_15 black flex_row_center width_fit cursor_grab overflow-scroll"  
                    key={i+"item"} 
                    style={{height:"100px"}}
                   
                    >
    
                    {item?.meal_data?.name}
                    
                    </div>
              )
            })}
          </ReactSortable> : <h5 className="gray bold">{`${currentAddons?.name} has no meals`}</h5>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formUpdateAddon();
        }}
        saveText={submittingAddon ? "Saving..." : "Save changes"}
        loading={submittingAddon || currentMealsAddons?.length < 1}
      />

      {/* prompt */}
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(false)}
        onSubmit={(e) => {
          e.preventDefault();
          formUpdate()
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to save changes?"
        deleteText={submitting ? "Saving..." : "Save"}
      />
    </main>
  );
};
export default withAlert()(MealsArrangement);
