import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import TextCard from "../../components/textCard";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import { DateRangePicker } from "react-date-range";
import FaqStore from "../../stores/dataStore/faqStore";
import PageLoading from "../../components/loader";
import { withAlert } from "react-alert";
import Prompt from "../../components/modal/prompt";
import { Button } from "../../components/buttons";
import Input from "../../components/inputs";
import { ReactSortable } from "react-sortablejs";

const Faqs = ({ alert }) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showDateRange, setShowDateRange] = useState(false);
  const [faqs, setFaqs] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [selectedRatings, setSelectedRatings] = useState([]);
  const locations = [
    { name: "All users (all locations)" },
    { name: "Lekki Phase 1 Kitchen" },
    { name: "Oniru Kitchen" },
    { name: "Ikeja Kitchen" },
  ];

  const [currentLocation, setCurrentLocation] = useState(
    "All users (all locations)"
  );
  
  const [showSide, setShowSide] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  //const [ratingsHidden, setRatingsHidden] = useState(false);
  const [pageWidth, setPageWidth] = useState("");
  const [currentData, setCurrentData] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [faqForm, setFaqForm] = useState({
    question: "",
    answer: "",
  });
  const [submitting, setSubmitting] = useState(null);
  const [rearrangeDisabled, setrRarrangeDisabled] = useState(false);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  
  // load faqs from store
  useEffect(async () => {
    if (!FaqStore.dataCached) {
      setLoading(true);
      await FaqStore.getFaqs(alert);
      setLoading(false);
    }
    setFaqs(FaqStore.faqs);
  }, []);

  useEffect(() => {
    let i;
    let ind = [];
    for (i = 0; i < faqs.length; i++) {
      ind.push(i);
    }
    setSelectedBrands(ind);
  }, [faqs.length]);

  const formSubmit = async () => {
    const { postFaqs, getFaqs } = FaqStore;
    setSubmitting(true);
    await postFaqs(alert, faqForm);
    await getFaqs(alert);
    setFaqs(FaqStore.faqs);
    setSubmitting(false);
    setShowCreateModal(false);
  };

  const faqUpdate = async () => {
    const { updateFaqs, getFaqs } = FaqStore;
    setSubmitting(true);
    await updateFaqs(alert, {
      faq_id: currentData.id,
      answer: currentData.answer,
    });
    await getFaqs(alert);
    setFaqs(FaqStore.faqs);
    setSubmitting(false);
    setShowModal(false);
  };

  const faqArrangementUpdate = async () => {
    const { updateFaqsArrangement, getFaqs } = FaqStore;
    setLoading(true);
    let i;
    let data = [];
    for (i = 0; i < faqs.length; i++) {
      data.push({
        id: faqs[i].id,
        answer: faqs[i].answer,
        question: faqs[i].question,
      });
    }
    await updateFaqsArrangement(alert, {
      faq_arrangement: data,
    });
    await getFaqs(alert);
    setFaqs(FaqStore.faqs);
    setLoading(false);
  };

  const faqDelete = async () => {
    const { deleteFaqs, getFaqs } = FaqStore;
    setSubmitting(true);
    await deleteFaqs(currentData.id, alert);
    await getFaqs(alert);
    setFaqs(FaqStore.faqs);
    setSubmitting(false);
    setShowPrompt(false);
    setShowModal(false);
  };

  const onCardSelect = (i) => {
    let newArr = [...selectedBrands, i];
    if (selectedBrands.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedBrands(newArr);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab faq="active_nav_item " />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">FAQs</h6>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {locations.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentLocation(item.name)}
                      activeItem={currentLocation === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section
              className="flex_column_left width_full"
              style={{ width: "100%" }}
            >
              <div className="mb_25 flex_row_top width_full">
                <div className="flex_column_left">
                  <a
                    href="#/"
                    //   onClick={() => {
                    //     setPostDragDisabled(!postDragDisabled);
                    //   }}
                  >
                    <p
                      className="bold_18_px mb_15"
                      onClick={() => setrRarrangeDisabled(!rearrangeDisabled)}
                    >
                      Re-arrange faqs
                    </p>
                  </a>
                  <Button text="Save changes" onClick={faqArrangementUpdate} />
                </div>

                <Button
                  text="Create FAQ"
                  onClick={() => setShowCreateModal(true)}
                />
              </div>

              <div className="flex_row_top width_full mb_35 tab_column">
                <label className="bold_18_px">FAQs</label>
                {/* <div className="flex_row">
                  <label
                    className="normal_18px cursor_pointer mr_30"
                    // onClick={() => {
                    //   ratingsHidden ? setRatings(ratingsCopy) : setRatings([]);
                    //   setRatingsHidden(!ratingsHidden);
                    // }}
                  >
                    Hide selected
                  </label>
                  <label className="normal_18px cursor_pointer">
                    Filter: {"All ratings"}
                  </label>
                </div> */}

                {showDateRange && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                )}
              </div>
              {!loading && !faqs[0] && !faqs[1] ? (
                <label className="bold_24px gray">FAQs is empty</label>
              ) : null}

              <ReactSortable
                list={faqs}
                setList={setFaqs}
                className="width_full"
                animation={300}
                delayOnTouchStart={true}
                delay={1.5}
                disabled={rearrangeDisabled}
              >
                {faqs &&
                  faqs.map((item, i) => {
                    return (
                      <TextCard
                        key={item && item.id}
                        cardName={item && item.question}
                        cardLabel={item && item.answer}
                        checked={selectedBrands.includes(i)}
                        onChange={() => onCardSelect(i)}
                        onViewClick={() => {
                          setCurrentData(item);
                          setShowModal(true);
                        }}
                        viewText="Edit FAQ >"
                      />
                    );
                  }
           
                  )}
              </ReactSortable>
              <PageLoading loading={loading} />
            </section>
          </section>
        </section>
      </div>

      
      <Modal
        title="Edit FAQ"
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        customContent={
          <div>
            <Input
              placeholder="Question"
              value={currentData.question}
              onChange={(e) => setCurrentData({ ...currentData, question: e })}
              required
              containerClass="mb_15 "
              disabled
            />
            <Input
              placeholder="Answer"
              value={currentData.answer}
              onChange={(e) => setCurrentData({ ...currentData, answer: e })}
              required
              containerClass="mb_15 "
            />
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          faqUpdate();
        }}
        saveText={submitting ? "Updating..." : "Update FAQ"}
        loading={submitting}
        withDel
        deleteText="Delete FAQ"
        delClick={() => setShowPrompt(true)}
      />

      <Modal
        title="Create FAQ"
        showModal={showCreateModal}
        closeModal={() => setShowCreateModal(!showCreateModal)}
        customContent={
          <div>
            <Input
              placeholder="Question"
              value={faqForm.question}
              onChange={(e) => setFaqForm({ ...faqForm, question: e })}
              required
              containerClass="mb_15 "
            />
            <Input
              placeholder="Answer"
              value={faqForm.answer}
              onChange={(e) => setFaqForm({ ...faqForm, answer: e })}
              required
              containerClass="mb_15 "
            />
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={submitting ? "Creating..." : "Create FAQ"}
        loading={submitting}
      />

      
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          faqDelete();
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to delete this FAQ?"
        deleteText={submitting ? "Deleting..." : "Delete"}
      />
    </main>
  );
};
export default withAlert()(Faqs);
