import {
  HOME,
  EXPLORE,
  DEALS_DISCOUNTS,
  BRAND_INFO,
  ITEM_INFO,
  MEAL_KEYWORD,
  MEAL_ARRANGEMENTS,
  TAG_CREATOR
} from "../router/router";

const ROUTES = [
  {
    name: "Shop page header",
    id: "1",
    type: "",
    route: HOME,
  },
  {
    name: "Shop Explore page",
    id: "2",
    type: "",
    route: EXPLORE,
  },

  {
    name: "Shop Deals page & discounts",
    id: "3",
    type: "",
    route: DEALS_DISCOUNTS,
  },

  {
    name: "Shop brand info",
    id: "6",
    type: "",
    route: BRAND_INFO,
  },

  {
    name: "Shop items info",
    id: "8",
    type: "",
    route: ITEM_INFO,
  },
  {
    name: "Tag creator",
    id: "11",
    type: "",
    route: TAG_CREATOR
  },
  {
    name: "Shop items Categories",
    id: "14",
    type: "",
    route: "/item-categories/meals",
  },
  {
    name: "Shop items Arrangment",
    id: "15",
    type: "",
    route: MEAL_ARRANGEMENTS,
  },

  {
    name: "Shop item Keyword",
    id: "13",
    type: "",
    route: MEAL_KEYWORD,
  },

];

export default ROUTES