import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import Loader from "react-loader-spinner";
import { LocationIcon } from "../../assets/icons/icons";
import { TagCreate } from "../../components/appTag";
import PageLoading from "../../components/loader";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import { observer } from "mobx-react";
import { TAG_URLS } from "../../helpers/urls";
import dataService from "../../services/data.service";
const TagCreator = ({ alert }) => {
  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [allTags, setAllTags] = useState([]);
  //const [tags, setTags] = useState(["Healthy", "Vegetables"]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setPageWidth(window.innerWidth);

    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  useEffect(async () => {
    if (!TagsStore.dataCached) {
      const tags = await getTags();
      formatTags(tags);
    } else {
      let { tags } = TagsStore;
      formatTags(tags);
    }
  }, []);

  const getTags = async (second) => {
    try {
      !second && setLoading(true);
      return await TagsStore.getAllTags(alert, true);
    } catch (error) {
      setLoading(false);
    } finally {
      !second && setLoading(false);
    }
  };
  /******************Function to format tags api response **************/
  const formatTags = (tags = {}) => {
    const { setFormatedTags } = TagsStore;
    let i;
    let tagKeys = Object.keys(tags);
    let formatedTags = [];
    for (i = 2; i < tagKeys.length; i++) {
      if (typeof tags[tagKeys[i]] !== "string") {
        formatedTags.push({
          tags: tags[tagKeys[i]]
          ?.filter(item=> item?.item_type==="SHOP"),
          loading: false,
          placeholder: `${tagKeys[i]}`,
          create_url: TAG_URLS[i - 2].create,
          delete_url: TAG_URLS[i - 2].delete,
          value: "",
        });
      }
    }
    setAllTags(formatedTags);
    setFormatedTags(formatedTags);
  };

  /******************Function to add tags **************/
  const onTagSubmit = async (url, val, i) => {
    const allTagsCopy = [...allTags];
    allTagsCopy[i].loading = true;
    setAllTags(allTagsCopy);
    await dataService
      .postAuthData(url, { name: val, item_type:"SHOP" })
      .then(async (res) => {
        const tags = await getTags(true);
        formatTags(tags);
        allTagsCopy[i].tags.push({
          name: res.data.name,
          id: res.data.id,
        });
        alert.show(`${val} added successfully!`, {
          type: "success",
        });
      })
      .catch((error) => {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );

      })
      .finally(() => {
        allTagsCopy[i].loading = false;
        setAllTags(allTagsCopy);
      });
  };

  /******************Function to delete tags **************/
  const onTagDelete = async (url, val, i, name, ind) => {
    const allTagsCopy = [...allTags];
    allTagsCopy[i].loading = true;
    setAllTags(allTagsCopy);
    await dataService
      .postAuthData(url, { id: val })
      .then(async () => {
        const tags = await getTags(true);
        formatTags(tags);
        const allInnerTags = allTagsCopy[i].tags;
        allInnerTags.splice(ind, 1);
        setAllTags(allInnerTags);
        alert.show(`${name} deleted successfully!`, {
          type: "success",
        });
      })
      .catch((error) => {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      
      })
      .finally(() => {
        allTagsCopy[i].loading = false;
        setAllTags(allTagsCopy);
      });
  };
  const onTagChange = (e, i) => {
    const allTagsCopy = [...allTags];
    allTagsCopy[i].value = e;
    setAllTags(allTagsCopy);
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab tagCreator="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">Tag creator</h6>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            <section className="cards_container px_50 tags_container grid_second">
              <PageLoading loading={loading} />

              {allTags.map((item, i) => {
                return (
                  <div key={"item" + i + i}>
                    <TagCreate
                      ind={i}
                      onAddition={() =>
                        onTagSubmit(item.create_url, item.value, i)
                      }
                      placeholder={
                        item.placeholder + ": press “enter” to create tag"
                      }
                      value={item.value}
                      onChange={(e) => onTagChange(e, i)}
                      tags={item.tags}
                      delete_url={item.delete_url}
                      onDelete={onTagDelete}
                      label={item.placeholder}
                    />
                    {item.loading ? (
                      <Loader
                        type="ThreeDots"
                        color="#ff000060"
                        height={10}
                        width={50}
                      />
                    ) : null}
                  </div>
                );
              })}
            </section>
          </section>
        </section>
      </div>
    </main>
  );
};
export default observer(withAlert()(TagCreator));
