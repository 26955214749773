import React, { useState, useEffect } from "react";
import {
  useCSVReader,
  lightenDarkenColor,
  formatFileSize,
} from "react-papaparse";
import { observer } from "mobx-react-lite";
import { arraymove } from "../../helpers/moveArray";
import mealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import "./styles.scss";
import { CSV_TITLES_NEW } from "./utils";

const GREY = "#CCC";
const GREY_LIGHT = "rgba(255, 255, 255, 0.4)";
const DEFAULT_REMOVE_HOVER_COLOR = "#A01919";
const REMOVE_HOVER_COLOR_LIGHT = lightenDarkenColor(
  DEFAULT_REMOVE_HOVER_COLOR,
  40
);
const GREY_DIM = "#686868";

const styles = {
  zone: {
    alignItems: "center",
    borderWidth: "2px",
    borderStyle: "dashed",
    borderColor: `${GREY}`,
    borderRadius: 7.5,
    display: "flex",
    flexDirection: "column",
    height: "100%",
    justifyContent: "center",
    padding: 20,
  },
  file: {
    background: "linear-gradient(to bottom, #EEE, #DDD)",
    borderRadius: 7.5,
    display: "flex",
    height: 120,
    width: 120,
    position: "relative",
    zIndex: 10,
    flexDirection: "column",
    justifyContent: "center",
  },
  info: {
    alignItems: "center",
    display: "flex",
    flexDirection: "column",
    paddingLeft: 10,
    paddingRight: 10,
  },
  size: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    marginBottom: "0.5em",
    justifyContent: "center",
    display: "flex",
  },
  name: {
    backgroundColor: GREY_LIGHT,
    borderRadius: 3,
    fontSize: 12,
    marginBottom: "0.5em",
  },
  progressBar: {
    bottom: 14,
    position: "absolute",
    width: "100%",
    paddingLeft: 10,
    paddingRight: 10,
  },
  zoneHover: {
    borderColor: GREY_DIM,
  },
  default: {
    borderColor: GREY,
  },
  remove: {
    height: 23,
    position: "absolute",
    right: 6,
    top: 6,
    width: 23,
  },
};

const CsvParserNew = observer(({ setCsvMeals, setTitles }) => {
  const { CSVReader } = useCSVReader();
  const [zoneHover, setZoneHover] = useState(false);
  const [meals, setMeals] = useState([]);
  const [addons, setAddons] = useState([]);
  const [mealsWIthaddons, setMealsWIthAddons] = useState([]);
  const [removeHoverColor, setRemoveHoverColor] = useState(
    DEFAULT_REMOVE_HOVER_COLOR
  );
  const numberString = CSV_TITLES_NEW?.numberString;
  const nameString = CSV_TITLES_NEW?.nameString;
  const categoryString = CSV_TITLES_NEW?.categoryString;
  const rateString = CSV_TITLES_NEW?.rateString;
  const descString = CSV_TITLES_NEW?.descString;
  const alcoholString = CSV_TITLES_NEW?.alcoholString;
  const ageString = CSV_TITLES_NEW?.ageString;
  const quantityString = CSV_TITLES_NEW?.quantityString;
  const imageString = CSV_TITLES_NEW?.imageString;
  const prepString = CSV_TITLES_NEW?.prepString;
  const nutritionString = CSV_TITLES_NEW?.nutritionString;
  const showOnHomePageString = CSV_TITLES_NEW?.showOnHomePageString;
  const commissionFeeString = CSV_TITLES_NEW?.commissionFeeString;
  const commissionPercentageString = CSV_TITLES_NEW?.commissionPercentageString;
  const partnerIdString = CSV_TITLES_NEW?.partnerIdString;

  const { setCsvNewData, setCsvImagesFound } = mealCategoryStore;
  const { csvImages } = mealCategoryStore;
  // csvImages = csvImages.map(item=>item);
  useEffect(() => {
    // csvImages = csvImages.map(item=>item);
    sortItemImages(csvImages.map((item) => item));
  }, [csvImages]);

  const collectCsvData = (items) => {
    return sortCsvData(items);
  };
  const sortCsvData = (items) => {
    let sortedData = [];
    let titles = [
      numberString,
      nameString,
      categoryString,
      descString,
      nutritionString,
      rateString,
      alcoholString,
      ageString,
      prepString,
      quantityString,
      commissionFeeString,
      commissionPercentageString,
      partnerIdString,
      showOnHomePageString,
      imageString,
    ];
    items?.shift();

    sortedData = items?.reduce((acc, curr) => {
      let item = {};
      for (let index = 0; index < curr.length; index++) {
        const isNumber =
          titles[index]?.toUpperCase() === numberString?.toUpperCase();
        const isName =
          titles[index]?.toUpperCase() === nameString?.toUpperCase();
        const isCategory =
          titles[index]?.toUpperCase() === categoryString?.toUpperCase();
          const isDesc =
            titles[index]?.toUpperCase() === descString?.toUpperCase();
          const isNutrition =
            titles[index]?.toUpperCase() === nutritionString?.toUpperCase();
        const isRate =
          titles[index]?.toUpperCase() === rateString?.toUpperCase();
      
        const isAlcohol =
          titles[index]?.toUpperCase() === alcoholString?.toUpperCase();
      
        const isAge = titles[index]?.toUpperCase() === ageString?.toUpperCase();
         const isPrep =
           titles[index]?.toUpperCase() === prepString?.toUpperCase();
        const isQuantity =
          titles[index]?.toUpperCase() === quantityString?.toUpperCase();
         const isPartnerIdString =
           titles[index]?.toUpperCase() === partnerIdString?.toUpperCase();
        const isCommissionFee =
          titles[index]?.toUpperCase() === commissionFeeString?.toUpperCase();
        const isCommissionPercentageString =
          titles[index]?.toUpperCase() ===
          commissionPercentageString?.toUpperCase();       
        const isHomePageString =
          titles[index]?.toUpperCase() === showOnHomePageString?.toUpperCase();
                const isImage =
                  titles[index]?.toUpperCase() === imageString?.toUpperCase();
        if (
          isNumber ||
          isPrep ||
          isName ||
          isCategory ||
          isRate ||
          isImage ||
          isNutrition ||
          isQuantity ||
          isDesc ||
          isPartnerIdString ||
          isCommissionFee ||
          isCommissionPercentageString
        ) {
          item[titles[index]] = curr[index];
        } else if (isAlcohol) {
          item[titles[index]] =
            curr[index]?.toUpperCase() === "YES" ? true : false;
        } else if (isHomePageString) {
          item[titles[index]] =
            curr[index]?.toUpperCase() === "YES" ? true : false;
        } else if (isAge) {
          item[titles[index]] = curr[index] ? curr[index] : 0;
        } else if (isPrep) {
          item[titles[index]] = curr[index] ? curr[index] : 5;
        }

        item["placeholder"] = "";
      }
      delete item[numberString];
      return [...acc, item];
    }, []);
    titles?.shift();

    const newTitles = [...titles, "placeholder"];
    arraymove(newTitles, alcoholString, -2);
    setTitles(newTitles);

    setMeals(sortedData);
    setCsvMeals(sortedData);
    sortItemImages(csvImages, sortedData);
    let mealsAddons = sortFullMealData(addons, sortedData);
    return mealsAddons;
  };

  const sortItemImages = (items, allMeals = meals) => {
    const findMatchedImage = (meal) => {
      return items?.find((img) => img?.name === meal);
    };
    const imagesAddedtoMealsData = allMeals.map((item) => {
      const itmImg = item[imageString];
      const matchedItmIng = findMatchedImage(itmImg) || itmImg;
      return { ...item, [imageString]: matchedItmIng };
    });
    const itemsWithImage = imagesAddedtoMealsData.filter(
      (item) => item[imageString]?.name
    );

    setMeals(imagesAddedtoMealsData);
    setCsvMeals(imagesAddedtoMealsData);
    setCsvNewData(imagesAddedtoMealsData);
    setCsvImagesFound(itemsWithImage);
  };

  const sortFullMealData = (sortedData = [], allMeals = []) => {
    let mealsAddons = allMeals?.map((ele) => {
      let mealAddons = sortedData?.filter((itm) => {
        let itmKeys = Object.keys(itm)
          ?.map((elll) => elll?.toLowerCase())
          ?.filter((str) => str.includes("associated"));

        return (
          Object.values(itm)
            ?.map((ell) => ell?.toLowerCase())
            ?.includes(ele[nameString]?.toLowerCase()) &&
          itmKeys &&
          itmKeys[0]
        );
      });
      return { ...ele, addons: mealAddons };
    });
    setMealsWIthAddons(mealsAddons);
    return mealsAddons;
  };
  return (
    <div>
      <CSVReader
        onUploadAccepted={(results) => {
          collectCsvData(results?.data);
          setZoneHover(false);
        }}
        onDragOver={(event) => {
          event.preventDefault();
          setZoneHover(true);
        }}
        onDragLeave={(event) => {
          event.preventDefault();
          setZoneHover(false);
        }}
      >
        {({
          getRootProps,
          acceptedFile,
          ProgressBar,
          getRemoveFileProps,
          Remove,
        }) => (
          <>
            <div
              className="cursor_pointer mb_30"
              {...getRootProps()}
              style={Object.assign(
                {},
                styles.zone,
                zoneHover && styles.zoneHover
              )}
            >
              {acceptedFile ? (
                <>
                  <div style={styles.file}>
                    <div style={styles.info}>
                      <span style={styles.size}>
                        {formatFileSize(acceptedFile.size)}
                      </span>
                      <span style={styles.name}>{acceptedFile.name}</span>
                    </div>
                    <div style={styles.progressBar}>
                      <ProgressBar />
                    </div>
                    <div
                      {...getRemoveFileProps()}
                      style={styles.remove}
                      onMouseOver={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(REMOVE_HOVER_COLOR_LIGHT);
                      }}
                      onMouseOut={(event) => {
                        event.preventDefault();
                        setRemoveHoverColor(DEFAULT_REMOVE_HOVER_COLOR);
                      }}
                    >
                      <Remove color={removeHoverColor} />
                    </div>
                  </div>
                </>
              ) : (
                "Drop CSV file here or click to upload"
              )}
            </div>
          </>
        )}
      </CSVReader>
    </div>
  );
});

export default CsvParserNew;
