import React from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { CaretDown } from "../../assets/icons/icons";
import Input from "../inputs";
const Tags = ({
  title,
  showTagDrop,
  setShowTagDrop,
  suggestions,
  onAddition,
  tags,
  defaultTags,
  onDelete,
  setTags,
  setSuggestions,
}) => {
  const getSuggestions = () => {
    let defaults = suggestions;
    for (
      let i = 0, suggestionsLen = defaults?.length;
      i < suggestionsLen;
      i++
    ) {
      for (let j = 0, tagsLen = tags?.length; j < tagsLen; j++) {
        if (defaults && defaults[i] && defaults[i].id === tags[j].id) {
          defaults.splice(i, 1);
        }
      }
    }

    return defaults;
  };
  return (
    <OutsideClickHandler
      onOutsideClick={() => {
        setShowTagDrop(false);
      }}
    >
      <div
        className="app_tag_container flex_column_left width_full"
        onClick={() => setShowTagDrop(!showTagDrop)}
      >
        <div className="app_tag flex_row_left width_full mb_30">
          <span className="gray bold_21px normal mr_10">{title}</span>
          <CaretDown
            width="10px"
            height="10px"
            onClick={() => setShowTagDrop(!showTagDrop)}
          />

          {showTagDrop && (
            <div className="app_tag_drop flex_column_start">
              {getSuggestions() && getSuggestions().length > 0 ? (
                getSuggestions().map((item, i) => {
                  return (
                    <p
                      key={item.name + i}
                      onClick={() =>
                        onAddition(
                          item,
                          i,
                          tags,
                          getSuggestions(),
                          setTags,
                          setSuggestions
                        )
                      }
                    >
                      {item.name}
                    </p>
                  );
                })
              ) : (
                <h4>No Options</h4>
              )}
            </div>
          )}
        </div>

        <div className="app_tag_selected flex_row_left">
          {tags &&
            tags.map((item, i) => {
              return (
                <button
                  key={item.name + i}
                  type="button"
                  onClick={() =>
                    onDelete(
                      item,
                      i,
                      tags,
                      suggestions,
                      setTags,
                      setSuggestions
                    )
                  }
                  className="tag_cap flex_row_center"
                >
                  <h6 className="mr_10">{item.name}</h6>
                  <span className="cursor_pointer">x</span>
                </button>
              );
            })}

          {/* {defaultTags &&
            defaultTags.map((item, i) => {
              return (
                <button
                  key={item.name + i}
                  type="button"
                  onClick={() =>
                    onDelete(
                      item,
                      i,
                      tags,
                      suggestions,
                      setTags,
                      setSuggestions
                    )
                  }
                  className="tag_cap flex_row_center"
                >
                  <h6 className="mr_10">{item.name}</h6>
                  <span className="cursor_pointer">x</span>
                </button>
              );
            })} */}
        </div>
      </div>
    </OutsideClickHandler>
  );
};

const TagCreate = ({
  onAddition,
  placeholder,
  value,
  onChange,
  tags,
  delete_url,
  onDelete,
  label,
  ind,
}) => {
  return (
    <form
      className="app_tag_container flex_column_left width_full mb_45"
      onSubmit={(e) => {
        e.preventDefault();
        onAddition();
        onChange("");
      }}
    >
      <Input
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        required
        containerClass="mb_30 width_full"
        withLabel
        label={label}
      />
      <div className="app_tag_selected flex_row_left">
        {tags &&
          tags.map((item, i) => {
            return (
              <button
                type="button"
                key={item + i}
                onClick={async () => {
                  onDelete(delete_url, item.id, ind, item.name, i);
                }}
                className="tag_cap flex_row_center"
              >
                <h6 className="mr_10">{item.name}</h6>
                <span className="cursor_pointer">x</span>
              </button>
            );
          })}
      </div>
    </form>
  );
};
export { Tags, TagCreate };
