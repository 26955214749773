import React,{useState, useEffect} from "react";
import { User } from "../../assets/icons/icons";
import Logo from "../../assets/images/logo.png";
import "./styles.scss";
import Cookies from "js-cookie";
import { Link, withRouter } from "react-router-dom";
import { withAlert } from "react-alert";
import { ReactSearchAutocomplete } from "react-search-autocomplete";
import ROUTES from "../../helpers/routes";
const Header = ({ noUser, history, alert }) => {
  const user = Cookies.get("first_name") + " " + Cookies.get("last_name");
  const role = Cookies.get("role");
  const logout = () => {
    Cookies.remove("token");
    Cookies.remove("id");
    Cookies.remove("email");
    Cookies.remove("first_name");
    Cookies.remove("last_name");
    Cookies.remove("role");
    history.push("/login");
    alert.show("Logged out successfully!", {
      type: "success",
    });
  };
  const [suggestions,setSuggestions]=useState([]);
  const [searchValue,setSearchValue]=useState("");
  const [selected,setSelected]=useState("");

  const items = ROUTES;
  useEffect(()=>{
if (selected){
  history.push(selected?.route)
}


  },[selected])

  const handleOnSearch = (string, results) => {

  };

  const handleOnHover = (result) => {
   
  };

  const handleOnSelect = (item) => {
    setSelected(item)
   
  };

  const handleOnFocus = () => {
   
  };

  const handleOnClear = () => {
   
  };

  const formatResult = (item) => {
  
    return (
      <div className="result-wrapper">
       
        <span className="result-span">{item?.name}</span>
      </div>
    );
  };
  return (
    <header className="header flex_row_between">
      <figure className="flex_row">
        <img src={Logo} className="mr_30" alt="logo" />
        <figcaption className="red_normal_20px flex_row_center ">
          Shop Dashboard
        </figcaption>
      </figure>
      {!noUser && (
        <div className="flex_row_center ">
<div style={{width:"400px", marginRight:"15px"}}>
            <ReactSearchAutocomplete
            items={items}
            onSearch={handleOnSearch}
            onHover={handleOnHover}
            onSelect={handleOnSelect}
            onFocus={handleOnFocus}
            onClear={handleOnClear}
            styling={{ zIndex: 999}}
            formatResult={formatResult}
            placeholder="Search shop dashboard"
           // autoFocus
          /></div>
          <div className="flex_column mr_30">
            <label className="bold_18_px mb_5">{user}</label>
            <label className="normal_18px">
              {role == "MARKETING"
                ? "Shop staff"
                : role == "MARKETING_ADMIN"
                ? "Shop admin"
                : "Admin"}{" "}
            </label>
          </div>
        
            <User width="28px" height="28px" fill="#000" />
        
          <button onClick={logout} className="cursor_pointer red_normal_20px">
            Logout
          </button>
        </div>
      )}
    </header>
  );
};

export default withRouter(withAlert()(Header));
