
export const CSV_KEYWORDS ={
     numberString:"MetadataNumber",
     nameString:"metadata_name",
     categoryString:"metadata_category",
     mealString:"meals",
     associatedString1:"Associated Item 1",
     associatedString2:"Associated Item 2",
     associatedString3:"Associated Item 3",
     associatedString4:"Associated Item 4",
     associatedString5:"Associated Item 5",
     associatedString6:"Associated Item 6",
     associatedString7:"Associated Item 7",
     associatedString8:"Associated Item 8",
     associatedString9:"Associated Item 9",
     associatedString10:"Associated Item 10",
     associatedString11:"Associated Item 11",
     associatedString12:"Associated Item 12",
     associatedString13:"Associated Item 13",
     associatedString14:"Associated Item 14",
     associatedString15:"Associated Item 15",
     associatedString16:"Associated Item 16",
     associatedString17:"Associated Item 17",
     associatedString18:"Associated Item 18",
     associatedString19:"Associated Item 19",
     associatedString21:"Associated Item 20",
     associatedString22:"Associated Item 21",
     associatedString23:"Associated Item 22",
     associatedString24:"Associated Item 23",
     associatedString25:"Associated Item 24",
     
}