import React, { useState } from "react";
import "./styles.scss";
import { TiDelete } from "react-icons/ti";
const ImageCard = ({
  loading,
  src,
  author,
  onClick,
  className,
  optionClick,
  text,
}) => {
  const [showOption, setShowOption] = useState(false);
  return (
    (loading && (
      <div className="image_placeholder image_card">
        <div className="author" />
        <div className="caption" />
      </div>
    )) || (
      <figure
        className={`image_card image_card_gradient ${className}`}
        onClick={onClick}
        onMouseEnter={() => {
          setShowOption(true);
        }}
        onMouseLeave={() => {
          setShowOption(false);
        }}
      >
        <div
          style={{
            backgroundImage: `url(${src})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "top",
          }}
        />
        <figcaption className="bold_white_23">{author}</figcaption>
       {optionClick? 
    <div onClick={optionClick} 
    
    style={{
      margin: "0px 0 0 7px",
      transform:text?"scale(1)": "scale(1.5)",
      opacity: showOption ? 1 : 0,
      transition: "all 0.3s ease",
      position: "absolute",
      top: "7px",
      right: "7px",
      zIndex: "9",
      cursor: "pointer",
      color:"white",
      textAlign:"right"
    }}
    >  

{text?
      text:

      <TiDelete
         
          width="26px"
          height="26px"
          fill={"#fff"}
         
        /> 
        
        }
    </div>   
       
        
        
        
        :null}
      </figure>
    )
  );
};

export default ImageCard;
