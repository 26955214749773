import React, { useState, useEffect } from "react";
import "./styles.scss";
import { observer } from "mobx-react-lite";
import MealListAcc from "./mealListAcc";
import { CSV_TITLES_NEW } from "../csvParserNew/utils";
import mealCategoryStore from "../../stores/dataStore/mealCategoryStore";
const MealsList = observer(({ titles, setCheckedMeals, isNew }) => {

  const [selectedMeals, setSelectedMeals] = useState([]);

  const brandString = CSV_TITLES_NEW.brandString;
  const categoryString = CSV_TITLES_NEW.categoryString;
  const prepString = CSV_TITLES_NEW.prepString;
  const amountString = CSV_TITLES_NEW.amountString;
  const descString = CSV_TITLES_NEW.descString;
  const nameString = CSV_TITLES_NEW.nameString;
  const rateString = CSV_TITLES_NEW.rateString;
  const alcoholString = CSV_TITLES_NEW?.alcoholString;
  const homepageString = CSV_TITLES_NEW?.showOnHomePageString;
 
const {csvNewData}=mealCategoryStore;
  useEffect(() => {
    let allMeals = [...csvNewData]
    let validMeals = sortValidMeals(allMeals)

    setSelectedMeals(validMeals)
    setCheckedMeals(validMeals)
  }, []);
  useEffect(() => {
    let allMeals = [...csvNewData]
    let validMeals = sortValidMeals(allMeals)
    setSelectedMeals(validMeals)
    setCheckedMeals(validMeals)
  }, [csvNewData]);
  // },[csvNewData]);


  const sortValidMeals = (items) => {
    let validMeals = [];

    if (isNew) {
      validMeals = items?.filter(item =>
        item &&
        item[nameString] &&
        item[rateString] &&
        item[categoryString]

      )
    }
    else {
      validMeals = items?.filter(item =>
        item &&
        item[nameString] &&
        item[prepString] &&
        item[amountString] &&
        item[descString] &&
        item[brandString]?.id &&
        item[categoryString]?.id

      )
    }
    return validMeals

  }
  const handleMealCheckboxChange = (item) => {
    let newArr = [...selectedMeals, item];
    let match = selectedMeals?.find((el) => el?.meal_index === item?.meal_index)
    if (match) {
      newArr = newArr.filter((itm) => itm?.meal_index !== item?.meal_index);
    }
    setSelectedMeals(newArr);
    setCheckedMeals(newArr);
  };
  const handleMealInputChange = (item, el, e) => {
    let newArr = [...selectedMeals];
    let match = selectedMeals?.find((el) => el?.meal_index === item?.meal_index)
    if (
      el?.toUpperCase() === alcoholString?.toUpperCase()
    ) {
      match[el] = !e
    }
      else if (el?.toUpperCase() === homepageString?.toUpperCase()) {
        match[el] = !e;
      } else {
        match[el] = e;
      }
    setSelectedMeals(newArr);
  };
  let brandData;

  return (
    csvNewData?.length > 0 ?
      <div className="meals_list_container">

        {csvNewData?.map((item, i) => {
          item.meal_index = i;
          let isChecked = selectedMeals?.find(itm => itm?.meal_index === i) ? true : false;
          return (

            <MealListAcc
              i={i}
              item={item}
              isChecked={isChecked}
              handleMealCheckboxChange={(e) => handleMealCheckboxChange(e)}
              titles={titles}
              brandData={brandData}
              handleMealInputChange={(a, b, c) => { handleMealInputChange(a, b, c) }}
              isNew={isNew}
              meals={csvNewData}
            />
          )
        }
        )}
      </div>
      : null
  );
});
export default MealsList;
