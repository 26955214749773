import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
  FAQ_URL,
  FAQ_ARRANGEMENT_URL,
  GET_APP_VERSIONS,
  CREATE_APP_VERSIONS,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class AppVersionsStore {
  @observable versions = [];
  @observable dataCached = false;

  getVersions = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_APP_VERSIONS);

      runInAction(() => {
        let res = response.data && response.data;

        this.versions = res;
        alert.show("App Version loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postVersions = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_APP_VERSIONS, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("App version updated successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateVersions = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(FAQ_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Version updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateVersionsArrangement = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(FAQ_ARRANGEMENT_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Versions rearranged successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteVersions = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(`${FAQ_URL}/${id}`);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Version deleted successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new AppVersionsStore();
