import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import {
  COKITCHEN_POLYGONS_URL,
  COKITCHEN_DELIVERY_SURGE_URL,
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class LocationStore {
  @observable dataCached = false;

  postPolygons = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        COKITCHEN_POLYGONS_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Polygon created successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  postDeliveryValues = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        COKITCHEN_DELIVERY_SURGE_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Delivery factors updated successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updatePolygons = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(
        COKITCHEN_POLYGONS_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Polygon updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deletePolygons = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(
        `${COKITCHEN_POLYGONS_URL}/${id}`
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Polygon deleted successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new LocationStore();
