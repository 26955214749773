import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import { GET_VOUCHERS, CREATE_VOUCHERS } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class VoucherStore {
  @observable vouchers = [];

  @observable dataCached = false;

  getVouchers = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_VOUCHERS);

      runInAction(() => {
        let res = response && response.data && response.data.voucher_data;
        this.vouchers = res;
        alert.show("Vouchers loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  postVouchers = async (alert, data) => {
    let response;
    try {
      response = await dataService.postAuthData(CREATE_VOUCHERS, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Voucher created successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response;
        }
      });
      return response;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new VoucherStore();
