import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button, RoundButton } from "../../components/buttons";
import ImageCard from "../../components/imageCard";
import NavItem from "../../components/sideNavItem";
import { PlusIcon, LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import Select from "../../components/select";
import TagsStore from "../../stores/dataStore/tagsStore";
import Prompt from "../../components/modal/prompt";
import { withAlert } from "react-alert";
import Loader from "react-loader-spinner";
import CommonStore from "../../stores/dataStore/commonStore";
import ExploreStore from "../../stores/dataStore/exploreStore";
import CsvParserExplore from "../../components/csvParserExplore";
import KeywordList from "../../components/keywordList";
import { CSV_KEYWORDS } from "../../components/csvParserExplore/utils"
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import BrandInfoStore from "../../stores/dataStore/brandInfoStore";

const ExplorePage = ({ alert }) => {
  const { csvExploreData, setCsvExploreData} = MealCategoryStore;
  const [keywords, setKeywords] = useState([]);

  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [loading, setLoading] = useState(false);
  const [currentCkId, setCurrentCkId] = useState("");
  const [currentKeyword, setCurrentKeyword] = useState("");
  const [showCsvModal, setShowCsvModal] = useState(false);
  const [showSide, setShowSide] = useState(true);
  const [checkedNewMeals, setCheckedNewMeals] = useState(false);
  const [pageWidth, setPageWidth] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [submitting, setSubmitting] = useState(null);
  const [allTagsOptions, setAllTagsOptions] = useState([]);
  const [keyword, setKeyword] = useState("");
  const [csvMeals, setCsvMeals] = useState([]);
  const [csvAddons, setCsvAddons] = useState([]);
  const [addingNew, setAddingNew] = useState(false);
  const [titlesNew, setTitlesNew] = useState([]);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;
    if (!CommonStore.dataCached) {
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCkLoading(false);
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setMealTags();
    } else {
      setMealTags();
    }

    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen(CommonStore.cokitchens[0]);
    setCurrentCkId(0);
  }, []);
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };
  const createNewItems = async () => {
    const { createBulkKeyword } = BrandInfoStore;
    let id = currentCokitchen?.id
    let exploreData = [...csvExploreData]
      let body= {
        cokitchen_id: id,
        cokitchen_explore_keywords: exploreData
      }
     console.log(body)
      console.log(id, exploreData)
    if(!csvMeals[0])
    {
      alert.show("Please upload menu items!", {
        type: "info",
      });
    } 
    else{
     // console.log("body",body,"checkedNewMeals",checkedNewMeals)
      setAddingNew(true)
      //navigator.clipboard.writeText(JSON.stringify({meals:body}))
     await createBulkKeyword(alert,body)
      setAddingNew(false)
      setShowPrompt(false)
    }
   
  }

  const setMealTags = () => {
    let { formatedTags } = TagsStore;
    let optionsCopy = [];
    optionsCopy=formatedTags?.find(item=> item.placeholder === "meal_keyword" )?.tags
                ?.filter(item=>item?.item_type==="SHOP")
                ?.map(item=> {return({ value: item.id, label: item.name })})
   
    setAllTagsOptions(optionsCopy);
  };

  const formSubmit = async () => {
    if (!keyword) {
      alert.show(`Please select a keyword!`, {
        type: "info",
      });
    } else {
      setSubmitting(true);
      await ExploreStore.postKeywords(alert, {
        cokitchen_id: currentCokitchen.id,
        meal_keyword_id: keyword.value,
        item_type:'SHOP'
      });
      setSubmitting(false);
      setShowModal(false);
      setShowAddForm(false);
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCurrentCokitchen(CommonStore.cokitchens[currentCkId]);
    }
  };

  // Delete keyword

  const keywordDelete = async () => {
    const { deleteKeywords } = ExploreStore;
    setSubmitting(true);
    await deleteKeywords(currentKeyword.id, alert);
    setSubmitting(false);
    setShowPrompt(false);
    await CommonStore.getCokitchens(alert);
    setCokitchens(CommonStore.cokitchens);
    setCurrentCokitchen(CommonStore.cokitchens[currentCkId]);
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab explore="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">Categories & keywords</h6>
                <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Upload Keywords Via CSV (New)"
                    onClick={() => {
                      setShowCsvModal(true);
                    }}
                    className="mr_30"
                  />
              </div>

            </div>
          </div>

          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
              <section className="flex_column_right">
                <Button text="Save changes" />
              </section>
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}

                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => {
                        setCurrentCokitchen(item);
                        setCurrentCkId(i);
                      }}
                      activeItem={
                        currentCokitchen && currentCokitchen.name === item.name
                      }
                    />
                  );
                })}
              </aside>
            ) : null}
            <section className="cards_container grid_fifth px_50">
              {currentCokitchen &&
                currentCokitchen.cokitchen_explore_keywords &&
                currentCokitchen.cokitchen_explore_keywords?.filter(item=> item?.item_type==='SHOP').map((item, i) => {
                  return (
                    <ImageCard
                      key={i + "item"}
                      loading={false}
                      src={item.meal_keyword.images[0]}
                      author={item.meal_keyword.name}
                      optionClick={() => {
                        setCurrentKeyword(item);
                        setShowPrompt(true);
                      }}
                    />
                  );
                })}
              <div className="flex_column_center">
                <RoundButton
                  icon={<PlusIcon width="10px" height="10px" fill="#2699FB" />}
                  className="mb_15"
                  onClick={() => setShowAddForm(!showAddForm)}
                />
                <label className="text_center bold_18_px">
                  New <br /> Thumbnail
                </label>
              </div>
            </section>
            <section className="desktop_visible">
              <Button text="Save changes" />
            </section>
          </section>
        </section>
      </div>
      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        customContent={
          <>
            <Select
              placeholder="Select keyword"
              defaultValue={keyword}
              handleChange={(e) => setKeyword(e)}
              options={allTagsOptions}
            />
          </>
        }
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={submitting ? "Saving..." : "Create thumbnail"}
        title="Add New Thumbnail"
      />
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          keywordDelete();
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to delete this keyword?"
        deleteText={submitting ? "Deleting..." : "Delete"}
      />
        <Modal
      title={`Upload Metadata`}
      showModal={showCsvModal}
      closeModal={() => setShowCsvModal(!showCsvModal)}
      customContent={
      <div>
   
      <CsvParserExplore
      setCsvData={(e)=>setCsvExploreData(e)}
      setTitles={(e)=>setTitlesNew(e)}
     // setCsvAddons={(e)=>setCsvAddons(e)}
      setCsvMeals={(e)=>setCsvMeals(e)}
      
      
      />
      {csvExploreData?.length>0&&
     <h4 className="black mb_15">
     {
     csvExploreData?.length} Meals Found 

     </h4>

      }
      <KeywordList 
      meals={csvExploreData} 
      titles={titlesNew} 
      
      setCheckedMeals={(e)=>{setCheckedNewMeals(e); }}
      isNew
      />


      </div>
      }
      customClass="feedback_modal"
      onSubmit={(e) => {
      e.preventDefault();
      createNewItems()
      
      }}
      saveText={addingNew ? "Creating..." : `Create Meals`}
      loading={addingNew}
      />
    </main>
  );
};
export default withAlert()(ExplorePage);
