import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import {
  BRAND_INFO_URL,
  GET_BRANDS_URL,
  MEAL_INFO_URL,
  POST_BRAND_WORKING_HOUR_URL,
  GET_BRAND_WORKING_HOUR_URL,
  GET_MEAL_CATEGORIES_URL,
  GET_MEAL_WORKING_HOUR_URL,
  POST_MEAL_WORKING_HOUR_URL,
  DELETE_MEAL_URL,
  DELETE_BRAND_URL,
  CREATE_BULK_MEALS_URL,
  CREATE_BULK_KEYWORD_URL
} from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";
export class BrandStore {
  @observable brands = [];
  @observable brandsAlt = [];
  @observable workingHours = [];
  @observable workingMealHours = [];
  @observable dataCached = false;
  @observable dataAltCached = false;

  @action updateWorkingHrs = (item) => {
    this.workingHours = item;
    return item;
  };
  @action updateMealWorkingHrs = (item) => {
    this.workingMealHours = item;
    return item;
  };

  getBrands = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_BRANDS_URL);

      runInAction(() => {
        let res = response.data && response.data.data;
        this.brands = res;
        alert.show("Brands loaded successfully!", {
          type: "success",
        });

        this.dataCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  getBrandsAlt = async (alert) => {
    try {
      let response = await dataService.getInternalData(GET_MEAL_CATEGORIES_URL);

      runInAction(() => {
        let res = response.data && response.data.data;
        this.brandsAlt = res;
        alert.show("Brands loaded successfully!", {
          type: "success",
        });

        this.dataAltCached = true;
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createBrands = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(BRAND_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Brand created successfully!", {
            type: "success",
          });


        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  updateBrands = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(BRAND_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Brand updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateMeal = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(MEAL_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  createMeal = async (alert, data, reRoute) => {
    try {
      let response = await dataService.postAuthData(MEAL_INFO_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal created successfully!", {
            type: "success",
          });

          reRoute()
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
  createBulkMeal = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_BULK_MEALS_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meals created successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert, "is_bulk");
    }
  };
  createBulkKeyword = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(CREATE_BULK_KEYWORD_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meals created successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert, "is_bulk");
    }
  };


  // Working hours
  getWorkingHrs = async (alert, id) => {
    try {
      let response = await dataService.getAuthData(
        GET_BRAND_WORKING_HOUR_URL + id
      );
      let res;
      runInAction(() => {
        res = response.data && response.data.data;
        this.workingHours = res;

        this.dataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // Post
  postWorkingHrs = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        POST_BRAND_WORKING_HOUR_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          this.dataCached = true;
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // Working hours
  getMealWorkingHrs = async (alert, id) => {
    try {
      let response = await dataService.getAuthData(
        GET_MEAL_WORKING_HOUR_URL + id
      );
      let res;
      runInAction(() => {
        res = response.data && response.data.data;
        this.workingMealHours = res;

        this.dataCached = true;
      });
      return res;
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  // Post
  postMealWorkingHrs = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(
        POST_MEAL_WORKING_HOUR_URL,
        data
      );

      runInAction(() => {
        if (response && response.data) {
          this.dataCached = true;
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };


  deleteMeals = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(`${DELETE_MEAL_URL}/${id}`);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Meal deleted successfully!", {
            type: "success",
          });
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteBrands = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(`${DELETE_BRAND_URL}/${id}`);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Brand deleted successfully!", {
            type: "success",
          });
          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

}

export default new BrandStore();
