import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import TextCard from "../../components/textCard";
import NavItem from "../../components/sideNavItem";
import { LocationIcon } from "../../assets/icons/icons";
import { CheckBox } from "../../components/checkBoxes";
import Modal from "../../components/modal";
import { DateRangePicker } from "react-date-range";
import FeedbackStore from "../../stores/dataStore/appFeedbackStore";
import PageLoading from "../../components/loader";
import { withAlert } from "react-alert";
import moment from "moment";

const AppFeedbacks = ({ alert }) => {
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [showAvgRate, setShowAvgRate] = useState(false);
  const [showDateRange, setShowDateRange] = useState(false);
  const [feedbacks, setFeedbacks] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [selectedRatings, setSelectedRatings] = useState([]);
  const locations = [
    { name: "All users (all locations)" },
    { name: "Lekki Phase 1 Kitchen" },
    { name: "Oniru Kitchen" },
    { name: "Ikeja Kitchen" },
  ];

  const [currentLocation, setCurrentLocation] = useState(
    "All users (all locations)"
  );
  const [showSide, setShowSide] = useState(true);
  const [showModal, setShowModal] = useState(false);
  //const [ratingsHidden, setRatingsHidden] = useState(false);
  const [pageWidth, setPageWidth] = useState("");

  const [currentData, setCurrentData] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  useEffect(() => {
    window.scrollTo({
      top: 0,
    });
  }, []);

  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);
  // load feedbacks from store
  useEffect(async () => {
    if (!FeedbackStore.dataCached) {
      setLoading(true);
      await FeedbackStore.getFeedbacks(alert);
      setLoading(false);
    }
    setFeedbacks(FeedbackStore.feedbacks);
  }, []);

  useEffect(() => {
    let i;
    let ind = [];
    for (i = 0; i < feedbacks.length; i++) {
      ind.push(i);
    }
    setSelectedBrands(ind);
  }, [feedbacks.length]);

  const onCardSelect = (i) => {
    let newArr = [...selectedBrands, i];
    if (selectedBrands.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedBrands(newArr);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };

  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab feedback="active_nav_item " />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px">App Feedbacks</h6>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {locations.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentLocation(item.name)}
                      activeItem={currentLocation === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section
              className="flex_column_left width_full"
              style={{ width: "100%" }}
            >
              <div className="mb_25 flex_row_top width_full">
                <CheckBox
                  id="showAvgRate"
                  label="Show average rating on brand page after 5 ratings."
                  checked={showAvgRate}
                  onClick={() => setShowAvgRate(!showAvgRate)}
                />

                <button
                  className="cursor_pointer"
                  onClick={() => setShowDateRange(!showDateRange)}
                >
                  Filter by date
                </button>
              </div>

              <div className="flex_row_top width_full mb_35 tab_column">
                <label className="bold_18_px">App Feedbacks</label>
                <div className="flex_row">
                  <label
                    className="normal_18px cursor_pointer mr_30"
                    // onClick={() => {
                    //   ratingsHidden ? setRatings(ratingsCopy) : setRatings([]);
                    //   setRatingsHidden(!ratingsHidden);
                    // }}
                  >
                    Hide selected
                  </label>
                  <label className="normal_18px cursor_pointer">
                    Filter: {"All ratings"}
                  </label>
                </div>

                {showDateRange && (
                  <DateRangePicker
                    ranges={[selectionRange]}
                    onChange={handleSelect}
                    showMonthAndYearPickers={false}
                  />
                )}
              </div>
              {!loading && feedbacks.length === 0 ? (
                <label className="bold_24px gray">App feedbacks is empty</label>
              ) : null}
              {feedbacks
                ?.sort(
                  (a, b) => new Date(b.created_at) - new Date(a.created_at)
                )
                .map((item, i) => {
                  return (
                    <TextCard
                      key={i + "item"}
                      cardName={
                        item.user &&
                        item.user.first_name + " " + item.user.last_name
                      }
                      cardTime={moment(item.created_at).format("LLLL")}
                      cardLabel={item.feedback}
                      checked={selectedBrands.includes(i)}
                      onChange={() => onCardSelect(i)}
                      onViewClick={() => {
                        setCurrentData(item);
                        setShowModal(true);
                      }}
                    />
                  );
                })}
              <PageLoading loading={loading} />
            </section>
          </section>
        </section>
      </div>
      <Modal
        noSave
        title="Feedback Detail"
        showModal={showModal}
        closeModal={() => setShowModal(!showModal)}
        customContent={
          <div>
            <div className="mb_15">
              <label className="gray_label">Name:</label>
              <p className="capitalize black">
                {currentData?.user?.first_name +
                  " " +
                  currentData?.user?.last_name}
              </p>
            </div>
            <div className="mb_15">
              <label className="gray_label">Feedback:</label>
              <p className="black">{currentData?.feedback}</p>
            </div>
            <div className="mb_15">
              <label className="gray_label">Phone:</label>
              <p className="black">{currentData?.user?.phone_number}</p>
            </div>
            <div className="mb_15">
              <label className="gray_label">Email:</label>
              <p className="black">{currentData?.user?.email}</p>
            </div>

            <div className="mb_15">
              <label className="gray_label">Created at:</label>
              <p className="black">
                {moment(currentData?.created_at).format("LLLL")}
              </p>
            </div>

            <div className="mb_15">
              <label className="gray_label">Updated at:</label>
              <p className="black">
                {moment(currentData?.updated_at).format("LLLL")}
              </p>
            </div>
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
        }}
      />
    </main>
  );
};
export default withAlert()(AppFeedbacks);
