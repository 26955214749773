import { observable, runInAction } from "mobx";
import dataService from "../../services/data.service";
import { EXPLORE_KEYWORD_URL } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";

export class ExploreStore {
  @observable dataCached = false;

  postKeywords = async (alert, data) => {
    try {
      let response = await dataService.postAuthData(EXPLORE_KEYWORD_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Keyword created successfully!", {
            type: "success",
          });

          this.dataCached = true;

          return response.data;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  updateKeywords = async (alert, data) => {
    try {
      let response = await dataService.putAuthData(EXPLORE_KEYWORD_URL, data);

      runInAction(() => {
        if (response && response.data) {
          alert.show("Polygon updated successfully!", {
            type: "success",
          });

          this.dataCached = true;
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };

  deleteKeywords = async (id, alert) => {
    try {
      let response = await dataService.deleteAuthData(
        `${EXPLORE_KEYWORD_URL}/${id}`
      );

      runInAction(() => {
        if (response && response.data) {
          alert.show("Keyword deleted successfully!", {
            type: "success",
          });
        }
      });
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}

export default new ExploreStore();
