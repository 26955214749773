import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button, RoundButton } from "../../components/buttons";
import ImageCard from "../../components/imageCard";
import NavItem from "../../components/sideNavItem";
import { PlusIcon, LocationIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import Select from "../../components/select";
import ImageFile from "../../components/imageFile";
import PageLoading from "../../components/loader";
import { fetchPhotos, openUploadWidget } from "../../helpers/cloudinaryService";
import { MEAL_ALLERGY_URL } from "../../helpers/urls";
import dataService from "../../services/data.service";
import TagsStore from "../../stores/dataStore/tagsStore";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import { withAlert } from "react-alert";
import axios from "axios";

const MealAllergy = ({ alert }) => {
  const locations = [
    { name: "Lekki Phase 1 Kitchen" },
    { name: "Oniru Kitchen" },
    { name: "Ikeja Kitchen" },
  ];
  const [currentLocation, setCurrentLocation] = useState(
    "Lekki Phase 1 Kitchen"
  );
  const [showSide, setShowSide] = useState(true);
  const [pageWidth, setPageWidth] = useState("");
  const [showAddForm, setShowAddForm] = useState(false);
  const [keyword, setKeyword] = useState("");
  const [keywordIcon, setKeywordIcon] = useState(null);
  const [keywordIconDisplay, setKeywordIconDisplay] = useState(null);
  const [imgUrl, setImgUrl] = useState(null);
  const [loading, setloading] = useState(null);
  const [submitting, setSubmitting] = useState(null);
  const [allTags, setAllTags] = useState([]);
  const [allTagsOptions, setAllTagsOptions] = useState([]);
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);
  useEffect(() => {
    fetchPhotos("image", setKeywordIconDisplay);
  }, []);

  useEffect(async () => {
    setloading(true);
    let { formatTags, dataCached } = TagsStore;
    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setMealTags();
    } else {
      setMealTags();
    }
    setloading(false);
  }, []);

  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };

  const setMealTags = () => {
    let { formatedTags } = TagsStore;
    let i;
    let j;
    let tagsCopy = [];
    let optionsCopy = [];
    for (i = 0; i < formatedTags.length; i++) {
      if (formatedTags[i].placeholder === "meal_allergy_metadata") {
        setAllTags(formatedTags[i].tags);
        tagsCopy = formatedTags[i].tags;
      }
    }
    for (j = 0; j < tagsCopy.length; j++) {
      optionsCopy.push({
        ...tagsCopy[j],
        value: tagsCopy[j].id,
        label: tagsCopy[j].icon ? tagsCopy[j].name + " 📸" : tagsCopy[j].name,
      });
    }
    setAllTagsOptions(optionsCopy);
  };

  const onImageChange = async (e) => {
    setKeywordIcon(e);
    e && setKeywordIconDisplay(URL.createObjectURL(e));
  };

  const formSubmit = async () => {
    if (!keyword) {
      alert.show(`Please select a keyword!`, {
        type: "info",
      });
    } else if (!keywordIcon) {
      alert.show(`Please upload an icon!`, {
        type: "info",
      });
    } else {
      try {
        setSubmitting(true);
        const formData = new FormData();
        const CLOUDINARY_CLOUDNAME = process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
        const CLOUDINARY_UPLOAD_PRESET =
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
        formData.append("file", keywordIcon);
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        const urlRes = await axios.post(
          `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
          formData
        );
        if (urlRes && urlRes.data) {
          const url = urlRes && urlRes.data && urlRes.data.url;
          setKeywordIconDisplay(url);

          const res = await dataService.putAuthData(MEAL_ALLERGY_URL, {
            keyword_id: keyword.value,
            keyword_type: "meal_allergy_metadata",
            icon: url,
          });

          if (res && res.data) {
            alert.show(`Keyword updated successfully!`, {
              type: "success",
            });
            const tags = await getTags();
            TagsStore.formatTags(tags);
            setMealTags();
          }
        }
      } catch (error) {
        const errMsg =
          error &&
          (error.response || error.message || error.toString() || error);
        alert.show(
          (errMsg && errMsg.data && errMsg.data.message) ||
            errMsg ||
            "An error has occured!!",
          {
            type: "error",
          }
        );
      } finally {
        setSubmitting(false);
        setShowAddForm(false);
      }
    }
  };
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab meal_allergy="active_nav_item" />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px flex_row">
                  Meal allergy metadata{" "}
                  <Tippy
                    content={`Meal allergy metadata contains editable icons for meal allergy metadata. To edit or add metadata icon hit "new thumbnail" and select the metadata to be edited.
                    Meal allergy metadata with the photo emoji 📸 already have icon`}
                  >
                    <div style={{ marginLeft: "10px" }}>
                      <AiFillQuestionCircle />
                    </div>
                  </Tippy>
                </h6>
              </div>
            </div>
          </div>

          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
              <section className="flex_column_right">
                <Button text="Save changes" />
              </section>
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}></aside>
            ) : null}
            <section className="cards_container grid_fifth px_50">
              <PageLoading loading={loading} />
              {allTags.map((item, i) => {
                if (item.icon) {
                  return (
                    <ImageCard
                      key={i + "item"}
                      loading={false}
                      src={item.icon}
                      author={item.name}
                    />
                  );
                } else {
                  return;
                }
              })}
              {!loading && (
                <div className="flex_column_center">
                  <RoundButton
                    icon={
                      <PlusIcon width="10px" height="10px" fill="#2699FB" />
                    }
                    className="mb_15"
                    onClick={() => setShowAddForm(!showAddForm)}
                  />
                  <label className="text_center bold_18_px">
                    New <br /> Thumbnail
                  </label>
                </div>
              )}
            </section>
            <section className="desktop_visible"></section>
          </section>
        </section>
      </div>
      <Modal
        showModal={showAddForm}
        closeModal={() => setShowAddForm(!showAddForm)}
        customContent={
          <>
            <Select
              placeholder="Select keyword"
              defaultValue={keyword}
              handleChange={(e) => {
                setKeyword(e);
                setKeywordIcon("");
                setKeywordIconDisplay("");
              }}
              options={allTagsOptions}
            />

            <ImageFile
              onChange={(e) => {
                onImageChange(e.target.files[0]);
              }}
              src={keyword?.icon || keywordIconDisplay}
              text="Upload Icon"
            />
          </>
        }
        onSubmit={(e) => {
          e.preventDefault();
          formSubmit();
        }}
        saveText={submitting ? "Saving..." : "Save Metadata"}
        title="Add New Meal Allergy Metadata icon"
        loading={submitting}
      />
    </main>
  );
};

export default withAlert()(MealAllergy);
