import React from "react";
import Select from "react-select";

const AppSelect = ({
  options,
  value,
  placeholder,
  handleChange,
  isLoading,
  defaultValue,
  containerClass,
  isMulti,
  name = "label",
  id = "value",
  isCustom,
  customNameOne,
  customNameTwo,
  disabled,
  label
}) => {
  return (
    <div className="flex_column width_full">
      {label && <label className="mb_15 normal_17px capitalize">{label}</label>}
      <Select
        className={`react-select-container ${containerClass}`}
        classNamePrefix="react-select"
        hideSelectedOptions="true"
        placeholder={placeholder}
        closeMenuOnSelect={isMulti ? false : true}
        isSelected={true}
        options={options}
        backspaceRemovesValue={true}
        onChange={handleChange}
        value={value}
        isLoading={isLoading}
        defaultValue={defaultValue}
        isMulti={isMulti}
        // menuIsOpen
        isDisabled={disabled}
        getOptionLabel={(option) =>
          isCustom ? option[customNameOne][customNameTwo] : option[name]
        }
        getOptionValue={(option) => option[id]}
      />
    </div>
  );
};
export default AppSelect;
