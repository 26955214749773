import { observable, runInAction, action } from "mobx";
import dataService from "../../services/data.service";
import { GET_BRAND_REVIEWS } from "../../helpers/urls";
import errorHandler from "../../helpers/errorHandler";

export class ReviewsStore {
  @observable reviews = [];

  @observable dataCached = false;

  getReviews = async (alert, second, id) => {
    try {
      let response = await dataService.getAuthData(
        GET_BRAND_REVIEWS + "85c7d173-56ed-44c8-8335-ef0eecabf454"
      );

      runInAction(() => {
        this.reviews = response.data && response.data.brand_reviews;
        !second &&
          alert.show("Reviews loaded successfully!", {
            type: "success",
          });

        this.dataCached = true;
      });
      return response.data;
    } catch (error) {
      errorHandler(error, alert);
    }
  };
}
export default new ReviewsStore();
