import React from "react";
import { FileUploader } from "react-drag-drop-files";
import { observer } from "mobx-react-lite";
const fileTypes = ["JPG","PNG"];

const BulkFileUpload=observer(({multiple=true, handleChange, file}) => {

  return (
    <div className={`width_full file_upload_container mb_30 ${file&&file[0]?"active":"inactive"}`}>       
    <FileUploader handleChange={handleChange} name="file" types={fileTypes} multiple={multiple}/>
    </div>
  );
});

export default BulkFileUpload;
