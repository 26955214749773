import React, { useState, useEffect } from "react";
// import { observer } from "mobx-react-lite";
import { observer } from "mobx-react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import { Button } from "../../components/buttons";
import NavItem from "../../components/sideNavItem";
import { Clock, LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Select from "../../components/select";
import { Tags } from "../../components/appTag";
import Loader from "react-loader-spinner";
import ImageFile from "../../components/imageFile";
import BrandStore from "../../stores/dataStore/brandInfoStore";
import CommonStore from "../../stores/dataStore/commonStore";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import TagsStore from "../../stores/dataStore/tagsStore";
import { withAlert } from "react-alert";
import axios from "axios";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import Modal from "../../components/modal";
import Input from "../../components/inputs";
import { CheckBox } from "../../components/checkBoxes";
import { ADD_MEAL } from "../../router/router";
import { useHistory } from "react-router-dom";
import IconInput from "../../components/inputs/iconInput";
import moment from "moment";
import Prompt from "../../components/modal/prompt";
import MealsList from "../../components/mealsList";
import CsvParserNew from "../../components/csvParserNew";
import { CSV_TITLES_NEW } from "../../components/csvParserNew/utils";
import FileUpload from "../../components/fileUpload";
import { uploadImagesToCloud } from "../../helpers/uploadImagesToCloud";
import errorHandler from "../../helpers/errorHandler";
import cleanPayload from "../../helpers/cleanPayload";
import { cloudinaryInvalidChars } from "../../helpers/constants";
import BulkFileUpload from "../../components/bulkFileUpload";

const defaultForm = {
  name: "",
  meal_category_id: "",
  amount: "",
  brand_id: "",
  preparation_time: "",
  description: "",
  nutritional_description: "",
  available_no: "",
  minimum_age: "",
  home_page:false,
  alcohol: false,
  new: false,
  images: [],
  meal_keywords: [],
  meal_descriptive_metadatas: [],
  meal_business_metadatas: [],
  meal_dietary_metadatas: [],
  meal_allergy_metadatas: [],
};
const ItemInfo = observer(({ alert }) => {
  const history = useHistory();
  const [brands, setBrands] = useState([]);
  const [brand, setBrand] = useState("");
  const [meal, setMeal] = useState("");
  const [meals, setMeals] = useState("");
  const [submitting, setSubmitting] = useState(null);
  const [loading, setLoading] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [ckLoading, setCkLoading] = useState(false);
  const [cokitchens, setCokitchens] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");
  const [currentBrands, setCurrentBrands] = useState([]);
  const [currentBrandIndex, setCurrentBrandIndex] = useState(0);

  const [imageDisplay, setImageDisplay] = useState(null);
  const [currentMealCategories, setCurrentMealCategories] = useState(null);

  const [image, setImage] = useState(null);
  const [mealActive, setMealActive] = useState(false);
  const [mealNew, setMealNew] = useState(false);
  const [mealCategory, setMealCategory] = useState(false);

  const [showSide, setShowSide] = useState(true);
  const [showTagDrop, setShowTagDrop] = useState(false);
  const [showDietaryDrop, setShowDietaryDrop] = useState(false);
  const [showAllergenDrop, setShowAllergenDrop] = useState(false);
  const [showDescriptionDrop, setShowDescriptionDrop] = useState(false);
  const [showBusinessDrop, setShowBusinessDrop] = useState(false);

  const [pageWidth, setPageWidth] = useState("");

  const [tags, setTags] = useState([]);
  const [suggestions, setSuggestions] = useState([]);

  const [dietaryTags, setDietaryTags] = useState([]);
  const [dietarySuggestions, setDietarySuggestions] = useState([]);

  const [allergenTags, setAllergenTags] = useState([]);
  const [allergenSuggestions, setAllergenSuggestions] = useState([]);

  const [descriptionTags, setDescriptionTags] = useState([]);
  const [descriptionSuggestions, setDescriptionSuggestions] = useState([]);

  const [businessTags, setBusinessTags] = useState([]);
  const [businessSuggestions, setBusinessSuggestions] = useState([]);

  const [csvMeals, setCsvMeals] = useState([]);
  const [showCsvNewModal, setShowCsvNewModal] = useState(false);
  const [checkedNewMeals, setCheckedNewMeals] = useState([]);
  const [titlesNew, setTitlesNew] = useState([]);
  const [showPrompt, setShowPrompt] = useState(false);
  const [form, setForm] = useState({
    ...defaultForm,
  });
  const [addingNew, setAddingNew] = useState(false);
  const [altLoading, setAltLoading] = useState(false);
  const [openingsHrs, setOpeningHrs] = useState([
    { name: "monday", opening_time: "", closing_time: "" },
    { name: "tuesday", opening_time: "", closing_time: "" },
    { name: "wednesday", opening_time: "", closing_time: "" },
    { name: "thursday", opening_time: "", closing_time: "" },
    { name: "friday", opening_time: "", closing_time: "" },
    { name: "saturday", opening_time: "", closing_time: "" },
    { name: "sunday", opening_time: "", closing_time: "" },
  ]);

  const { csvNewData, setCsvNewData, setCsvImages, csvImagesFound } =
    MealCategoryStore;
  let { csvImages } = MealCategoryStore;
  csvImages = csvImages.map((item) => item);
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    let { formatTags, dataCached } = TagsStore;

    if (
      !BrandStore.dataAltCached ||
      !MealCategoryStore.dataCached ||
      !BrandStore.dataCached ||
      !CommonStore.dataCached ||
      !dataCached
    ) {
      await Promise.all([
        BrandStore.getBrandsAlt(alert),
        MealCategoryStore.getMealCategories(alert, true),
        CommonStore.getCokitchens(alert),
        BrandStore.getBrands(alert),
      ]);
    }

    if (!dataCached) {
      const tags = await getTags();
      formatTags(tags);
      setAllTags();
    }
    setCkLoading(false);
    setLoading(false);
    setCokitchens(CommonStore.cokitchens);
    let allBrands = CommonStore?.cokitchens.reduce((acc, curr) => {
      return [...acc, ...curr?.brands];
    }, []);
    setCurrentCokitchen({
      brands: allBrands,
      name: "All users (all locations)",
    });
    setBrands(allBrands);
    setAllTags();
  }, []);

  // Set brand options based on current cokitchen
  useEffect(() => {
    let curr =
      currentCokitchen?.brands
        ?.filter((item) => item?.item_type === "SHOP")
        ?.map((item, i) => {
          return { ...item, label: item.name, value: item.id, index: i };
        }) || [];

    setCurrentBrands(curr);
  }, [currentCokitchen]);

  // Set brand data based on current brand
  useEffect(() => {
    setAllTags();

    let mealOptions = [];
    let mealCopy = brand.meals || [];
    for (let l = 0; l < mealCopy.length; l++) {
      mealOptions.push({
        ...mealCopy[l],
        label: mealCopy[l].name,
        name: mealCopy[l]?.images[0]
          ? mealCopy[l].name + " 📸"
          : mealCopy[l].name,
        value: mealCopy[l].id,
      });
    }
    setCurrentMealCategories(
      BrandStore.brandsAlt?.find((item) => item?.id === brand?.id)
        ?.meal_categories
    );
    setMeals(mealOptions);
    setMeal("");
    setImageDisplay("");
    setTags([]);
    setDietaryTags([]);
    setAllergenTags([]);
    setDescriptionTags([]);
    setBusinessTags([]);
  }, [brand]);

  // Set meal data based on current meal
  useEffect(() => {
    setAllTags();
    let i, j, k, l, m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let keyCopy = meal?.meal_keywords || [];
    let descCopy = meal?.meal_descriptive_metadatas || [];
    let bussCopy = meal?.meal_business_metadatas || [];
    let dietCopy = meal?.meal_dietary_metadatas || [];
    let allergyCopy = meal?.meal_allergy_metadatas || [];
    let mealCategoryCopy = currentMealCategories?.find(
      (item) => item.id === meal?.meal_category_id
    );
    setImageDisplay((meal?.images && meal?.images[0]) || "");
    setMealActive(meal?.active || false);
    setMealNew(meal?.new || false);
    setMealCategory(mealCategoryCopy || "");
    setForm({
      ...form,
      name: meal?.label || "",
      amount: meal?.amount || "",
      preparation_time: meal?.preparation_time || "",
      description: meal?.description || "",
      nutritional_description: meal?.nutritional_description || "",
      available_no: meal?.available_no || "",
      minimum_age: meal?.minimum_age || "",
      home_page:meal?.home_page || "",
      new: meal.new || false,

      alcohol: meal.alcohol ? true : false,
      item_type: "SHOP",
    });
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        ...keyCopy[i],
        label: keyCopy[i].name,
        value: keyCopy[i].id,
      });
    }
    setTags(keyOptions);

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        ...dietCopy[j],
        label: dietCopy[j].name,
        value: dietCopy[j].id,
      });
    }
    setDietaryTags(dietOptions);

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        ...allergyCopy[k],
        label: allergyCopy[k].name,
        value: allergyCopy[k].id,
      });
    }

    setAllergenTags(allergyOptions);

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        ...descCopy[l],
        label: descCopy[l].name,
        value: descCopy[l].id,
      });
    }
    setDescriptionTags(descOptions);

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        ...bussCopy[m],
        label: bussCopy[m].name,
        value: bussCopy[m].id,
      });
    }
    setBusinessTags(bussOptions);
  }, [meal]);
  useEffect(async () => {
    loadCurrentWorkingHrs();
  }, [meal]);

  //  Load Current working hours based on current meal

  const loadCurrentWorkingHrs = async () => {
    setAltLoading(true);
    const { getMealWorkingHrs, workingHours, updateMealWorkingHrs } =
      BrandStore;
    let m, n;
    let workingHrCopy = openingsHrs;
    let id = meal?.id;

    let res = await getMealWorkingHrs(alert, id);
    let matched = false;

    for (m = 0; m < res?.length; m++) {
      for (n = 0; n < workingHrCopy?.length; n++) {
        if (
          res &&
          res[m] &&
          res[m].name &&
          workingHrCopy &&
          workingHrCopy[n] &&
          workingHrCopy[n].name &&
          workingHrCopy[n].name.toLocaleLowerCase() &&
          res[m].name.toLocaleLowerCase() ===
            workingHrCopy[n].name.toLocaleLowerCase()
        ) {
          workingHrCopy[n] = res[m];
          matched = true;
        }
      }
    }

    if (!matched) {
      workingHrCopy = [
        { name: "monday", opening_time: "", closing_time: "" },
        { name: "tuesday", opening_time: "", closing_time: "" },
        { name: "wednesday", opening_time: "", closing_time: "" },
        { name: "thursday", opening_time: "", closing_time: "" },
        { name: "friday", opening_time: "", closing_time: "" },
        { name: "saturday", opening_time: "", closing_time: "" },
        { name: "sunday", opening_time: "", closing_time: "" },
      ];
    }

    setAltLoading(false);
    setOpeningHrs(workingHrCopy);
    updateMealWorkingHrs(workingHrCopy);
  };

  const setOpeningHrsFields = (index, field, val) => {
    let fields = [...openingsHrs];
    fields[index][field] = val;
    fields[index].active = true;

    setOpeningHrs(fields);
  };

  const setOpeningHrsFieldsCB = (index) => {
    let fields = [...openingsHrs];

    fields[index] = {
      ...fields[index],
      opening_time: "",
      closing_time: "",
      active: false,
    };
    setOpeningHrs(fields);
    //setOpeningHrsCopy(fields);
  };

  // Load tags from store
  const getTags = async () => {
    try {
      return await TagsStore.getAllTags(alert, false);
    } catch (error) {}
  };
  // Sort tags
  const setAllTags = () => {
    let { tags } = TagsStore;
    const tagsCopy = JSON.parse(JSON.stringify(tags));
    setSuggestions(
      tagsCopy.meal_keyword?.filter((item) => item?.item_type === "SHOP")
    );
    setDietarySuggestions(
      tagsCopy.meal_dietary_metadata?.filter(
        (item) => item?.item_type === "SHOP"
      )
    );
    setAllergenSuggestions(
      tagsCopy.meal_allergy_metadata?.filter(
        (item) => item?.item_type === "SHOP"
      )
    );
    setDescriptionSuggestions(
      tagsCopy.meal_descriptive_metadata?.filter(
        (item) => item?.item_type === "SHOP"
      )
    );
    setBusinessSuggestions(
      tagsCopy.meal_business_metadata?.filter(
        (item) => item?.item_type === "SHOP"
      )
    );
  };

  // Format tags to be submitted
  const formatTags = () => {
    let i;
    let j;
    let k;
    let l;
    let m;
    let keyOptions = [];
    let descOptions = [];
    let bussOptions = [];
    let dietOptions = [];
    let allergyOptions = [];
    let allOptions = {};
    let keyCopy = tags;
    let descCopy = descriptionTags;
    let bussCopy = businessTags;
    let dietCopy = dietaryTags;
    let allergyCopy = allergenTags;

    for (k = 0; k < allergyCopy.length; k++) {
      allergyOptions.push({
        name: allergyCopy[k].name,
        id: allergyCopy[k].id,
      });
    }
    for (i = 0; i < keyCopy.length; i++) {
      keyOptions.push({
        name: keyCopy[i].name,
        id: keyCopy[i].id,
      });
    }

    for (j = 0; j < dietCopy.length; j++) {
      dietOptions.push({
        name: dietCopy[j].name,
        id: dietCopy[j].id,
      });
    }

    for (l = 0; l < descCopy.length; l++) {
      descOptions.push({
        name: descCopy[l].name,
        id: descCopy[l].id,
      });
    }

    for (m = 0; m < bussCopy.length; m++) {
      bussOptions.push({
        name: bussCopy[m].name,
        id: bussCopy[m].id,
      });
    }

    allOptions = {
      key: keyOptions,
      desc: descOptions,
      buss: bussOptions,
      diet: dietOptions,
      allergy: allergyOptions,
    };

    return allOptions;
  };

  // Sort working hours format for the api request body
  const sortWorkingHours = () => {
    let items = [...openingsHrs],
      itemsCopy = [];

    let i;

    for (i = 0; i < openingsHrs.length; i++) {
      if (items[i].opening_time && items[i].closing_time) {
        delete items[i].checked;
        delete items[i].updated_at;
        delete items[i].created_at;
        delete items[i].id;

        items[i].meal_id = meal?.id;
        items[i].active = true;
        items[i].opening_time =
          moment(items[i].opening_time).format("HH:MM A") == "Invalid date"
            ? items[i].opening_time
            : moment(items[i].opening_time).format("HH:MM A");
        items[i].closing_time =
          moment(items[i].closing_time).format("HH:MM A") == "Invalid date"
            ? items[i].closing_time
            : moment(items[i].closing_time).format("HH:MM A");
        itemsCopy.push(items[i]);
      } else {
        delete items[i].checked;
        delete items[i].updated_at;
        delete items[i].created_at;
        delete items[i].id;
        delete items[i].opening_time;
        delete items[i].closing_time;

        items[i].meal_id = meal?.id;
        items[i].active = false;
        itemsCopy.push(items[i]);
      }
    }

    return itemsCopy;
  };
  // Update meal
  const mealUpdate = async (e) => {
    e.preventDefault();
    const { updateMeal, brands, postMealWorkingHrs } = BrandStore;
    if (!brand) {
      alert.show("Please select a brand to update it's meal", {
        type: "info",
      });
    } else if (!meal) {
      alert.show("Please select a meal to update", {
        type: "info",
      });
    } else {
      setSubmitting(true);
      let url;
      if (image) {
        const formData = new FormData();
        const CLOUDINARY_CLOUDNAME = process.env.REACT_APP_CLOUDINARY_CLOUDNAME;
        const CLOUDINARY_UPLOAD_PRESET =
          process.env.REACT_APP_CLOUDINARY_UPLOAD_PRESET;
        formData.append("file", image);
        formData.append("upload_preset", CLOUDINARY_UPLOAD_PRESET);
        const urlRes = await axios.post(
          `https://api.cloudinary.com/v1_1/${CLOUDINARY_CLOUDNAME}/upload`,
          formData
        );
        if (urlRes && urlRes.data) {
          url = urlRes && urlRes.data && urlRes.data.url;
          setImageDisplay(url);
        }
      }
      const allTags = await formatTags();
      let payload = {
        meal_id: meal.id,
        active: mealActive,
        new: mealNew,
        meal_category_id: mealCategory?.id,
        images: url || imageDisplay ? [url || imageDisplay] : [],
        meal_keywords: allTags.key,
        meal_descriptive_metadatas: allTags.desc,
        meal_business_metadatas: allTags.buss,
        meal_dietary_metadatas: allTags.diet,
        meal_allergy_metadatas: allTags.allergy,
        name: form?.name,
        amount: form?.amount,
        preparation_time: form?.preparation_time || "INFINITE",
        description: form?.description,
        summary: form?.summary,
        nutritional_description: form?.nutritional_description,
        available_no: form?.available_no,
        home_page:form?.home_page,
        minimum_age: form?.minimum_age,
        alcohol: form?.alcohol,
      };
      payload = cleanPayload(payload);

      await updateMeal(alert, payload);
      await postMealWorkingHrs(alert, {
        days: sortWorkingHours(),
      });
      setSubmitting(false);
      alert.show("Fetching updates...", {
        type: "info",
      });
      await CommonStore.getCokitchens(alert);
      setCokitchens(CommonStore.cokitchens);
      setCurrentCokitchen({
        brands: brands,
        name: "All users (all locations)",
      });
      setCurrentBrands(
        CommonStore?.cokitchens &&
          CommonStore?.cokitchens[0] &&
          CommonStore?.cokitchens[0].brands
      );

      setAllTags();
    }
  };

  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  const onDelete = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allSuggestions = [].concat(suggestionState, tag);
    const allTags = [...tagState];
    allTags.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const onAddition = (
    tag,
    i,
    tagState,
    suggestionState,
    setTagState,
    setSuggestionState
  ) => {
    const allTags = [].concat(tagState, tag);
    const allSuggestions = [...suggestionState];
    allSuggestions.splice(i, 1);
    setTagState(allTags);
    setSuggestionState(allSuggestions);
  };

  const createNewMeals = async () => {
    const { createBulkMeal } = BrandStore;

    if (!brand) {
      alert.show("Select a brand for the new meals!", {
        type: "info",
      });
    } else if (!csvMeals[0]) {
      alert.show("Please upload menu items!", {
        type: "info",
      });
    } else {
      setAddingNew(true);
      let imagesUrl = [];
      try {
        imagesUrl = await uploadImagesToCloud(csvImages.map((item) => item));
      } catch (error) {
        errorHandler(error, alert);

      }
      console.log(imagesUrl)
      let body = checkedNewMeals;
      body = body?.map((item, i) => {
        let imgUrlFormat = item[CSV_TITLES_NEW.imageString]?.name;
        for (let index = 0; index < cloudinaryInvalidChars.length; index++) {
          imgUrlFormat = imgUrlFormat
            ?.split(cloudinaryInvalidChars[index])
            .join("_");
        }
        if (imgUrlFormat?.includes(".png")) {
          imgUrlFormat = imgUrlFormat?.replace(".png", "");
        }
          else if (imgUrlFormat?.includes(".jpg")) {
            imgUrlFormat = imgUrlFormat?.replace(".jpg", "")
         }
        //  else if (!imgUrlFormat?.includes(".")) {
        //    imgUrlFormat = imgUrlFormat?.concat(".jpg");
        //  }
         imgUrlFormat = imgUrlFormat?.replaceAll("__", "_")?.toLowerCase();
        const imageUrlMatch = imagesUrl?.find((img) =>
          img?.toLowerCase().includes(imgUrlFormat)
          

        );
        let itemPayload = {
          name: item[CSV_TITLES_NEW.nameString]?.trim(),
          description: item[CSV_TITLES_NEW.descString],
          preparation_time: item[CSV_TITLES_NEW.prepString] || "5",
          amount: item[CSV_TITLES_NEW.rateString],
          meal_category_name: item[CSV_TITLES_NEW.categoryString]?.trim(),
          brand_id: brand?.id,
          item_type: "SHOP",
          available_no: item[CSV_TITLES_NEW.quantityString] || "INFINITE",
          minimum_age: String(item[CSV_TITLES_NEW.ageString]),
          alcohol: item[CSV_TITLES_NEW.alcoholString],
          nutritional_description: item[CSV_TITLES_NEW.nutritionString],
          commission_shop_value_fixed_amount: item[CSV_TITLES_NEW.commissionFeeString],
          commission_shop_value_percentage_amount:item[CSV_TITLES_NEW.commissionPercentageString],
          home_page: item[CSV_TITLES_NEW.showOnHomePageString],
          commission_partner_id: item[CSV_TITLES_NEW.partnerIdString],
          images: imageUrlMatch ? [imageUrlMatch] : [],
        };
        itemPayload = cleanPayload(itemPayload);
        console.log(itemPayload)
        return itemPayload;
      });
      //navigator.clipboard.writeText(JSON.stringify({meals:body}))
      await createBulkMeal(alert, { meals: body });
      setAddingNew(false);
      setShowCsvNewModal(false);
    }
  };
  

  const deleteMeal = async () => {
    const { deleteMeals } = BrandStore;
    setDeleting(true);
    await deleteMeals(meal?.id, alert);
    setDeleting(false);
    setShowPrompt(false);
  };
  const handleCsvImages = (e) => {
    const imgFiles = Object.values(e);
    let newImaageFiles = [...csvImages, ...imgFiles];
    newImaageFiles = newImaageFiles.filter((item) => item?.name);
    setCsvImages(newImaageFiles);
  };
  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab itemInfo="active_nav_item" />

            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px flex_row">
                  Item info
                  <Tippy
                    content={`Meal Info contains editable information on the meals for each brand. To edit the meals under each brand, choose the brand from the topmost select and pick the meal to be edited from the second select.
                    Meals with the photo emoji 📸 already have images`}
                  >
                    <div style={{ marginLeft: "10px" }}>
                      <AiFillQuestionCircle />
                    </div>
                  </Tippy>
                </h6>
                <div className="flex_row_end">
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="Upload Items Via CSV (New)"
                    onClick={() => {
                      setShowCsvNewModal(true);
                    }}
                    className="mr_30"
                  />
                  <Button
                    withIcon
                    icon={
                      <PlusIcon width="13px" height="13px" fill="#2699FB" />
                    }
                    text="New Item"
                    onClick={() => {
                      history.push(ADD_MEAL);
                    }}
                  />
                </div>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section mobile_column">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}
                {!ckLoading ? (
                  <NavItem
                    navText="All users (all locations)"
                    onClick={() =>
                      setCurrentCokitchen({
                        brands: brands,
                        name: "All users (all locations)",
                      })
                    }
                    activeItem={
                      currentCokitchen.name === "All users (all locations)"
                    }
                  />
                ) : null}

                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen.name === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section className="cards_container px_50 seond_width_desktop_2 mb_30">
              <form
                onSubmit={(e) => mealUpdate(e)}
                className="flex_column_center"
              >
                <Select
                  placeholder="Select brand"
                  defaultValue={brand}
                  handleChange={(e) => {
                    setBrand(e);
                    setCurrentBrandIndex(e.index);
                  }}
                  containerClass="width_full"
                  options={currentBrands}
                  name="name"
                  id="id"
                  label="Brand"
                />

                <Select
                  placeholder="Select item"
                  value={meal}
                  handleChange={(e) => setMeal(e)}
                  containerClass="width_full "
                  options={meals}
                  name="name"
                  id="id"
                  label="Item"
                />

                <Select
                  placeholder="Select meal category"
                  handleChange={(e) => {
                    setMealCategory(e);
                  }}
                  containerClass="width_full"
                  options={currentMealCategories}
                  value={mealCategory}
                  name="name"
                  id="id"
                  label={"Meal category " + currentMealCategories?.length}
                />

                <Input
                  placeholder="Enter Item name"
                  value={form.name}
                  onChange={(e) => {
                    setForm({ ...form, name: e });
                  }}
                  required
                  containerClass="mb_15 width_full"
                  label="Item name"
                />

                <Input
                  placeholder="Enter Item amount"
                  value={form.amount}
                  onChange={(e) => {
                    setForm({ ...form, amount: e });
                  }}
                  required
                  containerClass="mb_15 width_full"
                  type="number"
                  label="Item amount"
                />

                <Input
                  placeholder="Enter Item preparation time"
                  value={form.preparation_time}
                  onChange={(e) => {
                    setForm({ ...form, preparation_time: e });
                  }}
                  required
                  containerClass="mb_15 width_full"
                  label="Item preparation time (minutes)"
                />
                <Input
                  placeholder="Quantity available"
                  value={form.available_no}
                  onChange={(e) => {
                    setForm({ ...form, available_no: e });
                  }}
                  containerClass="mb_15 width_full"
                  type="number"
                  label="Quantity available (leave blank if infinite)"
                />

                <Input
                  placeholder="Enter minimum age"
                  value={form.minimum_age}
                  onChange={(e) => {
                    setForm({ ...form, minimum_age: e });
                  }}
                  type="number"
                  containerClass="mb_15 width_full"
                  label="Minimum age"
                />
                

                <div className="flex_row_left width_full mb_35">
                  <div className="flex_column_left mr_25">
                    <ImageFile
                      onChange={(e) => {
                        onImageChange(e.target.files[0]);
                      }}
                      src={imageDisplay}
                      text="Upload Image"
                    />
                    <label className="gray_label mt_10">
                      Main brand image:
                    </label>
                  </div>
                </div>

                {meal?.name && (
                  <div className="flex_row_left mb_25 width_full">
                    <CheckBox
                      id="turn_on_off"
                      label={
                        (mealActive ? "Turn-off" : "Turn-on") +
                        " " +
                        meal?.label
                      }
                      checked={mealActive}
                      onClick={() => {
                        setMealActive(!mealActive);
                      }}
                    />
                  </div>
                )}
                {meal?.name && (
                  <div className="flex_row_left mb_25 width_full">
                    <CheckBox
                      id="alcohol"
                      label={`${meal?.label} is alcohol?`}
                      checked={form.alcohol}
                      onClick={() => {
                        setForm({
                          ...form,
                          alcohol: !form?.alcohol,
                        });
                      }}
                    />
                  </div>
                )}
                {meal?.name && (
                  <div className="flex_row_left mb_25 width_full">
                    <CheckBox
                      id="is_new"
                      label={meal?.label + " is new"}
                      checked={mealNew}
                      onClick={() => {
                        setMealNew(!mealNew);
                      }}
                    />
                  </div>
                )}
                  {meal?.name && (
                   <div className="flex_row_left mb_25 width_full">
                   <CheckBox
                     id="is_hidden"
                     label={`Hidden?`}
                     checked={form?.home_page}
                     onClick={() => {
                       setForm({
                         ...form,
                         home_page: !form?.home_page,
                       });
                     }}
                   />
                 </div>
                )}
               
                {/* Opening hours */}
                <div className="flex_column width_full mb_35">
                  <label className="bold_18_px width_full mb_25">
                    Opening hours
                  </label>

                  {altLoading ? (
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  ) : (
                    openingsHrs.map((item, i) => {
                      return (
                        <div className="flex_row_between mb_15">
                          <label className="mr_15 bold_21px day_label">
                            {item.name}:
                          </label>
                          <IconInput
                            withLabel={i === 0 ? true : false}
                            label="Opening time:"
                            value={item?.active ? item?.opening_time : ""}
                            onChange={(e) =>
                              setOpeningHrsFields(i, "opening_time", e)
                            }
                            icon={Clock}
                            type="time"
                            containerClass="mr_10"
                          />
                          <IconInput
                            withLabel={i === 0 ? true : false}
                            label="Closing time:"
                            value={item?.active ? item?.closing_time : ""}
                            onChange={(e) =>
                              setOpeningHrsFields(i, "closing_time", e)
                            }
                            icon={Clock}
                            containerClass="ml_10"
                            type="time"
                          />
                          <span className="ml_15_t_5">
                            <CheckBox
                              checked={item?.active}
                              onClick={() => {
                                setOpeningHrsFieldsCB(i, "checked");
                              }}
                              className="m_xl_0"
                            />
                          </span>
                        </div>
                      );
                    })
                  )}
                </div>

                {/* Opening hours */}

                {/* <textarea
                  className="text_area text_area_short mb_35"
                  placeholder="meal description "
                  value={description}
                  onChange={(e) => {
                    setDescription(e.target.value);
                  }}
                /> */}
                <div className="flex_row_center">
                  <Button
                    text={submitting ? "Updating..." : "Update Item info"}
                    type="submit"
                    loading={submitting}
                  />

                  <Button
                    text={"Delete Item"}
                    className="ml_15"
                    loading={false}
                    isRed
                    type="button"
                    onClick={() => setShowPrompt(true)}
                  />
                </div>
              </form>
            </section>
            <section className="m_xl_0 second_width_desktop align_left outside_tag_container">
              <h6 className="bold_18_px mb_35">Item Tags & Metadata</h6>
              <Tags
                title="Keywords"
                showTagDrop={showTagDrop}
                setShowTagDrop={setShowTagDrop}
                suggestions={suggestions}
                onAddition={onAddition}
                tags={tags}
                onDelete={onDelete}
                setTags={setTags}
                setSuggestions={setSuggestions}
              />
              <Tags
                title="Dietary information"
                showTagDrop={showDietaryDrop}
                setShowTagDrop={setShowDietaryDrop}
                suggestions={dietarySuggestions}
                onAddition={onAddition}
                tags={dietaryTags}
                onDelete={onDelete}
                setTags={setDietaryTags}
                setSuggestions={setDietarySuggestions}
              />
              <Tags
                title="Allergen information"
                showTagDrop={showAllergenDrop}
                setShowTagDrop={setShowAllergenDrop}
                suggestions={allergenSuggestions}
                onAddition={onAddition}
                tags={allergenTags}
                onDelete={onDelete}
                setTags={setAllergenTags}
                setSuggestions={setAllergenSuggestions}
              />
              <Tags
                title="Descriptive information"
                showTagDrop={showDescriptionDrop}
                setShowTagDrop={setShowDescriptionDrop}
                suggestions={descriptionSuggestions}
                onAddition={onAddition}
                tags={descriptionTags}
                onDelete={onDelete}
                setTags={setDescriptionTags}
                setSuggestions={setDescriptionSuggestions}
              />
              <Tags
                title="Business information"
                showTagDrop={showBusinessDrop}
                setShowTagDrop={setShowBusinessDrop}
                suggestions={businessSuggestions}
                onAddition={onAddition}
                tags={businessTags}
                onDelete={onDelete}
                setTags={setBusinessTags}
                setSuggestions={setBusinessSuggestions}
              />
              <div className="width_full">
                <label className="mb_15 normal_17px">Item description</label>

                <textarea
                  className="text_area text_area_short mb_30"
                  placeholder="item description "
                  value={form.description}
                  onChange={(e) => {
                    setForm({ ...form, description: e.target.value });
                  }}
                />
              </div>
              <div className="width_full">
                <label className="mb_15 normal_17px">
                  Nutritional description
                </label>

                <textarea
                  className="text_area text_area_short mb_30"
                  placeholder="Nutritional Information"
                  value={form.nutritional_description}
                  onChange={(e) => {
                    setForm({
                      ...form,
                      nutritional_description: e.target.value,
                    });
                  }}
                />
              </div>
            </section>
          </section>
        </section>
      </div>
      {/* Meals by CSV New */}
      <Modal
        title={`Upload items in CSV format and their images`}
        showModal={showCsvNewModal}
        closeModal={() => setShowCsvNewModal(!showCsvNewModal)}
        size="lg"
        customContent={
          <div>
            <div className="file-progress-bar-container width_full mb_30">
              <div
                style={{ background: "#2699FB" }}
                className={`width_full file-progress-bar ${
                  csvNewData?.length > 0 && csvImages?.length > 0
                    ? "full"
                    : csvNewData?.length > 0 || csvImages?.length > 0
                    ? "half"
                    : "empty"
                }`}
              />
              <div
                className="width_full mt_10"
                style={{
                  display: "flex",
                  justifyContent: "space-evenly",
                  alignItems: "center",
                }}
              >
                {csvNewData?.length > 0 && (
                  <span className="normal_14px">Shop items detected</span>
                )}
                {csvImages?.length > 0 && (
                  <span className="normal_14px">Images detected</span>
                )}
              </div>
            </div>

            <CsvParserNew
              setCsvData={(e) => setCsvNewData(e)}
              setTitles={(e) => setTitlesNew(e)}
              setCsvMeals={(e) => setCsvMeals(e)}
              mealImages={csvImages}
            />
            <BulkFileUpload
              handleChange={(e) => handleCsvImages(e)}
              file={csvImages}
              multiple
            />

            {csvNewData?.length > 0 && (
              <h4 className="black mb_15">
                {csvNewData?.length} Items Found | {checkedNewMeals?.length}{" "}
                Items Selected | {csvImagesFound?.length} Images Matched
              </h4>
            )}
            <MealsList
              meals={csvNewData}
              titles={titlesNew}
              setCheckedMeals={(e) => {
                setCheckedNewMeals(e);
              }}
              isNew
            />
          </div>
        }
        customClass="feedback_modal"
        onSubmit={(e) => {
          e.preventDefault();
          createNewMeals();
        }}
        saveText={addingNew ? "Creating..." : `Create Meals`}
        loading={addingNew}
      />

      {/* Meals by CSV End */}
      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          deleteMeal();
        }}
        saveText="Cancel"
        loading={deleting}
        title={`Are you sure you want to delete ${form?.name}?`}
        deleteText={deleting ? "Deleting..." : "Delete meal"}
      />
    </main>
  );
});
export default withAlert()(ItemInfo);
