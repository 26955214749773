import React from "react";
import { Star } from "../../assets/icons/icons";
import { CheckBox } from "../../components/checkBoxes";
import "./styles.scss";
import { Link } from "react-router-dom";
const TextCard = ({
  cardLabel,
  checked,
  onChange,
  star1,
  star2,
  star3,
  star4,
  star5,
  cardName,
  cardTime,
  starCount,
  stars,
  viewText = "View full feedback & user information  >",
  onViewClick,
  className,
  forwardedRef,
  withImage,
  image = "http://localhost:3001/static/media/image.986ee233.png",
  cardBody,
  link,
  ...props
}) => {
  return (
    <div
      className={`text_card mb_25 ${className}`}
      ref={forwardedRef}
      {...props}
    >
      <div className="flex_row_between mb_25">
        <h6 className="bold_18_px capitalize">{cardName} </h6>
        <label className="gray_label"> {cardTime}</label>
      </div>
      {stars && (
        <div className="flex_row_between mb_15">
          <div className="flex_row_center">
            <Star
              fill={(star1 && "#000000") || "white"}
              width="15px"
              height="15px"
            />
            <Star
              fill={(star2 && "#000000") || "white"}
              width="15px"
              height="15px"
            />
            <Star
              fill={(star3 && "#000000") || "white"}
              width="15px"
              height="15px"
            />
            <Star
              fill={(star4 && "#000000") || "white"}
              width="15px"
              height="15px"
            />
            <Star
              fill={(star5 && "#000000") || "white"}
              width="15px"
              height="15px"
              className="mr_15"
            />
            <label className="normal_18px">{starCount + "/5"}</label>
          </div>
        </div>
      )}
      {cardBody && (
        <p className="gray_label ellipses_end mb_15 text_left bold">
          {" "}
          {cardBody}
        </p>
      )}
      <p className="gray_label ellipses_end mb_15 text_left"> {cardLabel}</p>

      <div className="flex_row_between">
        <CheckBox id="showAvgRate" checked={checked} onClick={onChange} />
        <Link to={link?link:"#/"} className="no_decor">
        <label className="gray_label cursor_pointer" onClick={onViewClick}>
          {viewText}
        </label>
        </Link>
      </div>
      {withImage && (
        <div
          className="text_card_image"
          style={{
            backgroundImage: `url(${image})`,
            backgroundSize: "cover",
            backgroundRepeat: "no-repeat",
            backgroundPosition: "center",
            backgroundColor: "#acacac",
            borderRadius: "0 0 0 10px",
          }}
        />
      )}
    </div>
  );
};

export default TextCard;
