import React, { useState, useEffect } from "react";
import "./styles.scss";
import Header from "../../components/header";
import Tab from "../../components/tabs";
import TextCard from "../../components/textCard";
import NavItem from "../../components/sideNavItem";
import { LocationIcon, PlusIcon } from "../../assets/icons/icons";
import Modal from "../../components/modal";
import { DateRangePicker } from "react-date-range";
import MealCategoryStore from "../../stores/dataStore/mealCategoryStore";
import PageLoading from "../../components/loader";
import { withAlert } from "react-alert";
import UserStore from "../../stores/userStore/user";
import Prompt from "../../components/modal/prompt";
import { Button } from "../../components/buttons";
import Input from "../../components/inputs";
import { ReactSortable } from "react-sortablejs";
import Select from "../../components/select";
import axios from "axios";
import ImageFile from "../../components/imageFile";
import IconInput from "../../components/inputs/iconInput";
import { BsSearch } from "react-icons/bs";
import CommonStore from "../../stores/dataStore/commonStore";
import moment from "moment";
import Loader from "react-loader-spinner";
import commonStore from "../../stores/dataStore/commonStore";
import { CheckBox } from "../../components/checkBoxes";
import Tippy from "@tippyjs/react";
import { AiFillQuestionCircle } from "react-icons/ai";
import BrandStore from "../../stores/dataStore/brandInfoStore";


const MealArrangement = ({ alert }) => {
  const [brand, setBrand] = useState(null);
  const [selectedBrands, setSelectedBrands] = useState([]);
  const [currentBrands, setCurrentBrands] = useState([]);
  const [activeMeals, setActiveMeals] = useState([]);
  const [loading, setLoading] = useState(false);
  // const [selectedRatings, setSelectedRatings] = useState([]);
  const locations = [
    { name: "All users (all locations)" },
    { name: "Lekki Phase 1 Kitchen" },
    { name: "Oniru Kitchen" },
    { name: "Ikeja Kitchen" },
  ];

  const [currentLocation, setCurrentLocation] = useState(
    "All users (all locations)"
  );

  const [ckLoading, setCkLoading] = useState(false);

  const [cokitchens, setCokitchens] = useState([]);
  const [brands, setBrands] = useState([]);
  const [currentCokitchen, setCurrentCokitchen] = useState("");

  const [currentMeallAddonCat, setCurrentMeallAddonCat] = useState(null);

  const [showSide, setShowSide] = useState(true);
  const [showAddon, setShowAddon] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [showPrompt, setShowPrompt] = useState(false);
  const [showCreateModal, setShowCreateModal] = useState(false);
  //const [ratingsHidden, setRatingsHidden] = useState(false);
  const [pageWidth, setPageWidth] = useState("");
  const [currentData, setCurrentData] = useState("");
  const [currentDataCopy, setCurrentDataCopy] = useState("");
  const [selectionRange, setSelectionRange] = useState({
    startDate: new Date(),
    endDate: new Date(),
    key: "selection",
  });

  const [mealCategoriesForm, setmealCategoriesForm] = useState({
    name: "",
    description: "default",
    summary: "",
    meal_category_selection_type_id: "",
  });
  const [submitting, setSubmitting] = useState(null);
  const [rearrangeDisabled, setrRarrangeDisabled] = useState(false);
  const [imageDisplay, setImageDisplay] = useState(null);
  const [image, setImage] = useState(null);
  const [users, setUsers] = useState([]);

  const [mealCategorySelections, setmealCategorySelections] = useState([]);
  const [supermealCategorySelections, setSupermealCategorySelections] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState([]);
  const [expDate, setExpDate] = useState("");
  const [expTime, setExpTime] = useState("");
  const [defaultMeal, setdefaultMeal] = useState("");
  const [searchQuery, setSearchQuery] = useState("");
  const [adding, setAdding] = useState(false);
  const [showAddForm, setShowAddForm] = useState(false);
  const [form, setForm] = useState({
    name:"",
    summary: "",
    brand_id:"",
    meal_category_selection_type_id: "",
    super_meal_category_id: "",
    selection_no: "",
    is_addon:false,
   
  });
  const [formTwo, setFormTwo] = useState({
    name: "",
    brand_id:"",
   
  });
  useEffect(() => {
    setPageWidth(window.innerWidth);
    if (pageWidth <= 628) {
      setShowSide(false);
    } else {
      setShowSide(true);
    }
  }, [pageWidth]);
  useEffect(() => {
    const handleWindowResize = () => {
      setPageWidth(window.innerWidth);
    };
    window.addEventListener("resize", handleWindowResize);
    return () => window.removeEventListener("resize", handleWindowResize);
  }, [pageWidth]);

  // load data from store
  useEffect(async () => {
    setLoading(true);
    setCkLoading(true);
    
   

    if (!CommonStore.dataCached&&!BrandStore.dataAltCached) {
      await Promise.all([
        CommonStore.getCokitchens(alert), 
        BrandStore.getBrandsAlt(alert)])
      
    } else {
      if (!CommonStore.dataCached) {
        await CommonStore.getCokitchens(alert);
        setCkLoading(true);
      }
      if (!BrandStore.dataAltCached) {
        await BrandStore.getBrandsAlt(alert);
        setBrands(BrandStore.brandsAlt.filter((item)=>item.item_type==="SHOP"));
      }
     
    }

    setLoading(false);
    setCkLoading(false);
    setCurrentCokitchen({
      name: "All users (all locations)",
      brands:BrandStore?.brandsAlt.filter((item)=>item.item_type==="SHOP"),
    });
    setCokitchens(CommonStore.cokitchens);
    setBrands(BrandStore.brandsAlt.filter((item)=>item.item_type==="SHOP"));
    setMealCategoryTypesOptions();
    setSuperMealCategoryTypesOptions()
    setBrand( BrandStore?.brandsAlt.filter((item)=>item.item_type==="SHOP")[0]||[])
  }, []);
  // Set brand options based on current cokitchen
  useEffect(async () => {
    let i;
    let options = [{ id: "All", label: "All Brands" }];
    let curr = currentCokitchen.brands || [];
    for (i = 0; i < curr.length; i++) {
      options.push({
        ...curr[i],
        label: curr[i].name + ` (${curr[i]?.meals?.length})`,
        value: curr[i].id,
      });
    }

    if( currentCokitchen?.name==="All users (all locations)")

    {

      setCurrentBrands(BrandStore?.brandsAlt.filter((item)=>item.item_type==="SHOP"))
      setBrand(BrandStore?.brandsAlt.filter((item)=>item.item_type==="SHOP")[0])
    }

    else{
      let mealsCopy= BrandStore?.brandsAlt?.filter(  item=>item?.cokitchen_id=== currentCokitchen?.id );
      setCurrentBrands(mealsCopy.filter((item)=>item.item_type==="SHOP") );
      setBrand(mealsCopy&&mealsCopy[0]?mealsCopy.filter((item)=>item.item_type==="SHOP")[0]:null)

    }
      }, [currentCokitchen]);


  useEffect(() => {
  let currActMeals=
  brand?.meal_categories
  ?.sort((a, b) => Number(a?.position) - Number(b?.position))||[];

  setActiveMeals(currActMeals)
 
  }, [brand]);



  const resetForm = () => {
    setmealCategoriesForm({
      name: "",
      max_usage: "",
      amount: "",
      to_expire_time: "",
      users: [],
      images: [],
    });
  };


  

  // Sort setMealCategoryTypesOptions options for react select
  const setMealCategoryTypesOptions = () => {
    let opts = MealCategoryStore.mealCategoriesSelection;
    setmealCategorySelections(opts);
   
  };

    // Sort setSuperMealCategoryTypesOptions options for react select
    const setSuperMealCategoryTypesOptions = () => {
      let opts = MealCategoryStore.superMealCategories;
      setSupermealCategorySelections(opts);
     
    };

  const onCardSelect = (i) => {
    let newArr = [...selectedBrands, i];
    if (selectedBrands.includes(i)) {
      newArr = newArr.filter((day) => day !== i);
    }
    setSelectedBrands(newArr);
  };

  const handleSelect = (ranges) => {
    setSelectionRange(ranges.selection);
  };
  const onImageChange = async (e) => {
    setImage(e);
    e && setImageDisplay(URL.createObjectURL(e));
  };

  // Set the defaults for preselected brands
  const setSelectDefaults = (items) => {
    let options = [];
    let i;

    if (items && items.length && items.length > 0) {
      for (i = 0; i < items.length; i++) {
        options.push({ label: items[i].name, value: items[i].id });
      }
    }

    return options;
  };



  const formUpdate = async () => {
    const { arrangeMealCategory } = MealCategoryStore;

    setSubmitting(true);
   
   
    let body = {
      meal_categories: activeMeals?.map(item=>item?.id),
      brand_id: brand?.id,
     
    };
   
    await arrangeMealCategory(alert, body);
    setSubmitting(false);
    setShowPrompt(false)
    alert.show("Fetching updates...", {
      type: "info",
    });
    let dataRes = await BrandStore.getBrandsAlt(alert, false);
    if (dataRes) {
      setBrands(dataRes?.filter(item => item.item_type === 'SHOP'));
    }

  };


  return (
    <main className="main_container">
      <div className="main_container_content">
        <Header />

        <section className="main_section">
          <div className="top_fixed">
            <Tab meal_categories="active_nav_item " />
            <div className="border_bottom_thick">
              <div className="flex_row_between title_bar">
                <h6 className="bold_24px flex_row">
                  Item Arrangement
                
                <Tippy
                    content={`Drag the cards vertically to rearrange 
                              and hit "save changes" 
                              below to save changes. 
                              Click view meal to rearrange meals under each category.`}
                  >
                    <div style={{ marginLeft: "10px" }}>
                      <AiFillQuestionCircle />
                    </div>
                  </Tippy>
                
                </h6>
                <div className="flex_row_center">
                

                
              
             
                {activeMeals&&activeMeals[0]? <Button
                  text={"Save changes"}
                  className="mt_35"
                  onClick={()=>setShowPrompt(true)}
                />:null}
                
              </div>
              </div>
            </div>
          </div>
          <div className="top_section">
            <div className="mobile_visible px_50 flex_row_between">
              {pageWidth <= 628 ? (
                <button
                  className="mr_25 cursor_pointer"
                  onClick={() => setShowSide(!showSide)}
                >
                  <LocationIcon
                    fill={"#FF0000"}
                    width="18px"
                    height="18px"
                    className={showSide ? "rotate_90 ease" : "no_rotate ease"}
                  />
                </button>
              ) : null}
            </div>
          </div>
          <section className="content_section">
            {pageWidth > 628 || showSide ? (
              <aside className={`slide_in`}>
                {ckLoading ? (
                  <div className="flex_column_left">
                    <p className="mb_12">Cokitchens loading...</p>
                    <Loader
                      type="ThreeDots"
                      color="#ff000060"
                      height={10}
                      width={50}
                    />
                  </div>
                ) : null}
                {!ckLoading ? (
                  <NavItem
                    navText="All users (all locations)"
                    onClick={() =>
                    {  setCurrentCokitchen({
                        name: "All users (all locations)",
                        brands:
                          CommonStore?.cokitchens &&
                          CommonStore?.cokitchens[0] &&
                          CommonStore?.cokitchens[0].brands.filter((item)=>item.item_type==="SHOP"),
                      })
                   
                    
                    }
                    }
                    activeItem={
                      currentCokitchen.name === "All users (all locations)"
                    }
                  />
                ) : null}
                {cokitchens.map((item, i) => {
                  return (
                    <NavItem
                      navText={item.name}
                      key={i + "name"}
                      onClick={() => setCurrentCokitchen(item)}
                      activeItem={currentCokitchen.name === item.name}
                    />
                  );
                })}
              </aside>
            ) : null}
            <section
              className="flex_column_left width_full"
              style={{ width: "100%" }}
            >
              <div className="flex_row_between width_full top_bar">
                <Select
                  placeholder="Select brand"
                  value={brand}
                  handleChange={(e) => setBrand(e)}
                  options={currentBrands}
                  name="name"
                  id="id"
                />
                <IconInput
                  required
                  containerClass="ml_15 width_full"
                  value={searchQuery}
                  onChange={(e) => setSearchQuery(e)}
                  icon={<BsSearch width="11px" height="11px" />}
                  placeholder="Search meal caterory"
                />

              {/* <div className="flex_row_left ml_15 width_full">
                      <CheckBox
                      id="show_addon"
                      label={showAddon?"Swich to meals":"Swich to addons"}
                      checked={showAddon}
                      onClick={() => {
                        setShowAddon(!showAddon)
                      }}
                    />
                    </div> */}
              </div>
              <div className="mb_25 flex_row_top width_full">
                {/* <div className="flex_column_left">
                  <a
                    href="#/"
                    //   onClick={() => {
                    //     setPostDragDisabled(!postDragDisabled);
                    //   }}
                  >
                    <p
                      className="bold_18_px mb_15"
                      onClick={() => setrRarrangeDisabled(!rearrangeDisabled)}
                    >
                      Re-arrange faqs
                    </p>
                  </a>
                  <Button text="Save changes" />
                </div> */}

            
              </div>

              <div className="flex_row_top width_full mb_35 tab_column">
               
               

             
              </div>
              {!loading && !brands ? (
                <label className="bold_24px gray">
                  There are no Meal Categories to show
                </label>
              ) : null}


             
                      <div >
                       {activeMeals?.length>0? <div className="bold_24px mb_15">
                          {brand?.name} ({activeMeals?.length} meal categories)
                        </div>:null}
                        <ReactSortable
                          list={activeMeals}
                          setList={setActiveMeals}
                          className="width_full"
                          animation={300}
                          delayOnTouchStart={true}
                          delay={1.5}
                          disabled={rearrangeDisabled}
                        >
                        {activeMeals
                            //?.sort((a, b) => a.name.localeCompare(b.name))
                            ?.filter((item) =>
                              item?.name
                                .toLowerCase()
                                .includes(searchQuery.toLowerCase()))
                            ?.map((item, ind) => {
                              return (
                                <TextCard
                                  key={item && item.id + ind}
                                  link={"meals-arrangements/"+item?.brand_id+"/"+item?.id}
                                  cardName={item && item.name}
                                  cardLabel={item && item.description && ""}
                                  cardTime={`Created on ${moment(
                                    item.created_at
                                  ).format("MMMM Do YYYY, h:mm:ss a")}`}
                                 
                                  onViewClick={async () => {
                                    setCurrentData(item);
                                    if (item && item.meal_default_id) {
                                      let currentDefault =
                                        await item.meals.filter(
                                          (itm) =>
                                            itm.id === item.meal_default_id
                                        );

                                      setdefaultMeal(
                                        currentDefault && currentDefault[0]
                                      );
                                    }
                                    setCurrentDataCopy(item);
                                    setShowModal(true);
                                  }}
                                  viewText="View meals >"
                                 // onChange={() => onCardSelect(i)}
                                  withImage
                                  image={item && item.images && item.images[0]}
                                  className="image_text_card"
                                  cardBody={item && item.summary}
                                />
                              );
                            })}
                          </ReactSortable>

                          
                          

                 </div>
                   

                

              <PageLoading loading={loading} />
            </section>
            <section className="desktop_visible" style={{marginTop:"100px"}}>

            
            </section>
          </section>
        </section>
      </div>
      

      
      

      <Prompt
        showPrompt={showPrompt}
        closeModal={() => setShowPrompt(!showPrompt)}
        onSubmit={(e) => {
          e.preventDefault();
          formUpdate()
        }}
        saveText="Cancel"
        loading={submitting}
        title="Are you sure you want to save changes?"
        deleteText={submitting ? "Saving..." : "Save"}
      />





    

    </main>
  );
};
export default withAlert()(MealArrangement);
