import React, { useRef } from "react";
import { Button } from "../buttons";
import Image from "../../assets/images/image.png";
import "./styles.scss";
const ImageFile = ({ onChange, src, onClick, text, className }) => {
  const fileRef = useRef();
  return (
    <div className={`image_file ${className}`}>
     {text && <Button
        text={text}
        isBlue
        type="button"
        onClick={() => {
          fileRef.current.click();
        }}
        className="mb_25"
      />}
      <input
        type="file"
        className="multi file-upload-input with-preview"
        //    maxLength={3}
        accept=".jpg, .jpeg, .png, .svg, .gif"
        onChange={onChange}
        ref={fileRef}
      />
      <img alt="meal_allergy_metadata" src={(src && src) || Image} />
    </div>
  );
};
export default ImageFile;
